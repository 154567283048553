<template>
<section>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
        <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="info-box link-stats" >
                        <span class="info-box-icon bg-success"><i class="fa fa-sort-numeric-asc"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Total Tip</span>
                            <span class="info-box-number">{{ getTotalTip() }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="info-box link-stats" >
                        <span class="info-box-icon bg-success"><i class="fa fa-sort-numeric-asc"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Total Amount</span>
                            <span class="info-box-number">{{ getTotalAmount() }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                    <label for="">Date Between</label><br>
                    <date-range-picker
                            v-if="filters.startDate && filters.endDate"
                            ref="picker"
                            opens="right"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                            single-date-picker="range"
                            :ranges="false"
                            :show-dropdowns="true"
                            v-model="filters"
                            :autoApply="true"
                            @update="getData()"
                    >
                     </date-range-picker>
                </div>
            </div>

            <div class="col-sm-12 px-0">
                <div class="text-right"></div>
                <div class="table-responsive">
                        <table id="myTable" class="table w-100">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Customer</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Charge Status</th>
                                    <th>Date</th>
                                    <th>Tip</th>
                                    <th>Amount</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="order in orders" :key="order.id">
                                <td>{{ order.id }}</td>
                                <td class="text-capitalize">{{ order.user.name }}</td>
                                <td>{{ order.type.replaceAll('App\\','') }}</td>
                                <td><span class="badge badge-info text-capitalize">{{ order.status }}</span></td>
                                <td><span class="badge badge-primary text-capitalize">{{ order.charge_status }}</span></td>
                                <td>{{ isBusinessService(order) ? momentFormat(order.date) : '' }}</td>
                                <td>{{ moneyFormat(order.tip) }}</td>
                                <td>{{ moneyFormat(order.amount) }}</td>
                                <td>{{ momentFormat(order.created_at) }}</td>
                                <td>
                                    <a :href="`/orders/${order.id}`" class="btn btn-outline-info"><i class="fa fa-eye"></i> View</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
import DateRangePicker from "vue2-daterange-picker"
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import HelperMixin from '@mixins/helperMixin';
import TimeDateMixin from '@mixins/timeDateMixin';

export default {
    name: "EmployeeOrders",
    components: { DateRangePicker },
    mixins: [HelperMixin, TimeDateMixin],
    props:{
        employeeId:{
            required: true
        }
    },
    data(){
        return {
            isLoading: false,
            orders:[],
            filters:{
                startDate: '',
                endDate: '',
            },
        }
    },
    mounted(){
        this.filters.startDate = new Date(moment().subtract(30,'d').format('LL'));
        this.filters.endDate = new Date(moment().format('LL'));
        this.getData();
    },
    methods:{
        getData(){
            let startDate = moment(this.filters.startDate).format('DD-MM-YYYY');
            let endDate = moment(this.filters.endDate).format('DD-MM-YYYY');

            this.orders =[];
            this.isLoading = true;
            axios.get(`/account/employee/orders/${this.employeeId}?start=${startDate}&end=${endDate}`)
            .then(res => {
                // this.$toast.success(`Successfully, Employee has been .`);
               this.orders = res.data.data.orders;
            })
            .catch(err => {
                this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
            }).finally(() => this.isLoading = false);
        },
        isBusinessService(order) {
            return order.type === "App\\BusinessService";
        },
        getTotalTip(){
            let res = 0;
            for(let i = 0; i < this.orders.length; i++) {
                res += this.orders[i].tip;
            }
            return this.moneyFormat(res);
        },
        getTotalAmount(){
            let res = 0;
            for(let i = 0; i < this.orders.length; i++) {
                res += this.orders[i].amount;
            }
            return this.moneyFormat(res);
        }
    }
}
</script>

<style scoped>
.card-body{
    min-height: 30em;
}
</style>
