<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
      <!--    listing-->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center justify-content-md-end mb-3">
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1" data-toggle="modal"
                 data-target="#voucher-modal" @click="reset"><i class="fa fa-plus"></i> Add Voucher</a>
            </div>

            <div class="row ">
              <div v-for="voucher in vouchers" class="col-md-6">
                <voucher-card
                        :voucher-id="voucher.id"
                        :theme="voucher.theme"
                        size="sm"
                        :can-edit="true"
                        :click-able="true"
                        :title="voucher.title"
                        :value="+voucher.value"
                        :retail-price="+voucher.retail_price"
                        :select-all="!!voucher.all_services"
                        :services-count="+voucher.services.length"
                        @click="clickedVoucher"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

      <!--create or update modal-->
    <div class="modal fade bs-modal-xl in" id="voucher-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-xl-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">{{ form.id ? 'Edit' : 'Create' }} Voucher</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i
                    class="fa fa-times"></i></button>
          </div>
          <div class="modal-body">

            <!--            Basic info-->
            <div class="row">
              <div class="col-md-6">

                <div class="col-sm-12">
                    <h3>Create Voucher <small>{{ step }}/2 steps</small></h3>
                  <small class="text-muted">Add the voucher name, value and duration of the voucher. If the voucher value is higher than the retail price it will encourage more sales.</small>
                </div>

                <TransitionGroup enter-active-class="animate__animated animate__flipInX"
                                 leave-active-class="animate__animated animate__flipOutX">
<!--                Step 1-->
                  <div v-show="step===1" :key="`step-1`">
                  <div class="col-md-12 mt-4">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Voucher name</label>
                      <input type="text" class="form-control" v-model="form.name">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Voucher Title</label>
                      <input type="text" class="form-control" v-model="form.title">
                    </div>
                  </div>
                  <div class="col-md-12 d-flex">
                    <div class="form-group mb-0">
                      <label>Value</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">AED</span>
                        </div>
                        <input type="number" class="form-control" aria-label="Amount" v-model="form.value">
                        <div class="input-group-append">
                          <span class="input-group-text">.00</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group ml-3 mb-0">
                      <label>Retail price</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">AED</span>
                        </div>
                        <input type="number" class="form-control" aria-label="Amount" v-model="form.retail_price">
                        <div class="input-group-append">
                          <span class="input-group-text">.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Valid For</label>
                      <select class="form-control" v-model="form.valid_for">
                        <option value="14_days">14 days</option>
                        <option value="1_months">1 month</option>
                        <option value="2_months">2 months</option>
                        <option value="3_months">3 months</option>
                        <option value="4_months">4 months</option>
                        <option value="6_months">6 months</option>
                        <option value="1_years">1 year</option>
                        <option value="3_years">3 years</option>
                        <option value="5_years">5 years</option>
                        <option value="never">Forever</option>
                      </select>
                    </div>
                  </div>

                    <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Included services</label>
                      <p class="service-info">
                        {{ form.all_selected ? 'All' : form.services.length }} Services
                        <a href="javascript:void(0);" class="btn btn-sm pull-right" data-toggle="modal"
                           data-target="#select-services-modal"><i class="fa fa-pencil"></i></a>
                      </p>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group mb-0" style="display: inline-flex;">
                      <div class="switch-div mr-2">
                        <label class="switch">
                          <input name="enable_no_of_sales" type="checkbox" :value="form.enable_no_of_sales"
                                 v-model="form.enable_no_of_sales">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <label>Limit amount of sales</label>
                    </div>
                  </div>
                  <div v-if="form.enable_no_of_sales" class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Number of sales</label>
                      <select class="form-control" v-model="form.no_of_sales">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                  </div>
                </div>
<!--                Step 2-->
                <div v-if="step===2" :key="`step-2`">

                  <label class="mb-0">Voucher colour</label><br>
                  <small class="text-muted">Select a colour that matches your business.</small>
                  <div class="col-md-12 text-center" style="margin: 2rem 0 1.2rem 0px">
                    <a v-for="theme in themes" :key="theme"
                       href="javascript:void(0);"
                       :class="`theme-plate card-${theme} mr-4 ${form.theme === theme?'active':''}`"
                       @click="form.theme=theme"></a>
                  </div>

                  <label class="mb-0 mt-2">Buttons</label><br>
                  <small class="text-muted">Add buttons to the voucher.</small>
                  <div class="col-md-12 mt-2 px-0">
                    <!-- text input -->
                    <div class="form-group mb-0" style="display: inline-flex;">
                      <div class="switch-div mr-2">
                        <label class="switch">
                          <input name="booking_button" type="checkbox" :value="form.booking_button"
                                 v-model="form.booking_button">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <label>Add a book now button</label>
                    </div>
                  </div>

                  <div class="col-md-12 px-0">
                    <label class="w-100">Voucher Description <small class="text-muted pull-right">({{ form.desc.length
                                                                                                  }}/500)</small></label>
                    <textarea class="form-control form-control-lg " maxlength="500" autocomplete="off"
                              v-model="form.desc"></textarea>
                  </div>

                  <label class="mb-0 mt-3">Notes for the clients</label><br>
                  <small class="text-muted">Add a note for clients. This will always be visible.</small>
                  <div class="col-md-12 mt-2 px-0">
                    <!-- text input -->
                    <div class="form-group mb-0" style="display: inline-flex;">
                      <div class="switch-div mr-2">
                        <label class="switch">
                          <input name="enable_client_note" type="checkbox" :value="form.enable_client_note"
                                 v-model="form.enable_client_note">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <label>Enable notes for the clients</label>
                    </div>
                  </div>

                  <div v-if="form.enable_client_note" class="col-md-12 px-0">
                    <label class="w-100">Note<small
                            class="text-muted pull-right">({{ form.client_note.length }}/500)</small></label>
                    <textarea class="form-control form-control-lg " maxlength="500" autocomplete="off"
                              v-model="form.client_note"></textarea>
                  </div>
                </div>
                </TransitionGroup>
              </div>

                <!--              Voucher Card preview-->
              <div class="col-md-6">
                  <voucher-card
                          :theme="+form.theme"
                          size="lg"
                          :value.sync="+form.value"
                          :valid-for.sync="form.valid_for"
                          :desc.sync="form.desc"
                          :booking-button.sync="!!form.booking_button"
                          :select-all.sync="form.all_selected"
                          :services-count.sync="+form.services.length"
                          :enable-client-note.sync="!!form.enable_client_note"
                          :client-note.sync="form.client_note"
                  />
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
            <a v-if="step>1" href="javascript:void(0);" class="btn btn-dark" @click="back">Back</a>
            <a v-if="step<2" href="javascript:void(0);" class="btn btn-dark" @click="next">Next</a>
            <a v-if="step === 2" href="javascript:void(0);" class="btn btn-success" @click="createUpdate()"><i
                    class="fa fa-check"></i>
              {{ form.id ? 'Update' : 'Save' }}
            </a>
          </div>
        </div>
      </div>
    </div>

      <!--View Voucher modal-->
    <div class="modal fade bs-modal-xl in" id="view-voucher-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-xl-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">View Voucher</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i
                    class="fa fa-times"></i></button>
          </div>
          <div class="modal-body">

            <!--            Basic info-->
            <div class="row">

              <!--              Voucher Card preview-->
              <div class="col-md-7">
                <voucher-card
                        :theme="+viewVoucher.theme"
                        size="lg"
                        :value.sync="+viewVoucher.value"
                        :valid-for.sync="viewVoucher.valid_for"
                        :booking-button.sync="!!viewVoucher.booking_button"
                        :select-all.sync="!!viewVoucher.all_services"
                        :services-count.sync="+viewVoucher.services.length"
                />
              </div>

              <div class="col-md-5">
                <div class="col-sm-12 text-center">
                    <h2>{{ viewVoucher.title }} AED {{ (+viewVoucher.value).toFixed(2) }}</h2>
                </div>
                <hr>
                <div class="col-sm-12 text-center">
                  <a href="javascript:void(0);" class="btn btn-outline-dark view-btn" @click="edit"><i
                          class="fa fa-pencil"></i></a>
                </div>
                <hr>
                <div class="col-sm-12 text-center">
                  <a href="javascript:void(0);" class="btn btn-outline-danger view-btn" @click="remove"><i
                          class="fa fa-remove"></i></a>
                </div>
                <hr>
                <div v-if="!!viewVoucher.enable_client_note" class="col-sm-12">
                  <h6>Client Note</h6>
                  <p>{{ viewVoucher.client_note }}</p>
                </div>

              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <SelectServicesModal
            ref="selectService"
            :services="services"
            :all-services="form.all_selected"
            @updated="updateServices"
    />
  </div>
</template>

<script>
import SelectServicesModal from "@components/includes/SelectServicesModal";

export default {
    name: "Vouchers",
    components: { SelectServicesModal },
    props: {
        services: {
            type: Array,
            default: () => []
        },
        voucherData: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            isLoading: false,
            vouchers: this.voucherData ?? [],
            viewVoucher: {
                services: {}
            },
            step: 1,
            themes: [ 0, 1, 2, 3, 4 ],
            form: {
                id: '',
                name: '',
                title: '',
                value: 0,
                retail_price: 0,
                valid_for: '14_days',
                enable_no_of_sales: false,
                no_of_sales: 10,
                all_selected: true,
                services: [],
                desc: '',
                theme: 1,
                booking_button: true,
                enable_client_note: false,
                client_note: '',
            }
        }
    },
    methods: {
        next(){
            if(this.step === 1) {
                if(!this.form.name || !this.form.title || !this.form.value || !this.form.retail_price || !this.form.valid_for) {
                    return this.$toast.error('All Fields are required.')
                } else if(!this.form.services) {
                    return this.$toast.error('Services are required.')
                } else if(+this.form.value < +this.form.retail_price) {
                    return this.$toast.error('The retail price should be less than the voucher value.')
                }
            }
            if(this.step < 2) this.step++;
        },
        back(){
            if(this.step > 1) this.step--;
        },
        createUpdate(){
            this.isLoading = true;
            axios.post('/account/vouchers', this.form)
            .then(res => {
                this.$toast.success(`Successfully, Voucher has been ${ this.form.id ? 'updated' : 'created' }.`);
                this.vouchers = res.data.data.vouchers ?? [];
                $('#voucher-modal').modal('hide');
                this.reset();
            })
            .catch(err => {
                this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
            }).finally(() => this.isLoading = false);
        },
        clickedVoucher(voucherId){
            this.viewVoucher = this.vouchers.find(v => v.id === voucherId);
            this.viewVoucher.services = this.viewVoucher.services.map(s => s.service) ?? [];
            $('#view-voucher-modal').modal('show');
        },
        edit(){
            this.form.id = this.viewVoucher.id;
            this.form.name = this.viewVoucher.name;
            this.form.title = this.viewVoucher.title;
            this.form.value = this.viewVoucher.value ?? 0;
            this.form.retail_price = this.viewVoucher.retail_price ?? 0;
            this.form.valid_for = this.viewVoucher.valid_for ?? '14_days';
            this.form.enable_no_of_sales = !!this.viewVoucher.enable_no_of_sales ?? false;
            this.form.no_of_sales = this.viewVoucher.no_of_sales ?? 10;
            this.form.all_selected = !!this.viewVoucher.all_services ?? true;
            this.form.services = this.viewVoucher.services ?? [];
            this.form.desc = this.viewVoucher.desc ?? '';
            this.form.theme = this.viewVoucher.theme ?? 1;
            this.form.booking_button = this.viewVoucher.booking_button ?? true;
            this.form.enable_client_note = !!this.viewVoucher.enable_client_note ?? false;
            this.form.client_note = this.viewVoucher.client_note ?? '';

            $('#view-voucher-modal').modal('hide');
            $('#voucher-modal').modal('show');

            this.$refs.selectService.setServices(this.form.services ?? []);
        },
        remove(){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete Voucher?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No, Cancel',
                confirmButtonText: 'Yes, Delete',
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed) {
                    this.isLoading = true;
                    axios.delete(`/account/vouchers/${ this.viewVoucher.id }`)
                    .then(res => {
                        this.$toast.success(`Successfully, Voucher has been deleted`);
                        this.vouchers = res.data.data.vouchers ?? [];
                        $('#view-voucher-modal').modal('hide');
                        this.reset();
                    })
                    .catch(err => {
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                    })
                    .finally(() => this.isLoading = false);
                }
            })
        },
        reset(){
            this.form.id = '';
            this.form.name = '';
            this.form.title = '';
            this.form.value = 0;
            this.form.retail_price = 0;
            this.form.valid_for = '14_days';
            this.form.enable_no_of_sales = false;
            this.form.no_of_sales = 10;
            this.form.desc = '';
            this.form.theme = 1;
            this.form.booking_button = true;
            this.form.enable_client_note = false;
            this.form.client_note = '';
            this.step = 1;

            this.viewVoucher = {
                services: {}
            }
            this.$refs.selectService.reset();
        },
        updateServices(data){
            this.form.all_selected = data.all;
            this.form.services = data.services;
        }
    }
}
</script>

<style scoped>
.input-group-text {
    background: white;
}

.service-info {
    padding: 10px 15px;
    font-size: 20px;
    background: #e1dede78;
    border-radius: 6px;
}

.theme-plate {
    background: red;
    padding: 10px 18px;
    border-radius: 50%;
}

.theme-plate.active {
    border: 3px solid #00fa00;
    padding: 17px 26px;
}

.card-0 {
    /*background: linear-gradient(0deg,#8b60ed,#b372bd);*/
    background: linear-gradient(0deg, #8b60ed, #b372bd);
    box-shadow: 0px 0px 20px 2px #8b60ed;
}

.card-1 {
    background: linear-gradient(0deg, #f19a1a, #ffc73c);
    box-shadow: 0px 0px 20px 2px #f19a1a;
}

.card-2 {
    background: linear-gradient(180deg, #f44881, #ec454f);
    box-shadow: 0px 0px 20px 2px #f44881;
}

.card-3 {
    background: linear-gradient(180deg, #6edcc4, #1aab8b);
    box-shadow: 0px 0px 20px 2px #6edcc4;
}

.card-4 {
    background: linear-gradient(351deg, #3f5efb, #5cd9d8);
    box-shadow: 0px 0px 20px 2px #3f5efb;
}

label {
    font-weight: 500;
}

.view-btn {
    padding: 1rem 2rem;
    border-radius: 5px;
    box-shadow: 6px 4px 26px -5px;
}

.view-btn i.fa {
    font-size: 2rem;
}

.switch{
    height: 22px;
}
</style>
