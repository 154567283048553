<template>
  <div :class="`col-12 w-100 h-100 ${classes}`">

    <!-- Large View-->
    <div :class="`card card-${size} ${clickAble?'clickable':''}`"
         :style="{ 'background-image': 'url(/user-uploads/ads/'+image+'.webp)', 'text-align': textAlign }"
         @click="clickAdvertising()"
    >
          <span class="name text-white">COMPANY NAME</span>
          <span class="name">{{ name }}</span>
          <span class="description">{{ description }}</span>
          <span class="discount">{{ discount }}% OFF</span>
    </div>

  </div>
</template>

<script>

export default {
    name: "AdvertisingCard",
    props: {
        id: {
            type: [ Number, String ],
            default: ''
        },
        name: {
            type: String,
            default: 'Enter your title'
        },
       description: {
            type: String,
            default: ''
        },
        discount: {
            type: [ Number, String ],
            default: 0
        },
        image: {
            type: Number,
            default: () => 20
        },
        textAlign: {
            type: String,
            default: 'left'
        },
        size: {
            type: String,
            default: 'sm'
        },
        clickAble: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            default: ''
        },
    },
    methods: {
        clickAdvertising(){
            if(this.clickAble) this.$emit('click', this.id);
        }
    }
}
</script>

<style scoped>
/*
* Gradient Site
* https://cssgradient.io/
* @Author Khuram Qadeer.
**/
.card {
    color: white;
    border: none;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
}

.card-sm {
    padding: 3em;
    height: 210px;
}
.card-sm .name {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: #e9d149;
}
.card-sm .description{
    text-transform: capitalize;
    font-size: 7px;
}
.card-sm .discount{
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
}
</style>
