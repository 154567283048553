<template>
    <section>
       <!-- SLIDER START -->
       <section v-if="advertisingUsers.length" class="position-relative bannerSection">
            <div class="container-fluid">
                <div class="row">
                    <div class="owl-carousel owl-theme" id="banner_slider">
                        <div v-for="user in advertisingUsers"
                             :key="`ads-user-${user.id}`"
                             class="item"
                             :style="{
                                 'background-image': 'url(/user-uploads/ads/'+user.advertisement_config?.image+ '.webp)',
                                 'background-repeat': 'no-repeat',
                                 'background-size': 'cover',
                                 'text-align': user.advertisement_config?.text_align
                             }">
                                  <p class="company">{{ user.company?.company_name }}</p>
                                  <p class="name">{{ user.advertisement_config?.name }}</p>
                                  <p class="description">{{ user.advertisement_config?.description }}</p>
                                  <p class="discount">{{ user.advertisement_config?.discount }}% OFF</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       <!--  CATEGORIES -->
       <section class="categorySection" id="categorySection">
            <div class="container px-0">
                <div class="heading justify-content-lg-center mt-4 mb-4">
                    <p class="">Categories</p>
                </div>
                <div class="row">
                    <div v-for="category in categories" :key="`category-${category.id}`" class="col-sm-12 col-md-3 mb-4">
                        <div class="categoryBox">
                            <a :href="`/services/${category?.slug}`">
                                <img :src="category.image"
                                     :data-src="category.image"
                                     class="img-fluid" alt="Cafes">
                                <div class="category_box_hover">
                                    <p class="number_of_category">{{ category.services.length }}</p>
                                    <p class="category_name">{{ category.name }}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       <!-- Popular Offers -->
       <section class="couponSection">
           <div class="container">
               <div class="heading">
                   <p class="mb-4">Special Offers</p>
               </div>
               <div class="row">
                   <div class="col-md-12 px-0">
                       <carousel :perPageCustom="[[480, 1],[768, 2], [1024, 3]]" :navigationEnabled="true"
                                 :paginationEnabled="false">
                           <slide v-for="offer in popularOffers" :key="`offer-${offer.id}`">
                                  <offer-card
                                          :offer-id="offer.id"
                                          :name="offer.name"
                                          :code="offer.code"
                                          :discount.sync="+offer.discount"
                                          :valid-for.sync="offer.valid_for"
                                          :select-all.sync="!!offer.all_services"
                                          :services-count.sync="+offer.services.length"
                                          :image.sync="offer.image"
                                          size="sm"
                                          :can-edit="true"
                                          :click-able="true"
                                          @click="clickedOffer"
                                  />
                           </slide>
                       </carousel>
                   </div>
               </div>
           </div>
        </section>

       <!-- Counter Sections -->
       <section class="counterSection">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <h1 class="font-weight-bold text-left text-gradient-primary">The top-rated destination for beauty and wellness</h1>
                        <span class="text-left text-muted">One solution, one software. Trusted by the best in the beauty and wellness industry</span>
                    </div>

                    <div class="col-sm-12 col-md-7 d-flex flex-wrap ">
                        <div class="col-sm-12 col-md-6 text-center mb-3">
                            <h1 class="counter">70000+</h1>
                            <span>Partner Businesses</span>
                        </div>
                        <div class="col-sm-12 col-md-6 text-center mb-3">
                            <h1 class="counter">250000+</h1>
                            <span>Stylists & Professionals</span>
                        </div>
                        <div class="col-sm-12 col-md-6 text-center mb-3">
                            <h1 class="counter">500 million+</h1>
                            <span>Appointments Booked</span>
                        </div>
                        <div class="col-sm-12 col-md-6 text-center mb-3">
                            <h1 class="counter">120+ countries</h1>
                            <span>With {{ appName }} Available</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

       <!-- Popular Branches | Top Rated Barbers -->
       <section v-if="popularBranches.length" class="couponSection">
                <div class="container">
                    <div class="heading">
                        <p class="mb-0">Top Rated Barbers</p>
                    </div>
                    <div class="row">
                            <div v-for="branch in popularBranches" :key="`branch-${branch.id}`" class="col-md-3 mt-4 px-2">
                                <div class="card rounded-2xl bg-transparent border-none">
                                    <img class="card-img-top w-full rounded-xl aspect-radio-16-9"
                                         :src="branch.images.length ? branch.images[0].path : '/images/default_barber.png'"
                                         alt="advertisement">
                                    <div class="card-body py-2 px-1 border-bottom-linear-gradient">
                                        <a :href="`/show/${branch?.slug}`"
                                           class="card_title leading-6 font-semibold">
                                            {{ branch.name | strLimit(40) }}
                                            <template v-if="branch.rating">
                                                 <span class="fa fa-star checked"></span>
                                                <b>{{ branch.rating }}</b>
                                            </template>
                                        </a>
                                        <p class="card-text text-muted leading-6">{{ branch.address | strLimit(40) }}</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>

        <!-- Popular Products -->
       <section v-if="popularProducts.length" class="mt-10">
            <div class="container">
                <div class="heading">
                    <p>Products</p>
                </div>
                <div class="row">
                    <div v-for="product in popularProducts" :key="`product-${product.id}`" class="col-md-3 mt-4 px-2">
                        <img class="card-img-top w-full aspect-radio-16-9 rounded-xl" :src="product.image" alt="advertisement">
                        <div class="card-body py-2 px-1 border-bottom-linear-gradient text-capitalize">
                            <a :href="`/show/products/${product.branch?.slug}`"
                               class="card_title leading-6 font-semibold">
                                {{ product.name  }}
                            </a>
                            <p class="card-text text-muted leading-6">{{ product.description | strLimit(40) }}</p>
                            <p class="card-text text-muted leading-6 font-semibold">{{ moneyFormat(product.price) }}</p>
                        </div>
                    </div>
                </div>
            </div>
       </section>

        <!-- Download our mobile apps-->
        <section>
            <div class="px-4 mt-20">
                <div class="heading text-center">
                    <p class="sm:text-xl md:text-4xl">Download our mobile apps</p>
                    <small style="font-size: 100%;">Book unforgettable beauty and wellness experiences with our mobile app, or run your business with our award-winning, 100% subscription-free iOS and Android booking platform</small>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                         <CustomerAppIntro />
                    </div>
                    <div class="col-sm-12 col-md-6">
                         <BusinessAppIntro />
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import CustomerAppIntro from "@components/includes/CustomerAppIntro";
import BusinessAppIntro from "@components/includes/BusinessAppIntro";
import helperMixin from '@mixins/helperMixin';

export default {
    components: { CustomerAppIntro, BusinessAppIntro },
    mixins: [helperMixin],
    props: {
        isCustomer: {
            type: Boolean,
            default: () => false
        },
        advertisingUsers: {
            type: Array,
            default: () => []
        },
        categories: {
            type: Array,
            default: () => []
        },
        popularBranches: {
            type: Array,
            default: () => []
        },
        popularOffers: {
            type: Array,
            default: () => []
        },
        popularProducts: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            appName: '',
        }
    },
    mounted(){
        setTimeout(() => {
            this.appName = window.appName;
        }, 200)
    },
    methods: {
        clickedOffer(offerId){
            if(this.isCustomer){
                let offer = this.popularOffers.find(o => o.id === offerId);
                window.location.href = `/booking/${ offer.branch?.slug }/0/0/${ offerId }`;
            }
        },
    }
}
</script>

<style scoped>
.owl-carousel{
    display: block;
}
</style>
