<template>
  <!--create or update modal-->
  <div class="modal fade bs-modal-lg in" id="service-category-modal" role="dialog" aria-labelledby="myModalLabel"
       aria-hidden="true">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
    <div class="modal-dialog modal-lg" id="modal-lg-data-location">
      <div class="modal-content">
        <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">Service Category</span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <!-- text input -->
              <div class="form-group">
                <label>Category Name</label>
                <input type="text" class="form-control form-control-lg" autocomplete="off" v-model="form.name">
              </div>
            </div>
            <div class="col-md-6">
              <!-- text input -->
              <div class="form-group">
                <label>Appointment color</label>
                <div>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#ff9cbb'?'active':''}`" style="background: #ff9cbb" @click="form.color='#ff9cbb'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#841B2D'?'active':''}`" style="background: #841B2D" @click="form.color='#841B2D'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#bbc1e8'?'active':''}`" style="background: #bbc1e8" @click="form.color='#bbc1e8'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#81bcff'?'active':''}`" style="background: #81bcff" @click="form.color='#81bcff'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#a6e5bd'?'active':''}`" style="background: #a6e5bd" @click="form.color='#a6e5bd'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#FF91AF'?'active':''}`" style="background: #FF91AF" @click="form.color='#FF91AF'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#DA1884'?'active':''}`" style="background: #DA1884" @click="form.color='#DA1884'"></a>
                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#ffbf69'?'active':''}`" style="background: #ffbf69" @click="form.color='#ffbf69'"></a>

                <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#59CE8F'?'active':''}`" style="background: #59CE8F" @click="form.color='#59CE8F'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#002B5B'?'active':''}`" style="background: #002B5B" @click="form.color='#002B5B'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#A66CFF'?'active':''}`" style="background: #A66CFF" @click="form.color='#A66CFF'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#AFB4FF'?'active':''}`" style="background: #AFB4FF" @click="form.color='#AFB4FF'"></a>

                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#80558C'?'active':''}`" style="background: #80558C" @click="form.color='#80558C'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#AF7AB3'?'active':''}`" style="background: #AF7AB3" @click="form.color='#AF7AB3'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#DF7861'?'active':''}`" style="background: #DF7861" @click="form.color='#DF7861'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#FA2FB5'?'active':''}`" style="background: #FA2FB5" @click="form.color='#FA2FB5'"></a>

                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#EAE509'?'active':''}`" style="background: #EAE509" @click="form.color='#EAE509'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#7DCE13'?'active':''}`" style="background: #7DCE13" @click="form.color='#7DCE13'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#2B7A0B'?'active':''}`" style="background: #2B7A0B" @click="form.color='#2B7A0B'"></a>
                  <a href="javascript:void(0);" :class="`form-control category-color ${form.color==='#5A8F7B'?'active':''}`" style="background: #5A8F7B" @click="form.color='#5A8F7B'"></a>

                </div>
              </div>
            </div>
            <div class="col-md-12">
              <!-- text input -->
              <div class="form-group">
                <label>Category description</label>
                <textarea class="form-control form-control-lg" v-model="form.desc"></textarea>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <!-- text input -->
              <a href="javascript:void(0);" class="btn btn-success" @click="createUpdate()"><i class="fa fa-check"></i>
                {{ form.id ? 'Update' : 'Save' }}
              </a>
            </div>

<!--            table-->
            <div class="col-12 mt-4">
              <div class="table-responsive">
                <table id="myTable" class="table w-100">
                  <thead>
                  <tr>
                    <th>Category Name</th>
                    <th>Color</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="category in categories" :key="category.id">
                    <td>{{category.name}}</td>
                    <td>
                      <a href="javascript:void(0);" class="form-control category-color" :style="`background: ${category.color}`"></a>
                    </td>
                    <td>{{category.desc}}</td>
                    <td>
                      <a href="javascript:;" @click="edit(category)" class="btn btn-primary btn-circle edit-row"><i class="icon-pencil"></i></a>
                      <a href="javascript:;" @click="remove(category.id)" class="btn btn-danger btn-circle delete-row" data-toggle="tooltip"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i> Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ServiceCategoryModal",
  data(){
    return {
      categories:[],
      isLoading:false,
      form:{
        id:'',
        name:'',
        color:'',
        desc:'',
      }
    }
  },
  mounted(){
    axios.get('/account/services-category')
    .then(res => {
      this.categories = res.data.data.categories ?? [];
      this.reset();
    });
  },
  methods:{
    createUpdate(){
      this.isLoading = true;
      axios.post('/account/services-category', this.form)
      .then(res => {
        this.$toast.success(`Successfully, Branch has been ${this.form.id ? 'updated':'created'}.`);
        this.categories = res.data.data.categories;
        this.reset();
      })
      .catch(err=>{
        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
      })
      .finally(()=> this.isLoading = false);
    },
    edit(data){
      this.form.id = data.id;
      this.form.name = data.name;
      this.form.color = data.color;
      this.form.desc = data.desc;
    },
    remove(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete category?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`account/services-category/${id}`)
          .then(res => {
            this.$toast.success(`Successfully, Service Category has been deleted.`);
            this.categories = res.data.data.categories;
          })
          .catch(err=>{
            this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
          });
        }
      })
    },
    reset(){
      this.form.id = '';
      this.form.name = '';
      this.form.color = '';
      this.form.desc = '';
    },
    getCategories(){
      return this.categories;
    }
  }
}
</script>

<style scoped>

</style>
