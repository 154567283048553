<template>
    <section>
        <!-- Booking statics -->
        <BookingStatics
                title="All Bookings Statics"
                role="admin"
                :pending="+bookingStats.pending"
                :awaiting-time="+bookingStats.awaiting_time"
                :completed="+bookingStats.completed"
                :cancelled="+bookingStats.cancelled"
                :expired="+bookingStats.expired"
                :sms="+bookingStats.sms"
        />

        <div class="row">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="true"
                     loader="dots"
                     color="#366865"
                     background-color="#a3afeb66"
            />
            <!--Company info-->
            <div class="col-md-12">
                <div class="card card-light p-6">
                    <h1 class="display-4 text-4xl mb-3"><b>Company</b></h1>
                    <img :src="company?.logo_url" class="img-thumbnail w-32" alt="">
                    <h4 class="display-4 text-2xl mt-2">{{ company?.company_name }}
                         <StarRating v-if="company.rating"
                                     :inline="true"
                                     :star-size="23"
                                     :rating="company.rating"
                                     :increment="0.1"
                                     :show-rating="false"
                                     :read-only="true"
                                     active-color="#366865"
                                     :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                         />
                        <span v-if="company.rating" class="badge badge-success mt-1 text-sm">{{ company.rating }}</span>
                        <small class="text-muted">({{ company.address }})</small>
                    </h4>
                    <p><b>Owner Name: </b>{{ company?.owner?.name }}</p>
                    <p><b>Category: </b>{{ company?.category?.name }}</p>
                    <p><b>Email: </b>{{ company?.company_email }}</p>
                    <p><b>Phone Number: </b>{{ company?.company_phone }}</p>
                </div>
            </div>

            <!--Branches-->
            <div v-for="(branch,i) in company?.owner?.branches" :key="`branch-${i}`" class="col-md-12">
                <div class="card card-light p-6">
                    <h1 class="display-4 text-4xl mb-3 ">
                        <a href="javascript:void(0);"><b><i class="fa fa-link"></i> Branch {{ i + 1 }}#</b> {{ branch?.name }}</a>
                        <StarRating v-if="branch.rating"
                                    :inline="true"
                                    :star-size="23"
                                    :rating="branch.rating"
                                    :increment="0.1"
                                    :show-rating="false"
                                    :read-only="true"
                                    active-color="#366865"
                                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                        />
                        <span v-if="branch.rating" class="badge badge-success mt-1 text-sm">{{ branch.rating }}</span>
                        <small class="text-muted text-sm">({{ branch?.address }})</small>
                    </h1>
                    <p class="text-capitalize">
                        <b>Available For: </b>{{ branch.available_for.replaceAll('_', ' ') }}
                    </p>
                    <p><b>Category: </b>{{ branch?.category?.name }}</p>
                    <p><b>Email: </b>{{ branch.email }}</p>
                    <p><b>Phone Number: </b>{{ branch.contact }}</p>
                    <small>{{ branch.desc }}</small>

                    <!-- Services-->
                    <template v-if="branch.services.length">
                        <h4 class="display-4 text-2xl font-semibold my-3 underline">Services</h4>
                        <div class="d-flex flex-wrap">
                            <div v-for="branchService in branch?.services"
                                 :key="`b-s-${branchService.service.id}`"
                                 class="rounded shadow p-2 m-1 border">
                                {{ branchService?.service?.name }}
                                <span class="badge badge-success">{{ branchService.service.treatment.type }}</span>
                                <span class="badge badge-dark p-1 ml-2">
                                     <span class="badge badge-light mr-1">{{
                                             timeFormat(branchService.service.price_duration)
                                                                          }}</span>
                                     , {{ moneyFormat(branchService.service.price) }}
                                     <small>{{ getPriceType(branchService.service.price_type) }}</small>
                                 </span>
                                <div class="d-flex flex-wrap mt-2">
                                    <div v-for="serviceEmployee in branchService.service.employees"
                                         :key="`b-s-e-${branchService?.service?.id}-${serviceEmployee.id}`"
                                         class="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-0.5">
                                        <img :src="serviceEmployee?.employee?.user_image_url ?? '/images/default_barber.png'"
                                             :alt="serviceEmployee?.employee?.name"
                                             data-toggle="tooltip"
                                             data-placement="right"
                                             data-html="true"
                                             :title="`<b>${serviceEmployee?.employee?.name ?? ''}</b> ${serviceEmployee?.employee?.title ?? ''}`"
                                             class="w-10 h-10 rounded-full bg-slate-100 ring-2 ring-white"
                                             loading="lazy"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- All Employees-->
                    <template v-if="branch.allEmployees.length>0">
                        <h4 class="display-4 text-2xl font-semibold my-3 underline">Employees</h4>
                        <div class="d-flex flex-wrap">
                            <div v-for="employee in branch?.allEmployees"
                                 :key="`b-all-e-${branch?.id}-${employee.id}`"
                                 class="flex shadow-xl mr-3 mb-3">
                                <div class="flex-none w-48 relative">
                                    <img :src="employee?.user_image_url ?? '/images/default_barber.png'"
                                         :alt="employee?.name" class="absolute inset-0 w-80 h-full object-cover"
                                         loading="lazy" />
                                </div>
                                <form class="flex-auto p-4">
                                    <div class="flex flex-wrap">
                                        <h1 class="flex-auto text-lg font-semibold text-slate-900 text-capitalize">
                                            {{ employee?.name }}
                                        </h1>
                                        <div class="w-full flex-none text-sm font-medium text-slate-700">
                                            <div class="text-sm text-slate-500">
                                                Since {{ (new Date(employee.created_at)).getFullYear() }}
                                            </div>
                                            {{ employee.title }}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </template>

                </div>
            </div>

            <!--Vouchers-->
            <template v-if="company.owner.vouchers.length>0">
                <div class="col-sm-12">
                    <div class="card card-light p-6">
                        <h4 class="display-4 text-4xl mb-3"><b>Vouchers</b></h4>
                        <div v-for="voucher in company.owner.vouchers" class="sm:px-[5%] md:px-[20%]">
                            <voucher-card
                                    :voucher-id="voucher.id"
                                    :theme="voucher.theme"
                                    size="sm"
                                    :can-edit="true"
                                    :click-able="true"
                                    :title="voucher.title"
                                    :value="+voucher.value"
                                    :retail-price="+voucher.retail_price"
                                    :select-all="!!voucher.all_services"
                                    :services-count="+voucher.services.length"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <!--Memberships-->
            <template v-if="company.owner.memberships.length>0">
                <div class="col-sm-12">
                    <div class="card card-light p-6">
                        <h4 class="display-4 text-4xl mb-3"><b>Memberships</b></h4>
                        <div v-for="membership in company.owner.memberships" class="sm:px-[5%] md:px-[20%]">
                            <membership-card
                                    :membership-id="membership.id"
                                    :theme="membership.theme"
                                    size="sm"
                                    :click-able="true"
                                    :title="membership.name"
                                    :price="+membership.price"
                                    :valid-for="membership.valid_for"
                                    :select-all="!!membership.all_services"
                                    :services-count="+membership.services.length"
                                    :sessions.sync="membership.sessions"
                                    :no-of-session.sync="+membership.no_of_session"
                                    classes="pl-0"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <!-- Reviews Table-->
            <div class="col-sm-12">
                    <div class="card card-light p-6">
                        <h4 class="display-4 text-4xl mb-3"><b>Reviews</b></h4>
                        <div class="table-responsive">
                            <table id="myTable" class="table w-100">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Rating</th>
                                        <th>Text</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
             </div>

        </div>
    </section>
</template>

<script>
import TimeDateMixin from '@mixins/timeDateMixin';
import HelperMixin from '@mixins/helperMixin';
import BookingStatics from "@components/includes/BookingStatics";

export default {
    components: { BookingStatics },
    props: {
        company: {
            type: Object,
            default: {}
        }
    },
    mixins: [ TimeDateMixin, HelperMixin ],
    data(){
        return {
            isLoading: false,
            reviewTable:'',
            bookingStats: {
                pending: 0,
                awaiting_time: 0,
                completed: 0,
                expired: 0,
                cancelled: 0,
                sms: 0
            },
        }
    },
    mounted(){
        let self = this;
        this.bookingStats = this.company.booking_stats;
        $(document).ready(function(){
            this.reviewTable = new DataTable('#myTable', {
                responsive: true,
                serverSide: true,
                paging: true,
                order: [ [ 0, 'desc' ] ],
                ajax: `/super-admin/company/reviews/${ self.company?.id }`,
                processing: true,
                language: languageOptions(),
                "fnDrawCallback": function(oSettings){
                    $("body").tooltip({
                        selector: '[data-toggle="tooltip"]'
                    });
                },
                columns: [
                    // { data: 'DT_RowIndex'},
                    { data: 'id', name: 'id' },
                    { data: 'name', name: 'name' },
                    { data: 'rating', name: 'rating' },
                    { data: 'text', name: 'text' },
                    { data: 'created_at', name: 'created_at' },
                    { data: 'action', name: 'action' }
                ],
                dom: 'Blfrtip',

            });
        });

        // Delete Review
        $('body').on('click', '.delete-row', function(){
            var url = $(this).data('url');
            swal({
                icon: "warning",
                buttons: [ "No, Cancel it!", "Yes, OK!" ],
                dangerMode: true,
                title: "Are you sure?",
                text: "Do you really want to delete?",
            })
            .then((willDelete) => {
                if(willDelete) {
                    window.location.href = url;
                }
            });
        });
    },
}
</script>

<style scoped>

</style>
