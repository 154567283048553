<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
    <!--    listing-->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center justify-content-md-end mb-3">
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1 mx-2" data-toggle="modal"
                 data-target="#service-category-modal" @click="reset"><i class="fa fa-plus"></i> Service Categories</a>
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1" data-toggle="modal"
                 data-target="#employee-modal" @click="reset"><i class="fa fa-plus"></i> Add New</a>
            </div>
            <div class="table-responsive">
              <table id="myTable" class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Title</th>
                  <th>Service Category</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="employee in allEmployees" :key="employee.id">
                  <td>{{ employee.id }}</td>
                  <td>
                    <img :src="employee.user_image_url" class="img-thumbnail " width="50" alt="">
                  </td>
                  <td>{{ employee.name }}</td>
                  <td>{{ employee.title }}</td>
                  <td>
                    <a v-if="employee.service_category" href="javascript:void(0);"
                       class="form-control category-color" :style="`position: relative; top: 10px; background: ${employee.service_category.color}`">
                    </a>
                    {{ employee.service_category?employee.service_category.name:'' }}
                  </td>
                  <td>{{ employee.email }}</td>
                  <td>+971-{{ employee.mobile }}</td>
                  <td>
                    <a :href="`/account/employee/orders/${employee.id}`" class="btn btn-info btn-circle"><i
                        class="icon-eye"></i></a>
                      <a href="javascript:;" @click="edit(employee)" class="btn btn-primary btn-circle"><i
                        class="icon-pencil"></i></a>
                    <a href="javascript:;" @click="remove(employee.id)" class="btn btn-danger btn-circle"
                       data-toggle="tooltip"><i class="fa fa-times" aria-hidden="true"></i></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--create or update modal-->
    <div class="modal fade bs-modal-lg in" id="employee-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-lg-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">{{ form.id ? 'Edit' : 'Create' }} Employee</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control form-control-lg" autocomplete="off" v-model="form.name">
                </div>
              </div>
              <div class="col-md-6">
                <label>Member Title</label>
                <input type="text" class="form-control form-control-lg" autocomplete="off" v-model="form.title">
              </div>
              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Email address</label>
                  <input type="email" class="form-control form-control-lg" autocomplete="off" v-model="form.email">
                </div>
              </div>
              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" class="form-control form-control-lg" autocomplete="off" v-model="form.password">
                </div>
              </div>

              <div class="col-md-6">
                <label>Contact number</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">+971</span>
                  </div>
                  <input type="text" class="form-control form-control-lg" maxlength="9"
                         aria-label="contact" aria-describedby="basic-addon1" v-model="form.contact">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Service Category <b>{{ getSelectedCategoryName }}</b></label>
                  <div>
                    <a href="javascript:void(0);"
                       v-for="category in serviceCategories"
                       :class="`form-control category-color ${+category.id===+form.service_category_id?'active':''}`"
                       :style="`background: ${category.color}`"
                       @click="form.service_category_id=+category.id"></a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Image</label>
                  <div class="card">
                    <div class="card-body">
                      <!--                    <img :src="form.image_url" class="img-thumbnail " width="50" alt="">-->
                      <input type="file" id="input-file-now" name="image"
                             accept=".png,.jpg,.jpeg"
                             data-default-file="/img/default-avatar-user.png"
                             class="dropify"
                             @change="form.image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
            <a href="javascript:void(0);" class="btn btn-success" @click="createUpdate()"><i class="fa fa-check"></i>
              {{ form.id ? 'Update' : 'Save' }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <ServiceCategoryModal
        ref="serviceCategories"
    />
  </div>
</template>

<script>
import ServiceCategoryModal from "@components/admin/includes/ServiceCategoryModal";

export default {
  name: "Employees",
  components: {ServiceCategoryModal},
  props: {
    employees: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      serviceCategories: [],
      isLoading: false,
      allEmployees: this.employees,
      form: {
        id: '',
        service_category_id: '',
        name: '',
        title: '',
        email: '',
        password: '',
        contact: '',
        image: '',
        image_url: '',
      },
    }
  },
  methods: {
    createUpdate(){
      this.isLoading = true;
      axios.post('/account/employee', this.form)
      .then(res => {
        this.$toast.success(`Successfully, Employee has been ${this.form.id ? 'updated' : 'created'}.`);
        this.allEmployees = res.data.data.employees ?? [];
        $('#employee-modal').modal('hide');
        this.reset();
        $('.modal-backdrop.fade.show').remove();
      })
      .catch(err => {
        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
      }).finally(() => this.isLoading = false);
    },
    edit(data){
      this.serviceCategories = this.$refs.serviceCategories.getCategories();
      this.form.id = data.id;
      this.form.service_category_id = data.service_category_id;
      this.form.name = data.name;
      this.form.title = data.title;
      this.form.email = data.email;
      this.form.contact = data.mobile;
      this.form.password = '';
      this.form.image_url = data.user_image_url;
      $('#employee-modal').modal('show');
    },
    remove(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete Employee?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete',
        reverseButtons: true
      }).then((result) => {
        if(result.isConfirmed) {
          axios.delete(`/account/employee/${id}`)
          .then(res => {
            this.$toast.success(`Successfully, Employee has been deleted`);
            this.allEmployees = res.data.data.employees ?? [];
          })
          .catch(err => {
            this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
          });
        }
      })
    },
    reset(){
      this.form.id = '';
      this.form.service_category_id = '';
      this.form.name = '';
      this.form.title = '';
      this.form.email = '';
      this.form.password = '';
      this.form.contact = '';
      this.form.image = '';
      this.form.image_url = '';
      this.serviceCategories = this.$refs.serviceCategories.getCategories();
    },
  },
  computed: {
    getSelectedCategoryName(){
      return this.form.service_category_id && this.serviceCategories ? this.serviceCategories.find(c => +c.id === +this.form.service_category_id).name : '';
    }
  }
}
</script>

<style scoped>

</style>
