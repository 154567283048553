<template>
    <div class="row">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />
        <div class="col-md-2 col-sm-12">
            <div class="info-box link-stats">
                <span class="info-box-icon bg-info">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="currentColor" d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7c1.3 3 4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4c32.7 12.3 69 19.4 107.4 19.4c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4l17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z"/></svg>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">SMS Available</span>
                    <span class="info-box-number"
                          id="expired-booking">{{ sms }}</span>
                </div>
                <!-- /.info-box-content -->
            </div>
        </div>

        <div class="col-sm-12">
            <main class="col-12 max-w-6xl mx-auto pt-10 pb-36 px-8">
                <div class="flex flex-col justify-between items-center lg:flex-row lg:items-start">

                    <div v-if="packages[0]" class="w-full flex-1 mt-8 p-8 order-2 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-r-none">
                        <div class="mb-7 pb-7 flex items-center border-b border-gray-300">
                            <img src="https://res.cloudinary.com/williamsondesign/abstract-1.jpg" alt="" class="rounded-3xl w-20 h-20"/>
                            <div class="ml-5">
                                <span class="block text-2xl font-semibold">{{ packages[0].title }}</span>
                                <span><span class="font-medium text-gray-500 text-xl align-top"></span><span class="text-3xl font-bold">{{ packages[0]?.price.toFixed(2) }} </span></span><span class="text-gray-500 font-medium">AED</span>
                            </div>
                        </div>
                        <ul class="mb-7 font-medium text-gray-500">
                            <li class="text-lg mb-0  w-full text-center">
                                <div class="form-group text-center" style="display: inline-flex;">
                                    <div class="switch-div mr-2">
                                        <label class="switch">
                                            <input name="booking_button" type="checkbox" v-model="packages[0].auto_renew" @change="offAutoRenew(packages[0])">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <label class="switch-name">Renew</label>
                                </div>
                            </li>
                            <li class="flex text-lg mb-2">
                                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg"/>
                                <span class="ml-3">SMS to <span class="text-black">Customers</span></span>
                            </li>
                            <li class="flex text-lg">
                                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg"/>
                                <span class="ml-3"><span class="text-black">{{ packages[0].count }} </span> SMS</span>
                            </li>
                            <li class="flex text-lg">
                                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg"/>
                                <span class="ml-3"><span class="text-black">24/7 </span> support</span>
                            </li>
                        </ul>
                        <button class="flex w-full justify-center items-center bg-green-500 rounded-xl py-5 px-4 text-center text-white text-xl"
                                @click="buy(packages[0])">
                            Buy
                            <img src="https://res.cloudinary.com/williamsondesign/arrow-right.svg" class="ml-2"/>
                        </button>
                    </div>

                    <div v-if="packages[1]" class="w-full flex-1 p-8 order-1 shadow-xl rounded-3xl bg-sky-900 text-orange-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0">
                        <div class="mb-8 pb-8 flex items-center border-b border-gray-600">
                            <img src="https://res.cloudinary.com/williamsondesign/abstract-2.jpg" alt="" class="rounded-3xl w-20 h-20"/>
                            <div class="ml-5">
                                <span class="block text-3xl font-semibold text-white">{{ packages[1].title }}</span>
                                <span><span class="font-medium text-xl align-top"></span><span class="text-3xl font-bold text-white">{{ packages[1].price.toFixed(2) }} </span></span><span class="font-medium">AED</span>
                            </div>
                        </div>
                        <ul class="mb-10 font-medium text-xl">
                            <li class="text-lg mb-0  w-full text-center">
                                <div class="form-group text-center" style="display: inline-flex;">
                                    <div class="switch-div mr-2">
                                        <label class="switch">
                                            <input name="booking_button" type="checkbox" v-model="packages[1].auto_renew" @change="offAutoRenew(packages[1])">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <label class="switch-name">Renew</label>
                                </div>
                            </li>
                            <li class="flex mb-6">
                                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg"/>
                                <span class="ml-3">SMS to <span class="text-white">Customers</span></span>
                            </li>
                            <li class="flex mb-6">
                                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg"/>
                                <span class="ml-3"> {{ packages[1].count }} <span class="text-white"> SMS</span></span>
                            </li>
                            <li class="flex">
                                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg"/>
                                <span class="ml-3">24/7 <span class="text-white"> support</span> </span>
                            </li>
                        </ul>
                        <button class="flex w-full justify-center items-center bg-green-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
                                @click="buy(packages[1])">
                            Buy
                            <img src="https://res.cloudinary.com/williamsondesign/arrow-right.svg" class="ml-2"/>
                        </button>
                    </div>

                    <div v-if="packages[2]" class="w-full flex-1 mt-8 p-8 order-3 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-3 lg:rounded-l-none">
                        <div class="mb-7 pb-7 flex items-center border-b border-gray-300">
                            <img src="https://res.cloudinary.com/williamsondesign/abstract-3.jpg" alt="" class="rounded-3xl w-20 h-20"/>
                            <div class="ml-5">
                                <span class="block text-2xl font-semibold">{{ packages[2].title }}</span>
                                <span><span class="font-medium text-gray-500 text-xl align-top"></span><span class="text-3xl font-bold">{{ packages[2].price.toFixed(2) }} </span></span><span class="text-gray-500 font-medium">AED</span>
                            </div>
                        </div>
                        <ul class="mb-7 font-medium text-gray-500">
                            <li class="text-lg mb-0  w-full text-center">
                                <div class="form-group text-center" style="display: inline-flex;">
                                    <div class="switch-div mr-2">
                                        <label class="switch">
                                            <input name="booking_button" type="checkbox" v-model="packages[2].auto_renew" @change="offAutoRenew(packages[2])">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <label class="switch-name">Renew</label>
                                </div>
                            </li>
                            <li class="flex text-lg mb-2">
                                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg"/>
                                <span class="ml-3">SMS to <span class="text-black">Customers</span></span>
                            </li>
                            <li class="flex text-lg">
                                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg"/>
                                <span class="ml-3"><span class="text-black">{{ packages[2].count }} </span> SMS</span>
                            </li>
                            <li class="flex text-lg">
                                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg"/>
                                <span class="ml-3"><span class="text-black">24/7 </span> support</span>
                            </li>
                        </ul>
                        <button class="flex w-full justify-center items-center bg-green-500 rounded-xl py-5 px-4 text-center text-white text-xl"
                                @click="buy(packages[2])">
                            Buy
                            <img src="https://res.cloudinary.com/williamsondesign/arrow-right.svg" class="ml-2"/>
                        </button>
                    </div>
                </div>
            </main>
        </div>

    </div>
</template>

<script>
export default {
    name: "Index",
    props: {
        sms: {
            required: true,
            type: Number,
        },
        allPackages: {
            required: true,
            type: Array,
        },
        order: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isLoading: false,
            packages: []
        }
    },
    mounted() {
        if (this.allPackages) {
            this.packages = this.allPackages.map(p => {
                p.auto_renew = this.order?.ref_id === p.id && this.order?.auto_renew;
                return p;
            })
        }
    },
    methods: {
        buy(plan) {
            swal({
                icon: 'info',
                title: 'Are you sure?',
                text: `Do you want to buy ${plan.title} package?`,
                buttons: ["Cancel", `Buy ${plan.count} SMS`],
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.isLoading = true;
                    axios.post(`/account/sms-offer/buy/${plan.id}/${plan.auto_renew ?? 0}`)
                        .then(res => {
                            this.$toast.success(`Successfully, You have purchased ${plan.title} Package.`);
                            setTimeout(() => {
                                window.location.href = res.data.data.redirect_to;
                            }, 1000);
                        })
                        .catch(err => {
                            this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                            this.isLoading = false
                        })
                }
            });
        },
        offAutoRenew(plan) {
            if(this.order && this.order?.ref_id === plan.id && !!this.order?.auto_renew){
                this.isLoading = true;
                axios.get(`/account/update/order/off/renew/${this.order.id}`)
                    .then(res => {
                        this.$toast.success('Auto Renew has been updated')
                    })
                    .catch(err => this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText))
                    .finally(() => this.isLoading = false);
            }
        }
    },
}
</script>

<style scoped>

</style>
