<template>
    <!-- Booking statics -->
    <div class="row">
        <div class="col-md-12 col-sm-12 mb-2 pl-3">
            <div class="text-2xl display-1 font-semibold">{{ title }}</div>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
            <div class="info-box link-stats booking-status" data-booking-status="pending">
                <span class="info-box-icon bg-warning"><i class="fa fa-calendar"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Pending</span>
                    <span class="info-box-number" id="pending-booking">{{ pending }}</span>
                </div>
            </div>
        </div>

        <div class="col-md-2 col-sm-6 col-12">
            <div class="info-box link-stats booking-status" data-booking-status="awaiting_time">
                <span class="info-box-icon bg-info"><i class="fa fa-calendar"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Awaiting</span>
                    <span class="info-box-number"
                          id="awaiting-time-booking">{{ awaitingTime }}</span>
                </div>
            </div>
        </div>

        <div class="col-md-2 col-sm-6 col-12">
            <div class="info-box link-stats booking-status" data-booking-status="completed">
                <span class="info-box-icon bg-success"><i class="fa fa-calendar"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Completed</span>
                    <span class="info-box-number"
                          id="completed-booking">{{ completed }}</span>
                </div>
            </div>
        </div>

        <!-- /.col -->
        <div class="col-md-2 col-sm-6 col-12">
            <div class="info-box link-stats booking-status" data-booking-status="canceled">
                <span class="info-box-icon bg-danger"><i class="fa fa-calendar"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Cancelled</span>
                    <span class="info-box-number"
                          id="canceled-booking">{{ cancelled }}</span>
                </div>
            </div>
        </div>
        <!-- /.col -->
        <div class="col-md-2 col-sm-6 col-12">
            <div class="info-box link-stats booking-status" data-booking-status="expired">
                <span class="info-box-icon bg-danger"><i class="fa fa-calendar"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Expired</span>
                    <span class="info-box-number"
                          id="expired-booking">{{ expired }}</span>
                </div>
            </div>
        </div>
        <!-- /.col -->

        <!-- /.col -->
        <div v-if="role === 'admin'" class="col-md-2 col-sm-6 col-12">
            <div class="info-box link-stats booking-status" data-booking-status="expired">
                <span class="info-box-icon bg-info">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="currentColor" d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7c1.3 3 4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4c32.7 12.3 69 19.4 107.4 19.4c141.4 0 256-93.1 256-208S397.4 32 256 32zM128.2 304H116c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H156c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-19 38.6-42.4 38.6zm191.8-8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-68.2l-24.8 55.8c-2.9 5.9-11.4 5.9-14.3 0L224 227.8V296c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8V192c0-8.8 7.2-16 16-16h16c6.1 0 11.6 3.4 14.3 8.8l17.7 35.4l17.7-35.4c2.7-5.4 8.3-8.8 14.3-8.8h16c8.8 0 16 7.2 16 16v104zm48.3 8H356c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h12.3c6 0 10.4-3.5 10.4-6.6c0-1.3-.8-2.7-2.1-3.8l-21.9-18.8c-8.5-7.2-13.3-17.5-13.3-28.1c0-21.3 19-38.6 42.4-38.6H396c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8h-12.3c-6 0-10.4 3.5-10.4 6.6c0 1.3.8 2.7 2.1 3.8l21.9 18.8c8.5 7.2 13.3 17.5 13.3 28.1c.1 21.3-18.9 38.6-42.3 38.6z"/></svg>
                </span>
                <div class="info-box-content">
                    <span class="info-box-text">SMS</span>
                    <span class="info-box-number"
                          id="expired-booking">{{ sms }}</span>
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
</template>

<script>
export default {
    name: 'BookingStatics',
    props: {
        title: {
            type: String,
            default: ''
        },
        role: {
            required: true,
            type: String
        },
        pending: {
            type: Number,
            default: 0
        },
        awaitingTime: {
            type: Number,
            default: 0
        },
        completed: {
            type: Number,
            default: 0
        },
        cancelled: {
            type: Number,
            default: 0
        },
        expired: {
            type: Number,
            default: 0
        },
        sms: {
            type: Number,
            default: 0
        },
    }
}
</script>

<style scoped>

</style>
