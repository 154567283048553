<template>
    <section id="dashboard">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />

        <!-- Booking statics -->
        <BookingStatics
            title="All Bookings Statics"
            :role="role"
            :pending="+bookingStats.pending"
            :awaiting-time="+bookingStats.awaiting_time"
            :completed="+bookingStats.completed"
            :cancelled="+bookingStats.cancelled"
            :expired="+bookingStats.expired"
            :sms="+bookingStats.sms"
        />

        <!-- Monthly Statics -->
        <div class="row">

            <!-- Recent Sales-->
            <div class="col-sm-12 col-md-6 text-right mb-2 bg-white p-6 capitalize">
                <div class="text-3xl display-1 font-semibold pull-left border-">Recent {{ isAdmin() ? 'Sales' : 'Appointments' }}</div>
                <div class="">
                    <date-range-picker
                            v-if="recentDates.startDate && recentDates.endDate && maxDate"
                            ref="dtRecent"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                            single-date-picker="range"
                            :ranges="false"
                            :show-dropdowns="true"
                            :maxDate="maxDate"
                            v-model="recentDates"
                            :autoApply="true"
                            @update="recentDateUpdate"
                    >
                    <!--        <template v-slot:input="picker" style="min-width: 350px;">-->
                    <!--            {{ picker.startDate }} - {{ picker.endDate }}-->
                    <!--        </template>-->
                    </date-range-picker>

                    <!--  <v-date-picker inline />-->
<!--                    <v-date-picker-->
<!--                            mode="date"-->
<!--                            v-model="range"-->
<!--                            inline-->
<!--                    />-->
                </div>
<!--                <button :class="`btn btn-sm mx-0 ${recent.days === 7?'btn-dark':'btn-light'}`" @click="getStatsBy('recent',7)">Last 7 Days</button>-->
<!--                <button :class="`btn btn-sm mx-0 ${recent.days === 30?'btn-dark':'btn-light'}`" @click="getStatsBy('recent',30)">Last 30 Days</button>-->
                <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white rounded-lg px-0 mt-2">
                    <ul v-if="recent.orders.length" class="flex flex-col w-full h-[380px] overflow-y-scroll divide-y divide-dashed divide-gray-400">
                        <li v-for="order in recent.orders"
                            :key="order.id"
                            class="flex flex-row hover:(shadow-lg bg-green-500)">
                            <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
                                <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
                                    <a href="#" class="block relative">
                                        <img alt="profil"
                                             :src="order.user?.user_image_url"
                                             class="mx-auto object-cover rounded-full h-10 w-10"/>
                                    </a>
                                </div>
                                <div class="flex-1 pl-1 text-left">
                                    <div class="font-small font-semibold">{{ order.user?.name }}</div>
                                    <div class="text-gray-600 text-xs">{{ momentFormat(order.date) }}</div>
                                </div>
                                <div class="flex flex-row justify-center">
                                    <div class="text-gray-500 text-md mr-4">
                                        <b>Sale:</b> {{ moneyFormat(order.amount) }}
                                    </div>
                                    <div class="text-red-500 text-md">
                                        <b>{{ order.commission?.percent }}% Commission:</b> {{ moneyFormat(order.commission?.amount) }}
                                    </div>

                                    <!--                                    <button class="w-10 text-right flex justify-end">-->
                                    <!--                                        <svg width="20" fill="currentColor" height="20" class="text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>-->
                                    <!--                                        </svg>-->
                                    <!--                                    </button>-->
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Alert
                        v-else
                        type="gray"
                        title="No Sales Data"
                        message="Make some appointments for sales data to appear"
                        class="my-4"
                    />
                </div>
            </div>

            <!-- Upcoming appointments -->
            <div class="col-sm-12 col-md-6 text-right mb-2 bg-white p-6 capitalize">
                <div class="text-3xl display-1 font-semibold pull-left mb-3">Upcoming appointments</div>
                 <div class="">
                    <date-range-picker
                            v-if="upcomingDates.startDate && upcomingDates.endDate && minDate"
                            ref="dtUpcoming"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                            single-date-picker="range"
                            :ranges="false"
                            :show-dropdowns="true"
                            :minDate="minDate"
                            v-model="upcomingDates"
                            :autoApply="true"
                            @update="upcomingDateUpdate"
                    >
                    <!--        <template v-slot:input="picker" style="min-width: 350px;">-->
                    <!--            {{ picker.startDate }} - {{ picker.endDate }}-->
                    <!--        </template>-->
                    </date-range-picker>

                     <!--                    <v-date-picker inline />-->
                     <!--                    <v-date-picker-->
                     <!--                            mode="date"-->
                     <!--                            v-model="range"-->
                     <!--                            inline-->
                     <!--                    />-->
                </div>
<!--                <button :class="`btn btn-sm mx-0 ${upcoming.days === 7?'btn-dark':'btn-light'}`" @click="getStatsBy('upcoming',7)">Last 7 Days</button>-->
<!--                <button :class="`btn btn-sm mx-0 ${upcoming.days === 30?'btn-dark':'btn-light'}`" @click="getStatsBy('upcoming',30)">Last 30 Days</button>-->
                <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white rounded-lg px-0 mt-2">
                    <ul v-if="upcoming.orders.length" class="flex flex-col w-full h-[380px] overflow-y-scroll divide-y divide-dashed divide-gray-400">
                        <li v-for="order in upcoming.orders"
                            :key="order.id"
                            class="flex flex-row hover:(shadow-lg bg-green-500)">
                            <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
                                <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
                                    <a href="#" class="block relative">
                                        <img alt="profil"
                                             :src="order.user?.user_image_url"
                                             class="mx-auto object-cover rounded-full h-10 w-10"/>
                                    </a>
                                </div>
                                <div class="flex-1 pl-1 text-left">
                                    <div class="font-small font-semibold">{{ order.user?.name }}</div>
                                    <div class="text-gray-600 text-xs">{{ momentFormat(isBusinessService(order) ? order.date : order.created_at) }}</div>
                                </div>
                                <div class="flex flex-row justify-center">
                                    <!--                                     <div class="text-gray-500 text-md mr-4">-->
                                    <!--                                         <b>Sale:</b> 200 AED-->
                                    <!--                                     </div>-->
                                    <!--                                     <div class="text-green-500 text-md">-->
                                    <!--                                         <b>20% Commission:</b> 40-->
                                    <!--                                     </div>-->

                                    <!--                                    <button class="w-10 text-right flex justify-end">-->
                                    <!--                                        <svg width="20" fill="currentColor" height="20" class="text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>-->
                                    <!--                                        </svg>-->
                                    <!--                                    </button>-->
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Alert
                        v-else
                        type="gray"
                        title="Your schedule is empty"
                        message="Make some appointments for schedule data to appear"
                        class="my-4"
                    />
                </div>
            </div>

            <!-- Today's next appointments -->
            <div class="col-sm-12 col-md-6 text-right mb-2 bg-white p-6 capitalize">
                <div class="text-3xl display-1 font-semibold pull-left mb-3">
                    Today's next appointments
                </div>
                <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white rounded-lg px-0 mt-2">
                    <ul v-if="todayBookings.length" class="flex flex-col w-full h-[380px] overflow-y-scroll divide-y divide-dashed divide-gray-400">
                        <li v-for="booking in todayBookings"
                            :key="`tb-${booking.id}`"
                            class="flex flex-row hover:(shadow-lg bg-green-500)">
                            <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
                                <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
                                    <a href="#" class="block relative">
                                        <img alt="profil"
                                             :src="booking.user?.user_image_url"
                                             class="mx-auto object-cover rounded-full h-10 w-10"/>
                                    </a>
                                </div>
                                <div class="flex-1 pl-1 text-left">
                                    <div class="font-small font-semibold">
                                        {{ booking.user?.name }}
                                    </div>
                                    <div class="text-gray-600 text-md">
                                        {{ momentFormat(booking.date, 'LL') }}, {{ booking.time }}
                                    </div>
                                </div>
                                <div class="flex flex-row justify-center">
                                    <div class="text-green-500 text-md mr-4">
                                        <b>Staff Preference:</b> {{ booking?.employee ? booking?.employee?.name : 'No' }}
                                    </div>
                                    <!--                                     <div class="text-green-500 text-md">-->
                                    <!--                                         <b>20% Commission:</b> 40-->
                                    <!--                                     </div>-->

                                    <!--                                    <button class="w-10 text-right flex justify-end">-->
                                    <!--                                        <svg width="20" fill="currentColor" height="20" class="text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>-->
                                    <!--                                        </svg>-->
                                    <!--                                    </button>-->
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Alert
                        v-else
                        type="gray"
                        title="No Appointments Today"
                        message="Visit the calendar section to add some appointments"
                        class="my-4"
                    />
                </div>
            </div>

            <!-- Top services -->
            <div v-if="isAdmin()" class="col-sm-12 col-md-6 text-right mb-2 bg-white p-6">
                <div class="text-3xl display-1 font-semibold pull-left mb-3">
                    Top Services
                </div>
                <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white rounded-lg px-0 mt-2">
                    <ul v-if="topServices.length>0" class="flex flex-col w-full h-[380px] overflow-y-scroll divide-y divide-dashed divide-gray-400">
                        <li v-for="service in topServices"
                            :key="`ts-${service.id}`"
                            class="flex flex-row hover:(shadow-lg bg-green-500)">
                            <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center py-3">
                                <!--                                <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">-->
                                <!--                                    <a href="#" class="block relative">-->
                                <!--                                        <img alt="profil"-->
                                <!--                                             :src="service?.name"-->
                                <!--                                             class="mx-auto object-cover rounded-full h-10 w-10"/>-->
                                <!--                                    </a>-->
                                <!--                                </div>-->
                                <div class="flex-1 pl-1 text-left">
                                    <div class="font-small font-semibold">
                                        {{ service.name }}
                                        <span class="badge badge-success">{{ service.total_orders }} Orders</span>
                                    </div>
                                    <div class="text-gray-600 text-md">
                                        {{ momentFormat(service.created_at) }}
                                    </div>
                                </div>
                                <div class="flex flex-row justify-center">
                                    <div class="text-green-500 text-md mr-4">
                                        <span class="badge badge-dark mr-1">{{ timeFormat(service.price_duration) }}</span>
                                        <small class="badge badge-light">{{ moneyFormat(service.price) }}</small>
                                        <!--                                        <b>Staff Preference:</b> {{ booking?.employee ?booking?.employee?.name:'No'}}-->
                                    </div>
                                    <!--                                     <div class="text-green-500 text-md">-->
                                    <!--                                         <b>20% Commission:</b> 40-->
                                    <!--                                     </div>-->

                                    <!--                                    <button class="w-10 text-right flex justify-end">-->
                                    <!--                                        <svg width="20" fill="currentColor" height="20" class="text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>-->
                                    <!--                                        </svg>-->
                                    <!--                                    </button>-->
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Alert
                        v-else
                        type="gray"
                        title="No sales in last 30 days"
                        message="Create some sales for sales data to appear"
                        class="my-4"
                    />
                </div>
            </div>

            <!-- Top team member -->
            <div v-if="isAdmin()" class="col-sm-12 col-md-6 text-right mb-2 bg-white p-6">
                <div class="text-3xl display-1 font-semibold pull-left mb-3">
                    Top team member
                </div>
                <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white rounded-lg px-0 mt-2">

                    <ul v-if="topEmployee.length>0" class="flex flex-col w-full h-[380px] overflow-y-scroll divide-y divide-dashed divide-gray-400">
                        <li v-for="user in topEmployee"
                            :key="`tu-${user.id}`"
                            class="flex flex-row hover:(shadow-lg bg-green-500)">
                            <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
                                <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
                                    <a href="#" class="block relative">
                                        <img alt="profil"
                                             :src="user?.user_image_url"
                                             class="mx-auto object-cover rounded-full h-10 w-10"/>
                                    </a>
                                </div>
                                <div class="flex-1 pl-1 text-left">
                                    <div class="font-small font-semibold">
                                        {{ user?.name }}
                                        <span class="badge badge-success">{{ user.total_orders }} Orders</span>
                                    </div>
                                    <div class="text-gray-600 text-xs">{{ momentFormat(user.created_at) }}</div>
                                </div>
                                <div class="flex flex-row justify-center">
                                    <!--                                     <div class="text-gray-500 text-md mr-4">-->
                                    <!--                                         <b>Sale:</b> 200 AED-->
                                    <!--                                     </div>-->
                                    <!--                                     <div class="text-green-500 text-md">-->
                                    <!--                                         <b>20% Commission:</b> 40-->
                                    <!--                                     </div>-->

                                    <!--                                    <button class="w-10 text-right flex justify-end">-->
                                    <!--                                        <svg width="20" fill="currentColor" height="20" class="text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">-->
                                    <!--                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>-->
                                    <!--                                        </svg>-->
                                    <!--                                    </button>-->
                                </div>
                            </div>

                        </li>
                    </ul>
                    <Alert
                        v-else
                        type="gray"
                        title="No sales in last 30 days"
                        message="Create some sales for sales data to appear"
                        class="my-4"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HelperMixin from '@mixins/helperMixin';
import OrderMixin from '@mixins/orderMixin';
import TimeDateMixin from '@mixins/timeDateMixin';
import BookingStatics from "@components/includes/BookingStatics";
import Alert from "@components/includes/Alert";
import DateRangePicker from "vue2-daterange-picker"
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: "Dashboard",
    components: {BookingStatics, Alert, DateRangePicker,},
    mixins: [HelperMixin, TimeDateMixin, OrderMixin],
    props: {
        role: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            isLoading: false,
            bookingStats: {
                pending: 0,
                awaiting_time: 0,
                completed: 0,
                expired: 0,
                cancelled: 0,
                sms:0
            },
            minDate: null,
            maxDate: null,
            recentDates:{
                startDate: '',
                endDate: '',
            },
            upcomingDates:{
                startDate: '',
                endDate: '',
            },
            recent: {
                days: 7,
                orders: []
            },
            upcoming: {
                days: 7,
                orders: []
            },
            todayBookings: [],
            topServices: [],
            topEmployee: [],
        }
    },
    mounted() {
        this.getStatsCounters();
        this.getStatsBy('recent', 7);
        this.getStatsBy('upcoming', 7);
        this.getTodayBookings();
        if (this.isAdmin()) {
            this.getTopRatedServicesAndEmployees();
        }
        this.maxDate = new Date(moment().subtract(1,'d').format('LL'));
        this.recentDates.startDate = new Date(moment().subtract(7,'d').format('LL'));
        this.recentDates.endDate = new Date(moment().subtract(1,'d').format('LL'));

        this.minDate = new Date(moment().add(1,'d').format('LL'));
        this.upcomingDates.startDate = new Date(moment().add(1,'d').format('LL'));
        this.upcomingDates.endDate = new Date(moment().add(7,'d').format('LL'));
    },
    methods: {
        isAdmin() {
            return this.role === 'admin';
        },
        getStatsCounters() {
            this.isLoading = true;
            axios.get(`/account/dashboard/stats`)
                .then(res => {
                    this.bookingStats = res.data.data.stats;
                })
                .finally(() => this.isLoading = false)
        },
        getStatsBy(action = '', days = 1) {
            this.isLoading = true;
            axios.get(`/account/dashboard/stats/by/${action}/${days}`)
                .then(res => {
                    if (action === 'recent') {
                        this.recent.days = days;
                        this.recent.orders = res.data.data.orders;
                    } else if (action === 'upcoming') {
                        this.upcoming.days = days;
                        this.upcoming.orders = res.data.data.orders;
                    }
                })
                // .catch(err => )
                .finally(() => this.isLoading = false);
        },
        getTopRatedServicesAndEmployees() {
            this.isLoading = true;
            axios.get(`/account/dashboard/top/services/employees`)
                .then(res => {
                    this.topServices = res.data.data.top.services;
                    this.topEmployee = res.data.data.top.employees;
                })
                // .catch(err => )
                .finally(() => this.isLoading = false);
        },
        getTodayBookings() {
            this.isLoading = true;
            axios.get(`/account/dashboard/today/bookings`)
                .then(res => {
                    this.todayBookings = res.data.data.today_bookings;
                })
                // .catch(err => )
                .finally(() => this.isLoading = false);
        },
        recentDateUpdate(){
            let days = Math.abs(moment(this.recentDates.startDate).diff(moment(this.recentDates.endDate),'days'))
            this.getStatsBy('recent', days);
        },
        upcomingDateUpdate(){
            let days = Math.abs(moment(this.upcomingDates.startDate).diff(moment(this.upcomingDates.endDate),'days'))
            this.getStatsBy('upcoming', days);
        }
    },
}
</script>

<style>
#dashboard .daterangepicker.openscenter{
    left: 10% !important;
}
</style>

<style scoped>

</style>
