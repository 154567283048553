<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-4 h-full">
                    <!-- Default switch -->
                    <div class="form-group mb-0" style="display: inline-flex;">
                        <div class="switch-div mr-2">
                            <label class="switch">
                                <input name="booking_button" type="checkbox" v-model="form.all">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <label class="switch-name">All Customers</label>
                    </div>
                    <multiselect v-model="form.selectedCustomers"
                                 :options="options"
                                 :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false"
                                 :preserve-search="true"
                                 placeholder="Select Customers"
                                 label="name"
                                 track-by="name"
                                 :preselect-first="false"
                                 :disabled="form.all"
                    />
                </div>

                 <div v-if="isSuperadmin" class="col-sm-12 col-md-4 mt-4">
                     <label for="">Title</label>
                     <input type="text" class="form-control" v-model="form.title" />
                 </div>

                <div class="col-sm-12 mt-4">
                    <label for="">Write SMS</label>
                    <textarea class="form-control" rows="8" v-model="form.sms"></textarea>
                </div>

                <div class="col-sm-12 mt-4">
                    <button class="btn btn-success pull-right" @click="send">Send</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "SendSMS",
    components: {Multiselect},
    props: {
        isSuperadmin: {
            type: Boolean,
            default: []
        },
        customers: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            isLoading: false,
            form: {
                all: false,
                selectedCustomers: [],
                title: '',
                sms: '',
            },
            options: [
                ...this.customers
            ],
        }
    },
    methods: {
        send() {
            this.isLoading = true;
            axios.post('/account/sms/send', this.form)
                .then(res => {
                    this.$toast.success(`Successfully, SMS has been sent.`);
                    this.form.sms = '';
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                })
                .finally(() => this.isLoading = false)
        }
    }
}
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    /*width: 49px !important;*/
    /*height: 23px !important;*/
}
</style>
