<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
    <!--    listing-->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center justify-content-md-end mb-3">
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1" data-toggle="modal"
                 data-target="#membership-modal" @click="reset"><i class="fa fa-plus"></i> Add Membership</a>
            </div>

            <div class="row ">
              <div v-for="membership in memberships" class="col-md-6">
                <membership-card
                    :membership-id="membership.id"
                    :theme="membership.theme"
                    size="sm"
                    :can-edit="true"
                    :click-able="true"
                    :title="membership.name"
                    :price="+membership.price"
                    :valid-for="membership.valid_for"
                    :select-all="!!membership.all_services"
                    :services-count="+membership.services.length"
                    :sessions.sync="membership.sessions"
                    :no-of-session.sync="+membership.no_of_session"
                    @click="clickMembership"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--create or update modal-->
    <div class="modal fade bs-modal-xl in" id="membership-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-xl-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">{{ form.id ? 'Edit' : 'Create' }} Membership</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">

            <!--            Basic info-->
            <div class="row">
              <div class="col-md-6">

                <div class="col-sm-12">
                  <h3>Create Membership <small>{{ step }}/2 steps</small></h3>
<!--                  <small class="text-muted">Add the Membership name, value and duration of the Membership. If the Membership-->
<!--                    value is higher than the retail price it will encourage more sales.</small>-->
<!--                  <hr>-->
                </div>

                <TransitionGroup enter-active-class="animate__animated animate__flipInX" leave-active-class="animate__animated animate__flipOutX">
                  <!--                Step 1-->
                  <div v-show="step===1" :key="`step-1`">
                    <div class="col-md-12 mt-4">
                      <!-- text input -->
                      <div class="form-group">
                        <label>Membership name</label>
                        <input type="text" class="form-control" v-model="form.name">
                      </div>
                    </div>

                    <div class="col-md-12 d-flex">
                      <div class="form-group mb-0">
                        <label>Price</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">AED</span>
                          </div>
                          <input type="number" class="form-control" aria-label="Amount" v-model="form.price">
                          <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                          </div>
                        </div>
                      </div>

                      <div class="form-group ml-3 mb-0 w-50">
                        <label>Valid For</label>
                        <select class="form-control" v-model="form.valid_for">
                            <option value="7_days">7 days</option>
                            <option value="14_days">14 days</option>
                            <option value="1_months">1 month</option>
                            <option value="2_months">2 months</option>
                            <option value="3_months">3 months</option>
                            <option value="4_months">4 months</option>
                            <option value="6_months">6 months</option>
                            <option value="8_months">8 months</option>
                            <option value="1_years">1 year</option>
                            <option value="18_months">18 months</option>
                            <option value="2_years">2 years</option>
                            <option value="5_years">5 years</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-12 d-flex">
                      <div class="form-group mb-0 w-50" id="session_div">
                        <label>Sessions</label>
                        <select class="form-control" v-model="form.sessions">
                          <option value="limited">Limited</option>
                          <option value="unlimited">Unlimited</option>
                        </select>
                      </div>

                      <div v-if="form.sessions === 'limited'" class="form-group mb-0 w-50" id="no_of_session_div">
                        <label>Number of sessions</label>
                        <input type="number" class="form-control" v-model="form.no_of_session">
                      </div>
                    </div>

                    <div class="col-md-12 mt-3">
                      <!-- text input -->
                      <div class="form-group">
                        <label>Included services</label>
                        <p class="service-info">
                          {{ form.all_selected ? 'All' : form.services.length }} Services
                          <a href="javascript:void(0);" class="btn btn-sm pull-right" data-toggle="modal"
                             data-target="#select-services-modal"><i class="fa fa-pencil" style="font-size: 1.4rem;"></i></a>
                        </p>
                      </div>
                    </div>

                  </div>

                  <!--                Step 2-->
                  <div v-if="step===2" :key="`step-2`">

                    <label class="mb-0">Membership colour</label><br>
                    <small class="text-muted">Select a colour that matches your business.</small>
                    <div class="col-md-12 text-center" style="margin: 4rem 0 3rem 0px">
                      <a v-for="theme in themes" :key="theme"
                         href="javascript:void(0);"
                         :class="`theme-plate card-${theme} mr-4 ${form.theme === theme?'active':''}`"
                         @click="form.theme=theme"></a>
                    </div>

                    <div class="col-md-12 px-0 mt-4">
                      <label class="w-100 mt-4">Membership Description
                        <small class="text-muted pull-right">({{form.desc.length }}/360)</small></label>
                      <textarea class="form-control form-control-lg" maxlength="360" autocomplete="off" v-model="form.desc"></textarea>
                    </div>

                    <div class="col-md-12 px-0 mt-3">
                      <label class="w-100">Terms & Conditions
                        <small class="text-muted pull-right">({{form.terms.length }}/3000)</small></label>
                      <textarea class="form-control form-control-lg" maxlength="3000" autocomplete="off" v-model="form.terms"></textarea>
                    </div>

                  </div>
                </TransitionGroup>
              </div>

              <!--              Membership Card preview-->
              <div class="col-md-6">
                <membership-card
                    :theme="+form.theme"
                    size="lg"
                    :price.sync="+form.price"
                    :valid-for.sync="form.valid_for"
                    :sessions.sync="form.sessions"
                    :no-of-session.sync="+form.no_of_session"
                    :desc.sync="form.desc"
                    :select-all.sync="form.all_selected"
                    :services-count.sync="+form.services.length"
                    :terms.sync="form.terms"
                />
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
            <a v-if="step>1" href="javascript:void(0);" class="btn btn-dark" @click="back">Back</a>
            <a v-if="step<2" href="javascript:void(0);" class="btn btn-dark" @click="next">Next</a>
            <a v-if="step === 2" href="javascript:void(0);" class="btn btn-success" @click="createUpdate()"><i
                class="fa fa-check"></i>
              {{ form.id ? 'Update' : 'Save' }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--View modal-->
    <div class="modal fade bs-modal-xl in" id="view-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-xl-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">View Membership</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">

            <!--            Basic info-->
            <div class="row">

              <!--               Card preview-->
              <div class="col-md-7">
                <membership-card
                    :theme="+viewMembership.theme"
                    size="lg"
                    :price.sync="+viewMembership.price"
                    :valid-for.sync="viewMembership.valid_for"
                    :sessions.sync="viewMembership.sessions"
                    :no-of-session.sync="+viewMembership.no_of_session"
                    :select-all.sync="!!viewMembership.all_services"
                    :services-count.sync="+viewMembership.services.length"
                />
              </div>

              <div class="col-md-5">
                <div class="col-sm-12 text-center">
                  <h2>{{ viewMembership.title }} AED {{ (+viewMembership.price).toFixed(2) }}</h2>
                </div>
                <hr>
                <div class="col-sm-12 text-center">
                  <a href="javascript:void(0);" class="btn btn-outline-dark view-btn" @click="edit"><i
                      class="fa fa-pencil"></i></a>
                </div>
                <hr>
                <div class="col-sm-12 text-center">
                  <a href="javascript:void(0);" class="btn btn-outline-danger view-btn" @click="remove"><i
                      class="fa fa-remove"></i></a>
                </div>
                <hr>
                <div v-if="!!viewMembership.enable_client_note" class="col-sm-12">
                  <h6>Client Note</h6>
                  <p>{{ viewMembership.client_note }}</p>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <SelectServicesModal
        ref="selectService"
        :services="services"
        :all-services="form.all_selected"
        @updated="updateServices"
    />
  </div>
</template>

<script>
import SelectServicesModal from "@components/includes/SelectServicesModal";

export default {
  name: "Memberships",
  components: {SelectServicesModal},
  props: {
    services: {
      type: Array,
      default: () => []
    },
    membershipData: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      isLoading: false,
      memberships: this.membershipData ?? [],
      viewMembership: {
        services: {}
      },
      step: 1,
      themes: [0, 1, 2, 3, 4],
      form: {
        id: '',
        name: '',
        sessions:'limited',
        no_of_session: 5,
        price_type: 'one-time-payment',
        price: 0,
        valid_for: '14_days',
        all_selected: true,
        services: [],
        desc: '',
        terms:'',
        theme: 1,
      }
    }
  },
  methods: {
    next(){
      if(this.step === 1) {
        if(!this.form.name || !this.form.price || !this.form.valid_for || !this.form.sessions || !this.form.no_of_session) {
          return this.$toast.error('All Fields are required.')
        } else if(!this.form.services) {
          return this.$toast.error('Services are required.')
        }
      }
      if(this.step < 2) this.step++;
    },
    back(){
      if(this.step > 1) this.step--;
    },
    createUpdate(){
      this.isLoading = true;
      axios.post('/account/memberships', this.form)
      .then(res => {
        this.$toast.success(`Successfully, Membership has been ${this.form.id ? 'updated' : 'created'}.`);
        this.memberships = res.data.data.memberships ?? [];
        $('#membership-modal').modal('hide');
        this.reset();
      })
      .catch(err => {
        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
      }).finally(() => this.isLoading = false);
    },
    clickMembership(membershipId){
      this.viewMembership = this.memberships.find(v => v.id === membershipId);
      this.viewMembership.services = this.viewMembership.services.map(s => s.service) ?? [];
      $('#view-modal').modal('show');
    },
    edit(){
      this.form.id = this.viewMembership.id;
      this.form.name = this.viewMembership.name;
      this.form.price = +this.viewMembership.price;
      this.form.valid_for = this.viewMembership.valid_for;
      this.form.sessions = this.viewMembership.sessions;
      this.form.no_of_session = +this.viewMembership.no_of_session;
      this.form.all_selected = !!this.viewMembership.all_services ?? true;
      this.form.services = this.viewMembership.services ?? [];
      this.form.desc = this.viewMembership.desc ?? '';
      this.form.theme = +this.viewMembership.theme;
      this.form.terms = this.viewMembership.terms ?? '';

      $('#view-modal').modal('hide');
      $('#membership-modal').modal('show');

      this.$refs.selectService.setServices(this.form.services ?? []);
    },
    remove(){
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete Membership?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete',
        reverseButtons: true
      }).then((result) => {
        if(result.isConfirmed) {
          this.isLoading = true;
          axios.delete(`/account/memberships/${this.viewMembership.id}`)
          .then(res => {
            this.$toast.success(`Successfully, Membership has been deleted`);
            this.memberships = res.data.data.memberships ?? [];
            $('#view-modal').modal('hide');
            this.reset();
          })
          .catch(err => {
            this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
          })
          .finally(() => this.isLoading = false);
        }
      })
    },
    reset(){
      this.form.id = '';
      this.form.name = '';
      this.form.sessions = 'limited';
      this.form.no_of_session = 5;
      this.form.price_type = 'one-time-payment';
      this.form.price = 0;
      this.form.valid_for = '14_days';
      this.form.all_selected = true;
      this.form.services = [];
      this.form.desc = '';
      this.form.terms = '';
      this.form.theme = 1;

      this.step = 1;

      this.viewMembership = {
        services: {}
      }
      this.$refs.selectService.reset();
    },
    updateServices(data){
      this.form.all_selected = data.all;
      this.form.services = data.services;
    }
  }
}
</script>

<style scoped>
.input-group-text {
  background: white;
}

.service-info {
  padding: 10px 15px;
  font-size: 20px;
  background: #e1dede78;
  border-radius: 6px;
}

.theme-plate {
  background: red;
  padding: 10px 18px;
  border-radius: 50%;
}

.theme-plate.active {
  border: 3px solid #00fa00;
  padding: 17px 26px;
}

.card-0{
  /*background: linear-gradient(0deg,#8b60ed,#b372bd);*/
  background: linear-gradient(0deg,#8b60ed,#b372bd);
  box-shadow: 0px 0px 20px 2px #8b60ed;
}
.card-1{
  background: linear-gradient(0deg,#f19a1a,#ffc73c);
  box-shadow: 0px 0px 20px 2px #f19a1a;
}
.card-2{
  background: linear-gradient(180deg,#f44881,#ec454f);
  box-shadow: 0px 0px 20px 2px #f44881;
}
.card-3{
  background: linear-gradient(180deg,#6edcc4,#1aab8b);
  box-shadow: 0px 0px 20px 2px #6edcc4;
}
.card-4{
  background: linear-gradient(351deg, #3f5efb,#5cd9d8);
  box-shadow: 0px 0px 20px 2px #3f5efb;
}

label {
  font-weight: 500;
}

.view-btn {
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 6px 4px 26px -5px;
}

.view-btn i.fa {
  font-size: 2rem;
}

#session_div {
  width: 46% !important;
}

#no_of_session_div {
  padding-left: 1rem;
  width: 52% !important;
}
</style>
