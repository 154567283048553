<template>
    <section id="book-section">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />
        <div class="container md:px-52 sm:px-20">
            <div class="row">

                <div class="col-md-8 col-sm-12" id="detail-section">
                    <div class="col-md-12 pb-2 px-0">
                        <p class="step-title">
                            <a href="javascript:void(0);" @click="back" class="back"><i class="fa fa-arrow-left"></i>
                            </a> Step
                            {{ step }} of {{ endStep }}</p>
                        <h1 class="mt-3">
                            <span class="text-4xl font-semibold">{{ getTitle }}</span>
                        </h1>
                    </div>
                    <KeepAlive>
                        <TransitionGroup mode="out-in">

                            <!--  Step 1 | Listing | Vouchers | Memberships -->
                            <div v-show="step===1" :key="`div-step-1-${step}`" id="accordion" class="w-100">
                                <div class="grid gap-4 grid-cols-1">
                                    <template v-if="isVoucher">
                                        <voucher-card
                                            v-for="voucher in data"
                                            :key="`voucher-${voucher.id}`"
                                            :voucher-id="voucher.id"
                                            :theme="voucher.theme"
                                            size="sm"
                                            :click-able="true"
                                            :title="voucher.title"
                                            :value="+voucher.value"
                                            :retail-price="+voucher.retail_price"
                                            :select-all="!!voucher.all_services"
                                            :valid-for="voucher.valid_for"
                                            :services-count="+voucher.services.length"
                                            classes="px-0"
                                            @click="selectCard"
                                        />
                                    </template>
                                    <template v-else-if="isMembership">
                                        <membership-card
                                            v-for="membership in data"
                                            :key="`membership-${membership.id}`"
                                            :membership-id="membership.id"
                                            :theme="membership.theme"
                                            size="sm"
                                            :click-able="true"
                                            :title="membership.name"
                                            :price="+membership.price"
                                            :valid-for="membership.valid_for"
                                            :select-all="!!membership.all_services"
                                            :services-count="+membership.services.length"
                                            :sessions.sync="membership.sessions"
                                            :no-of-session.sync="+membership.no_of_session"
                                            classes="px-0"
                                            @click="selectCard"
                                        />
                                    </template>

                                    <div>
                                        <h2 class="text-4xl font-semibold">More offers for you</h2>
                                    </div>
                                    <!--                  Book an appointment-->
                                    <a :href="`/booking/${branch.slug}`"
                                       class="group block w-full rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-green-800 hover:ring-green-600">
                                        <div class="flex items-center space-x-3">
                                            <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                                <path
                                                    d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"/>
                                            </svg>
                                            <h3 class="text-slate-900  text-2xl font-semibold mt-3">Book an
                                                appointment</h3>
                                        </div>
                                        <p class="text-slate-500 text-sm">Book unforgettable beauty and wellness experiences.</p>
                                    </a>
                                    <!--                  Buy a voucher || Membership-->
                                    <a :href="`/${isVoucher?'membership':'voucher'}/booking/${branch.slug}`"
                                       class="group block w-full rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-green-800 hover:ring-green-600">
                                        <div class="flex items-center space-x-3">
                                            <svg v-if="isMembership" width="50" height="50" viewBox="0 0 48 48" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect width="48" height="48" fill="black" fill-opacity="0.01"/>
                                                <path
                                                    d="M35.0564 15.0002L18.4628 7.66508C17.5888 7.27875 16.5636 7.56461 16.0157 8.34745L11.359 15.0002"
                                                    stroke="#000" stroke-width="1" stroke-linecap="round"/>
                                                <path
                                                    d="M43 15H5C4.44772 15 4 15.4477 4 16V40C4 40.5523 4.44772 41 5 41H43C43.5523 41 44 40.5523 44 40V16C44 15.4477 43.5523 15 43 15Z"
                                                    fill="none" stroke="#000" stroke-width="1"/>
                                                <path d="M19 23L24.1026 33L29 23" stroke="#000" stroke-width="1" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                            </svg>
                                            <svg v-else-if="isVoucher" width="50" height="50" viewBox="0 0 24 24">
                                                <path fill=""
                                                      d="M20 10H4V4h16m0 11H4v-2h16m0-11H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2l4 2v-5h4c1.11 0 2-.89 2-2V4c0-1.11-.89-2-2-2Z"/>
                                            </svg>
                                            <h3 :class="`text-slate-900  text-2xl font-semibold ${isMembership?'mt-2':''}`">
                                                Buy a {{ isVoucher ? 'Membership' : 'Voucher' }}</h3>
                                        </div>
                                        <p class="text-slate-500 text-sm">Choose to apply the service charge on the Full sale value, or Only
                                            selected items such as Services, Products and/or Memberships.</p>
                                    </a>

                                </div>
                            </div>

                            <!-- Step 2 | Preview Voucher,Preview Membership -->
                            <div v-show="step===2" :key="`div-step-2-${step}`" class="col-12 px-0" id="staff">

                                <!-- Button | increment and decrement input-->
                                <div v-if="isVoucher" class="custom-number-input h-32 w-full text-center mt-6">
                                    <label for="custom-input-number" class="w-full text-2xl">Quantity</label>
                                    <div class="flex flex-row gap-2 w-full rounded-lg relative bg-transparent mt-1 justify-center">
                                        <button @click="form.qty--"
                                                :class="`h-18 w-20 rounded-xl cursor-pointer outline-none border shadow-lg bg-slate-100 hover:bg-black hover:text-white ${form.qty === 1?'disabled':''}`">
                                            <span class="m-auto text-3xl">−</span>
                                        </button>
                                        <input type="number" v-model="form.qty"
                                               class="py-3 w-52 shadow-lg bg-white rounded-xl text-center font-semibold text-3xl hover:text-black focus:text-black  md:text-basecursor-default flex items-center  outline-none"
                                               name="custom-input-number" value="1"/>
                                        <button @click="form.qty++"
                                                class="h-18 w-20 rounded-xl cursor-pointer border shadow-lg bg-slate-100 hover:bg-black hover:text-white">
                                            <span class="m-auto text-3xl">+</span>
                                        </button>
                                    </div>
                                </div>

                                <template v-if="isVoucher">
                                    <voucher-card
                                        :name.sync="branch.name"
                                        :address.sync="branch.address"
                                        :theme="+card.theme"
                                        size="lg"
                                        :value.sync="+card.value"
                                        :valid-for.sync="card.valid_for"
                                        :booking-button="false"
                                        :select-all.sync="card.all_selected"
                                        :services-count.sync="+card.services.length"
                                        :desc.sync="card.desc"
                                        :enable-client-note.sync="!!card.enable_client_note"
                                        :client-note.sync="card.client_note"
                                    />
                                </template>

                                <template v-else-if="isMembership">
                                    <membership-card
                                        :name.sync="branch.name"
                                        :address.sync="branch.address"
                                        :theme="+card.theme"
                                        size="lg"
                                        :price.sync="+card.price"
                                        :valid-for.sync="card.valid_for"
                                        :sessions.sync="card.sessions"
                                        :no-of-session.sync="+card.no_of_session"
                                        :desc.sync="card.desc"
                                        :select-all.sync="card.all_selected"
                                        :services-count.sync="+card.services.length"
                                        :terms.sync="card.terms"
                                    />
                                </template>

                            </div>

                            <!--            Step 3-->
                            <div v-show="step===3 && isVoucher" :key="`div-voucher-step-3-${step}`">
                                <div class="flex flex-column gap-4 mt-3">
                                    <a href="javascript:void(0);"
                                       :class="`group block w-full rounded-lg p-6 bg-white shadow-lg hover:bg-green-800 hover:ring-1 hover:ring-black hover:bg-indigo-700 ${form.recipient_type === 0?'ring-2 ring-black':''}`"
                                       @click="switchRecipientType(0)">
                                        <div class="flex items-center space-x-3">
                                            <svg width="32" height="32" viewBox="0 0 512 512">
                                                <path fill=""
                                                      d="M469 128h-76q12-20 12-43q0-35-25-60T320 0q-40 0-64 30q-24-30-64-30q-35 0-60 25t-25 60q0 23 12 43H43q-18 0-30.5 12.5T0 171v42q0 18 12.5 30.5T43 256v192q0 27 18 45.5t46 18.5h298q28 0 46-18.5t18-45.5V256q18 0 30.5-12.5T512 213v-42q0-18-12.5-30.5T469 128zM192 469h-85q-22 0-22-21V256h107v213zm0-256H43v-42h149v42zm0-85q-17 0-30-12.5T149 85q0-17 13-29.5T192 43t30 12.5T235 85q0 18-13 30.5T192 128zm85 341h-42V256h42v213zm0-256h-42v-42h42v42zm0-128q0-17 13-29.5T320 43t30 12.5T363 85q0 18-13 30.5T320 128t-30-12.5T277 85zm150 363q0 21-22 21h-85V256h107v192zm42-235H320v-42h149v42z"/>
                                            </svg>
                                            <p class="text-xl w-full text-slate-900 ml-3">
                                                A treat for me
                                            </p>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);"
                                       :class="`group block w-full rounded-lg p-6 bg-white shadow-lg hover:bg-green-800 hover:ring-1 hover:ring-black ${form.recipient_type === 1?'ring-2 ring-black':''}`"
                                       @click="switchRecipientType(1)">
                                        <div class="flex items-center space-x-3">
                                            <svg width="40" height="40" viewBox="0 0 256 256">
                                                <path fill=""
                                                      d="M210.7 72H200V40a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v32H45.3C31.4 72 20 82.8 20 96v80a8 8 0 0 0 8 8h28v36a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-36h28a8 8 0 0 0 8-8V96c0-13.2-11.4-24-25.3-24ZM72 48h112v24H72Zm112 164H72v-52h112Zm36-44h-20v-16a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v16H36V96c0-4.4 4.2-8 9.3-8h165.4c5.1 0 9.3 3.6 9.3 8Zm-20-52a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z"/>
                                            </svg>
                                            <p class="text-xl w-full text-slate-900">
                                                Print as gift
                                            </p>
                                        </div>
                                    </a>
                                    <a href="javascript:void(0);"
                                       :class="`group block w-full rounded-lg p-6 bg-white shadow-lg hover:bg-green-800 hover:ring-1 hover:ring-black ${form.recipient_type === 2?'ring-2 ring-black':''}`"
                                       @click="switchRecipientType(2)">
                                        <div class="flex items-center space-x-3">
                                            <svg class="" width="43" height="43" viewBox="0 0 24 24">
                                                <path fill=""
                                                      d="M22 5.5H9c-1.1 0-2 .9-2 2v9a2 2 0 0 0 2 2h13c1.11 0 2-.89 2-2v-9a2 2 0 0 0-2-2m0 11H9V9.17l6.5 3.33L22 9.17v7.33m-6.5-5.69L9 7.5h13l-6.5 3.31M5 16.5c0 .17.03.33.05.5H1c-.552 0-1-.45-1-1s.448-1 1-1h4v1.5M3 7h2.05c-.02.17-.05.33-.05.5V9H3c-.55 0-1-.45-1-1s.45-1 1-1m-2 5c0-.55.45-1 1-1h3v2H2c-.55 0-1-.45-1-1Z"/>
                                            </svg>
                                            <p class="text-xl w-full text-slate-900 ">
                                                Email as a gift
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <!--            Step | Recipient form-->
                            <div v-show="step===4 && isVoucher && form.recipient_type > 0" :key="`div-voucher-step-4-${step}`">
                                <div class="confirm-section row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>First Name</label>
                                            <input type="text" class="form-control" placeholder="Enter first name..."
                                                   v-model="form.recipient_form.first_name">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div class="form-group">
                                            <label>Last Name
                                                <small class="text-muted">(Optional)</small></label>
                                            <input type="text" class="form-control" placeholder="Enter last name..."
                                                   v-model="form.recipient_form.last_name">
                                        </div>
                                    </div>

                                    <div v-if="form.recipient_type === 2" class="col-md-12">
                                        <div class="form-group">
                                            <label>Email
                                                <small class="text-muted">(Optional)</small></label>
                                            <input type="email" class="form-control" placeholder="Enter your email..."
                                                   v-model="form.recipient_form.email">
                                            <small class="text-muted text-sm">The voucher will be sent to this email address.</small>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Personalised message
                                                <small class="text-muted">(Optional)</small></label>
                                            <textarea class="form-control" placeholder="Make personalised message..."
                                                      v-model="form.recipient_form.message"></textarea>
                                            <a v-if="isVoucher" href="javascript:void(0);" data-toggle="modal" data-target="#view-gift-modal"
                                               class="relative top-2 text-lg text-blue-600">
                                                See {{ form.recipient_type === 1 ? 'print' : (form.recipient_type === 2 ? 'email' : '') }} preview</a>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!--        Last Step | Booking Preview  -->
                            <div v-show="step===endStep" :key="`div-step-4-${step}`">

                                <!--              Booking-->
                                <div v-if="authCheck" class="confirm-section">

                                    <div v-if="card.desc" class="col-md-12 mt-2 px-0">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Description</h4>
                                            <p class="text-muted">{{ card.desc }}</p>
                                        </div>
                                    </div>

                                    <div v-if="isVoucher && card.enable_client_note" class="col-md-12 mt-2 px-0">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Client Note</h4>
                                            <p class="text-muted">{{ card.client_note }}</p>
                                        </div>
                                    </div>

                                    <div v-if="isMembership && card.terms" class="col-md-12 mt-2 px-0">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Terms & Condition</h4>
                                            <p class="text-muted">{{ card.terms }}</p>
                                        </div>
                                    </div>

                                    <div class="col-md-12 px-0">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Add booking notes</h4>
                                            <p class="text-muted">Include comments or requests about your booking</p>
                                            <textarea class="form-control form-control-lg" placeholder="Type your booking note..."
                                                      v-model="form.note"></textarea>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mt-4">
                                            <MapWithPin :lat="branch.lat" :lng="branch.lng" height="300px"/>
                                        </div>
                                    </div>

                                </div>

                                <!--   Login section-->
                                <div v-else class="confirm-section">
                                    <div class="col-md-6 offset-3 mb-4 text-center">
                                        <h3 class="font-semibold text-2xl">Login</h3>
                                    </div>
                                    <div class="col-md-6 offset-3">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="text" class="form-control" placeholder="Enter your email..."
                                                   v-model="loginForm.email">
                                        </div>
                                    </div>
                                    <div class="col-md-6 offset-3">
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control" placeholder="Enter your password..."
                                                   v-model="loginForm.password">
                                        </div>
                                    </div>
                                    <div class="col-md-6 offset-3 mt-4">
                                        <a href="/register" class="btn btn-link" target="_blank">I've no account?</a>
                                        <a href="javascript:void(0);" class="btn btn-primary pull-right" @click="login">Login</a>
                                    </div>

                                </div>
                            </div>

                        </TransitionGroup>
                    </KeepAlive>
                </div>

                <!--        cart-->
                <div class="col-md-4 col-sm-12 md:mt-24">
                    <div class="card card-cart shadow-2xl sticky top-20">
                        <div class="card-body pb-1">
                            <img class="img-thumbnail " :src="branch.images.length?branch.images[0].path:'/images/default_barber.png'"
                                 alt="Card image cap">
                            <h5 class="card-title text-center mt-3 mb-0 font-semibold">{{ branch.name }}</h5>
                            <small class="text-muted leading-normal">{{ branch.address }}</small>
                        </div>
                        <ul v-if="card.id" class="list-group list-group-flush">
                            <li :key="`-`" class="list-group-item py-4 px-3">
                        <span class="text-2xl">
                           <span v-if="isVoucher" class="text-lg text-white bg-black py-1 px-3 rounded-full">{{
                                   form.qty
                               }}</span>
                          {{ card.name }}
                        </span>
                                <p class="mt-3 text-lg">
                                    <a href="javascript:void(0);" class="text-blue-600" data-toggle="modal"
                                       data-target="#view-services-modal">{{
                                            card.all_services ? 'All' : card.services.length
                                        }} Services</a>
                                    <span class="currency pull-right">{{ moneyFormat(isVoucher ? card.retail_price : card.price) }}</span>
                                </p>
                            </li>
                            <li class="list-group-item total">
                                Total<span class="float-right">{{ getTotal }}</span>
                            </li>
                        </ul>
                        <small v-else class="mb-4 mt-4 text-muted text-center">No {{ type }} selected yet</small>

                        <div class="card-body">
                            <CreditCardView
                                :auth-user="authUser"
                                :show-button="step === 4"
                                @click="next(true)"
                            />
                            <a v-if="step>1" href="javascript:void(0);" class="btn btn-primary w-100"
                               @click="next(false)">{{ step === endStep ? 'Confirm' : 'Next' }}</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <ViewServicesModal v-if="card" :services="card.services.map(s=>s.service)"/>

        <ViewGiftModal
            :auth-user="authUser"
            :gift-type="form.recipient_type === 1? 'print':(form.recipient_type ===2?'email':'')"
            :branch="branch"
            :card="card"
            :for-name="form.recipient_form.first_name"
        />

    </section>
</template>

<script>
import moment from 'moment-timezone';
import TimeDateMixin from '@mixins/timeDateMixin';
import HelperMixin from '@mixins/helperMixin';
import ViewServicesModal from "@components/includes/ViewServicesModal";
import ViewGiftModal from "@components/includes/ViewGiftModal";
import MapWithPin from '@components/includes/MapWithPin';
import CreditCardView from "@components/includes/CreditCardView";

export default {
    name: "BuyVoucherOrMembership",
    components: {ViewServicesModal, MapWithPin, ViewGiftModal, CreditCardView},
    mixins: [TimeDateMixin, HelperMixin],
    props: {
        userAuthCheck: {
            type: [Boolean, Number],
            default: false
        },
        authUser: {
            type: [Object, Array],
            default: () => {
            }
        },
        branch: {
            type: Object,
            default: () => {
            }
        },
        data: {
            required: true,
            type: Array,
        },
        type: {
            required: true,
            type: String,
        },
        selectedId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            authCheck: !!+this.userAuthCheck,
            isLoading: false,
            step: 2,
            endStep: 3,
            form: {
                type: this.type,
                id: this.selectedId,
                qty: 1,
                note: '',
                new_card: false,
                recipient_type: 0,
                recipient_form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    message: '',
                },
            },
            loginForm: {
                email: '',
                password: ''
            }
        }
    },
    mounted() {
        this.endStep = this.isMembership ? 3 : 4;
    },
    methods: {
        next(newCard) {
            this.form.new_card = newCard;
            // Validation
            if (this.isVoucher && this.step === 4 && (this.form.recipient_type === 1 || this.form.recipient_type === 2) && !this.form.recipient_form.first_name) {
                return this.$toast.error('First name is required.');
            }

            if (this.step < this.endStep) {
                this.step++;
            } else if (this.step === this.endStep) {
                this.bookRequest();
            }
        },
        bookRequest() {
            this.isLoading = true;
            axios.post(`/buy/card`, this.form)
                .then(res => {
                    this.$toast.success(`Successfully, Order has been created.`);
                    setTimeout(() => {
                        window.location.href = res.data.data.redirect_to;
                    }, 2000);
                })
                .catch(err => {
                    this.isLoading = false;
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                });
        },
        back() {
            if (this.step > 1) this.step--;
        },
        login() {
            this.isLoading = true;
            axios.post('/login/ajax', this.loginForm)
                .then(res => {
                    this.authCheck = res.data.data.authCheck ?? false;
                    this.loginForm.email = '';
                    this.loginForm.password = '';
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                }).finally(() => this.isLoading = false);
        },
        selectCard(id) {
            this.form.type = this.type;
            this.form.id = id;
            this.next(false);
        },
        switchRecipientType(typeNo = 0) {
            this.form.recipient_type = typeNo;
            this.endStep = typeNo > 0 ? 5 : 4;
            this.next(false);
        }
    },
    computed: {
        getTitle() {
            let res;
            let recipientTitle = this.isVoucher && this.form.recipient_type === 1 ? 'Print as a gift' : (this.form.recipient_type === 2 ? 'Email as a gift' : 'Review and confirm');
            switch (this.step) {
                case 1:
                    res = `Select a ${this.type}`;
                    break;
                case 2:
                    res = `Additional info${this.isVoucher ? ' and quantity' : ''}`;
                    break;
                case 3:
                    res = `${this.isVoucher ? 'Choose a recipient' : 'Review and confirm'}`;
                    break;
                case 4:
                    res = `${recipientTitle ? recipientTitle : 'Review and confirm'}`;
                    break;
                case 5:
                    res = 'Review and confirm';
                    break;
                default:
                    res = '';
                    break;
            }
            return res;
        },
        getTotal() {
            return this.moneyFormat(+(this.isMembership ? this.card.price : this.form.qty * this.card.retail_price));
        },
        isMembership() {
            return this.type === 'membership';
        },
        isVoucher() {
            return this.type === 'voucher';
        },
        card() {
            return +this.form.id ? this.data.find(c => +c.id === +this.form.id) : {};
        }
    }
}
</script>

<style scoped>
#book-section {
    padding-top: 2rem;
    /*background: url("/images/wave1.png");*/
    /*background-repeat: no-repeat;*/
}

.btn-time {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: var(--primary-color);
    opacity: 1;
    box-shadow: 0px 0px 12px -3px grey;
}

.btn-time.active {
    color: white !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color);
    opacity: 1;
    /*box-shadow: 0px 0px 12px -3px grey;*/
}

h1 {
    color: black;
}

#accordion .btn.btn-link {
    color: black;
}

#accordion .card {
    border-radius: 0 0 0 20px;
    border-bottom: 4px solid rgb(54 104 101);
}

#accordion .card-header {
    padding: 2px 0;
    background: white;
    border-radius: 0 0 0 20px;
}

.switch {
    width: 50px;
    height: 25px;
}

.switch .slider:before {
    width: 17px;
    height: 17px;
}

.card-cart {
    border-bottom: 4px solid #366865;
    border-radius: 0 0 0 20px;
    margin-top: 4rem;
}

.card-cart .name {
    font-size: 12px;
}

.card-cart .currency {
    font-size: 14px;
}

.card-cart img {
    max-width: 75%;
    margin-top: -68px;
    margin-left: 40px;
}

.back {
    color: white;
    margin-right: 8px;
}

.step-title {
    /*color: #f5f5f594;*/
    font-size: 15px;
}

.currency {
    color: #366865;
    font-weight: bolder;
    font-size: 18px;
}

.fa.fa-arrow-left {
    color: black;
    font-size: 1em;
    padding: 11px 12px;
    border: 1px solid;
    border-radius: 50%;
}

.fa.fa-arrow-left:hover {
    color: white;
    background: black;
}

i.fa.fa-remove {
    color: red;
    font-size: 17px;
}

.total {
    color: black;
    /*padding: 10px 5px;*/
    font-weight: bolder;
    font-size: larger;
}

.total p {
    color: white;
    font-weight: bolder;
    font-size: 20px;
}

#staff img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
}

#staff li.list-group-item {
    /*background: radial-gradient(black, transparent);*/
    background: transparent;
    cursor: pointer;
    border-radius: 2px solid rgb(0 0 0 / 9%) !important;
}

#staff li.list-group-item.active p {
    color: white !important;
}

#staff li.list-group-item.active {
    background: #315855;
    border: 1px dashed #24d133;
    border-radius: 10px;
}

#staff li.list-group-item.active img {
    border: 2px solid #17ff17b8;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

div.confirm-section {
    padding: 15px 0;
    border-radius: 10px;
}

.count-service {
    font-size: 3rem;
    color: #366865;
}

textarea {
    height: 10rem;
}

/* The container */
.cb-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cb-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -7px;
    left: 0;
    height: 32px;
    width: 32px;
    /*background-color: #b5cdccbd;*/
    border-radius: 20px;
    border: 1px dashed;
}

/* On mouse-over, add a grey background color */
.cb-container:hover input ~ .checkmark {
    background-color: black;
}

/* When the checkbox is checked, add a blue background */
.cb-container input:checked ~ .checkmark {
    background-color: black;
    border-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cb-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.cb-container .checkmark:after {
    left: 12px;
    top: 5px;
    width: 8px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#detail-section {
    color: black;
    /*height: 100vh;*/
    /*overflow: scroll;*/
}
</style>
