<template>
    <section>
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />

        <BookingStatics
            title="All Bookings Statics"
            role="superadmin"
            :pending="+bookingStats.pending"
            :awaiting-time="+bookingStats.awaiting_time"
            :completed="+bookingStats.completed"
            :cancelled="+bookingStats.cancelled"
            :expired="+bookingStats.expired"
        />
        <!-- Commission & Sales | Chart-->
        <div class="row bg-white p-6">
            <div class="col-md-12 col-sm-12 text-right mb-3">
                <div class="text-2xl display-1 font-semibold pull-left">Commission & Sales</div>
                <div>
                    <date-range-picker
                            v-if="statsDates.startDate && statsDates.endDate && maxDate"
                            ref="dtRecent"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                            single-date-picker="range"
                            :ranges="false"
                            :show-dropdowns="true"
                            :maxDate="maxDate"
                            v-model="statsDates"
                            :autoApply="true"
                            @update="get"
                    >
                    <!--        <template v-slot:input="picker" style="min-width: 350px;">-->
                    <!--            {{ picker.startDate }} - {{ picker.endDate }}-->
                    <!--        </template>-->
                    </date-range-picker>
                </div>
<!--                <button :class="`btn btn-sm mx-0 ${duration === 'currentWeek'?'btn-dark':'btn-light'}`" @click="get('currentWeek')">Weekly</button>-->
<!--                <button :class="`btn btn-sm mx-0 ${duration === 'currentMonth'?'btn-dark':'btn-light'}`" @click="get('currentMonth')">Monthly</button>-->
<!--                <button :class="`btn btn-sm mx-0 ${duration === 'currentYear'?'btn-dark':'btn-light'}`" @click="get('currentYear')">1 Year</button>-->
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white rounded-lg shadow px-0">
                    <ul class="flex flex-col w-full h-[380px] overflow-y-scroll">
                        <li v-for="(order,i) in orders"
                            :key="`o-${i}`"
                            class="flex flex-row hover:(shadow-lg bg-green-500)">
                            <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
                                <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
                                    <a href="#" class="block relative">
                                        <img alt="profil" :src="order?.company?.logo_url" class="mx-auto object-cover rounded-full h-10 w-10"/>
                                    </a>
                                </div>
                                <div class="flex-1 pl-1">
                                    <div class="font-small">{{ order?.company?.company_name }}</div>
                                    <div class="text-gray-600 text-xs">{{ momentFormat(order.created_at, 'LL') }}</div>
                                </div>
                                <div class="flex flex-row justify-center">
                                    <div class="text-gray-500 text-md mr-4">
                                        <b>Sale:</b> {{ moneyFormat(order.amount) }}
                                    </div>
                                    <div class="text-green-500 text-md">
                                        <b>{{ order.commission.percent }}% Commission:</b> {{ moneyFormat(order.commission.amount) }}
                                    </div>

                                    <button class="w-10 text-right flex justify-end">
                                        <svg width="20" fill="currentColor" height="20" class="text-gray-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <p class="text-center mt-4">
                    <span class="badge bg-gray-500 text-white text-xl font-normal">Total Sales: {{ getTotalSale }}</span>
                    <span class="badge badge-success text-xl font-normal">Total Commission: {{ getTotalCommission }}</span>
                </p>
            </div>
            <div class="col-md-6 col-sm-12 chart">
                <apexchart type="area" height="420" :options.sync="commissionChart.chartOptions" :series.sync="commissionChart.series"></apexchart>
            </div>
        </div>

    </section>
</template>

<script>
import HelperMixin from '@mixins/helperMixin';
import TimeDateMixin from '@mixins/timeDateMixin';
import BookingStatics from "@components/includes/BookingStatics";
import DateRangePicker from "vue2-daterange-picker"
// You need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: "Dashboard",
    components: {BookingStatics, DateRangePicker},
    mixins: [HelperMixin, TimeDateMixin],
    data() {
        return {
            duration: '',
            isLoading: false,
            maxDate: null,
            statsDates:{
                startDate: '',
                endDate: '',
            },
            bookingStats: {
                pending: 0,
                awaiting_time: 0,
                completed: 0,
                expired: 0,
                cancelled: 0
            },
            orders: [],
            commissionChart: {
                series: [
                    {
                        id: 'sale',
                        name: 'Sale',
                        data: []
                    },
                    {
                        id: 'commission',
                        name: 'Commission',
                        data: []
                    },
                ],
                chartOptions: {
                    chart: {
                        type: 'area',
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm',
                            // show: true,
                            // formatter: function (value, timestamp) {
                            //     return this.momentFormat(new Date(value))
                            // },
                        },
                        y: {
                            formatter: function (val) {
                                return val + ' AED';
                            }
                        }
                    },
                    colors: ['#6b7280', '#28a745'],
                    noData: {
                        text: "Loading...",
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                    }
                },

            },
        }
    },
    mounted() {
        this.maxDate = new Date(moment().subtract(1,'d').format('LL'));
        this.statsDates.startDate = new Date(moment().subtract(30,'d').format('LL'));
        this.statsDates.endDate = new Date(moment().subtract(1,'d').format('LL'));
        this.get();
    },
    methods: {
        get() {
            let days = Math.abs(moment(this.statsDates.startDate).diff(moment(this.statsDates.endDate),'days'));
            this.isLoading = true;
            axios.get(`/super-admin/dashboard/stats/${days}`)
                .then(res => {
                    this.bookingStats = res.data.data.stats;
                    this.orders = res.data.data.orders;
                    this.updateCommissionChart();
                })
                .finally(() => this.isLoading = false)
        },
        updateCommissionChart() {
            let self = this;
            let categories = [];
            this.commissionChart.series.map(s => {
                s.data = [];
            });
            this.orders.forEach(function (order) {
                categories.push(order.created_at);
                self.commissionChart.series.map(s => {
                    if (s.id === 'sale') {
                        s.data.push(order.amount)
                    }
                    if (s.id === 'commission') {
                        s.data.push(order.commission.amount)
                    }
                });
            });

            this.commissionChart.chartOptions = {
                ...this.commissionChart.chartOptions,
                ...{
                    xaxis: {
                        type: 'datetime',
                        categories: categories
                    },
                }
            }

        }
    },
    computed: {
        getTotalSale() {
            let sum = 0;
            for (let i = 0; i < this.orders.length; i++) {
                sum += +this.orders[i].amount;
            }
            return moneyFormat(sum);
        },
        getTotalCommission() {
            let sum = 0;
            for (let i = 0; i < this.orders.length; i++) {
                sum += +this.orders[i].commission.amount;
            }
           return moneyFormat(sum);
        },
    }
}
</script>

<style scoped>

</style>
