<template>
  <div id="my_map_with_pin" :class="classes" :style="`height: ${height};`"></div>
</template>

<script>
export default {
  name: "MapWithPin",
  props: {
    lat: {
      type: String,
      default: ''
    },
    lng: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '400px'
    },
    classes: {
      type: String,
      default: 'drop-shadow-2xl rounded-lg'
    }
  },
  mounted(){
    let self = this;
    setTimeout(() => {
      let latlng = new window.google.maps.LatLng(self.lat, self.lng);

      let map = new window.google.maps.Map(
          document.getElementById("my_map_with_pin"),
          {
            center: latlng,
            zoom: 16,
            mapTypeControl: false,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          });

      let marker = new window.google.maps.Marker({
        position: latlng,
        map: map,
        animation: window.google.maps.Animation.BOUNCE,
        icon: '/images/pin.webp',
      });
    }, 1000);
  }
}
</script>

<style scoped>

</style>
