<template>
    <section id="book-section">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />
        <div class="container">
            <div class="row">

                <div class="col-md-8 col-sm-12" id="detail-section">
                    <div class="col-md-12 pb-2 px-0">
                        <p class="step-title">
                            <a href="javascript:void(0);" @click="back" class="back"><i class="fa fa-arrow-left"></i>
                            </a> Step
                            {{ step }} of 4</p>
                        <h1 class="mt-2">
                            <span class="text-4xl font-semibold">{{ getTitle }}</span>
                        </h1>
                    </div>
                    <KeepAlive>
                        <TransitionGroup mode="out-in">

                            <!-- Step 1 | Services -->
                            <div v-show="step===1" :key="`div-step-1-${step}`" id="accordion" class="w-100">
                                <template v-if="cardType === 'voucher'">
                                    <voucher-card
                                        :name="branch.name"
                                        :address="branch.address"
                                        :purchased="true"
                                        :order="card.order"
                                        :voucher-id="card.id"
                                        :theme="card.theme"
                                        size="lg"
                                        :click-able="false"
                                        :booking-button="false"
                                        :title="card.title"
                                        :value="+card.value"
                                        :retail-price="+card.retail_price"
                                        :valid-for="card.valid_for"
                                        :select-all="!!card.all_services"
                                        :services-count="+card.services.length"
                                        classes="pl-0"
                                    />
                                </template>
                                <template v-else-if="cardType === 'membership'">
                                    <membership-card
                                        :name="branch.name"
                                        :address="branch.address"
                                        :purchased="true"
                                        :order="card.order"
                                        :membership-id="card.id"
                                        :theme="card.theme"
                                        size="lg"
                                        :click-able="false"
                                        :booking-button="false"
                                        :title="card.name"
                                        :price="+card.price"
                                        :valid-for="card.valid_for"
                                        :select-all="!!card.all_services"
                                        :services-count="+card.services.length"
                                        :sessions.sync="card.sessions"
                                        :no-of-session.sync="+card.no_of_session"
                                        classes="pl-0"
                                    />
                                </template>
                                <template v-else>
                                    <div v-for="(data,i) in services" :key="`tab-${i}`" class="card mb-2 shadow-xl">
                                        <div class="card-header" :id="`heading-${i}`">
                                            <h5 class="mb-0">
                                                <button :class="`btn btn-link ${i===0?'collapsed':''}`" data-toggle="collapse"
                                                        :data-target="`#collapse-${i}`"
                                                        :aria-expanded="i===0"
                                                        :aria-controls="`collapse-${i}`">
                                                    {{ data[0].service.service_category.name }}
                                                </button>
                                            </h5>
                                        </div>

                                        <div :id="`collapse-${i}`" :class="`collapse ${i===0?'show':''}`" :aria-labelledby="`heading-${i}`" data-parent="#accordion">
                                            <div class="card-body py-2">
                                                <ul class="list-group list-group-flush">
                                                    <li v-for="(serviceData,j) in data" :key="`tab-content-services-${j}`"
                                                        :class="`list-group-item px-0 py-2 ${!form.services.some(s=>s.id === serviceData.service.id) && form.services.length === 6 ? 'disabled':''}`">
                                                        <div class="switch-div">
                                                            <label class="cb-container">
                                                                <input type="checkbox" v-model="form.services" name="services[]"
                                                                       :value="serviceData.service">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <!--                          <label class="switch mr-2">-->
                                                            <!--                            <input type="checkbox" v-model="form.services" name="services[]"-->
                                                            <!--                                   :value="serviceData.service">-->
                                                            <!--                            <span class="slider round"></span>-->
                                                            <!--                          </label>-->
                                                            <label style="position:relative; left: 40px; top: -2px;">
                                                                <b>{{ serviceData.service.name }}</b>
                                                            </label>
                                                        </div>
                                                        <p class="text-muted mt-2">{{ serviceData.service.description }}</p>
                                                        <p class="float-right text-muted">{{ timeFormat(serviceData.service.price_duration) }},
                                                            <span class="currency">
                                                                <span>{{ moneyFormat(serviceData.service.price) }}</span>
<!--                                                            <small>{{ getPriceType(serviceData.service.price_type) }}</small>-->
                                                            </span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <!-- Step 2 | Staff -->
                            <div v-show="step===2" :key="`div-step-2-${step}`" class="col-12 px-0" id="staff">
                                <ul class="list-group list-group-light">

                                    <li v-if="!selectedEmployeeId"
                                        :class="`list-group-item d-flex justify-content-between align-items-center mb-1 ${form.employee_id === 'no_preference'?'active':''}`"
                                        @click="selectStaff('no_preference')">
                                        <div class="d-flex align-items-center">
                                            <i class="fas fa-users" style="font-size: 50px;margin: 10px 20px 10px 5px;"></i>
                                            <div class="ms-3">
                                                <p class="fw-bold mb-1 ">
                                                    <b>No Preference</b></p>
                                                <p class=" mb-0 ">Maximum availability</p>
                                            </div>
                                        </div>
                                    </li>

                                    <li v-for="employee in getRelatedTeam" :key="employee.id"
                                        :class="`list-group-item d-flex justify-content-between align-items-center mb-1 ${form.employee_id === employee.id?'active':''}`"
                                        @click="selectStaff(employee.id)">
                                        <div class="d-flex align-items-center">
                                            <img :src="employee.user_image_url" alt="" class="rounded-circle"/>
                                            <div class="ms-3">
                                                <p class="fw-bold mb-1 ">
                                                    <b>{{ employee.name }}</b>
                                                </p>
                                                <p class="mb-0 text-slate-500">{{ employee.title }}</p>
                                            </div>
                                        </div>
                                        <p>
                                            <span class="fa fa-star checked"></span>
                                            <b>5.0 Great</b>
                                            <span class="text-slate-500">(1200 ratings)</span>
                                        </p>
                                    </li>

                                </ul>
                            </div>

                            <!-- Step 3-->
                            <div v-show="step===3" :key="`div-step-3-${step}`" id="date-section">

                                <h5 class="mb-3 font-semibold">{{ getSelectedDate }} {{ form.time ? 'at ' + form.time : '' }}</h5>
                                <vue-horizontal-calendar
                                    class="mb-2"
                                    choosedItemColor="#366865"
                                    :choosedDatePos="'left'"
                                    lang="en"
                                    :showBorderTop="false"
                                    :resizeable="false"
                                    @change="selectedDate"
                                >
                                    <!--                <img slot="leftIcon" src="./assets/left.jpg" style="width:20px;margin-top:11px" />-->
                                    <!--                <img slot="rightIcon" src="./assets/right.jpg" style="width:20px;margin-top:11px" />-->
                                </vue-horizontal-calendar>
                                <!--                time slots-->
                                <div v-if="getTiming.items.length" class="flex flex-wrap mt-10">
                                    <a v-for="(time,i) in getTiming"
                                       :key="i"
                                       href="javascript:void(0);"
                                       :class="`btn btn-primary btn-time m-2 ${form.time === time ? 'active':''}`"
                                       @click="form.time = time"
                                    >{{ time }}</a>
                                </div>
                                <!--  Information alert-->
                                <div v-else class="col-md-12" style="margin-top: 4em;">
                                    <div class="alert-message alert-message-warning">
                                        <h4><i class="fa fa-info-circle mr-2"></i>Information</h4>
                                        <p>You can't book for this day, <strong>try again in upcoming days</strong><strong>future date.</strong></p>
                                    </div>
                                </div>

                            </div>

                            <!-- Step 4-->
                            <div v-show="step===4" :key="`div-step-4-${step}`">

                                <!-- Booking-->
                                <div v-if="authCheck" class="confirm-section pt-2">
                                    <div v-if="!isVoucher && !isMembership" class="col-md-12 mb-1 text-center">
                                      <span class="count-service badge px-0">{{ form.services.length }} <small style="font-size: 40%;">
                                          Service{{ form.services.length > 1 ? 's' : '' }}
                                        </small> AED {{ getTotal }}
                                      </span>
                                    </div>

                                    <div class="col-md-12 mt-4">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Additional terms and conditions</h4>
                                            <p class="text-muted">Deposits are non-refundable, you can reschedule your appointment and your
                                                deposit will be transferred over to your future booking. If you are unable to reschedule, please
                                                contact us at least 48 hours before your appointment.</p>
                                        </div>
                                    </div>
                                    <div v-if="!isVoucher && !isMembership" class="col-md-12">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Tip</h4>
                                            <p class="text-muted">Give tip to staff <i class="fa fa-info-circle" title="Enter the amount that you want to give to staff member as tip" data-toggle="tooltip"></i></p>
                                            <input type="number" class="form-control form-control-lg" placeholder="Enter the amount of tip" v-model="form.tip" />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <h4 class="md:text-2xl text-md font-semibold">Add booking notes</h4>
                                            <p class="text-muted">Include comments or requests about your booking</p>
                                            <textarea class="form-control form-control-lg" placeholder="Type your booking note..."
                                                      v-model="form.note"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                       <div class="form-group mb-0" style="display: inline-flex;">
                                            <div class="switch-div mr-2">
                                                <label class="switch">
                                                    <input name="allow_home_service" type="checkbox" v-model="form.allow_home_service">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            <label class="switch-name">Available for home service</label>
                                       </div>
                                    </div>
                                </div>

                                <!-- Login section-->
                                <div v-else class="confirm-section">
                                    <div class="col-md-6 offset-3 mb-4 text-center">
                                        <h3 class="font-semibold text-2xl">Login</h3>
                                    </div>
                                    <div class="col-md-6 offset-3">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="text" class="form-control" placeholder="Enter your email..."
                                                   v-model="loginForm.email">
                                        </div>
                                    </div>

                                    <div class="col-md-6 offset-3">
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control" placeholder="Enter your password..."
                                                   v-model="loginForm.password">
                                        </div>
                                    </div>

                                    <div class="col-md-6 offset-3 mt-4">
                                        <a href="/register" class="btn btn-link" target="_blank">I've no account?</a>
                                        <a href="javascript:void(0);" class="btn btn-primary pull-right" @click="login">Login</a>
                                    </div>

                                </div>
                            </div>

                        </TransitionGroup>
                    </KeepAlive>
                </div>

                <!-- Cart -->
                <div class="col-md-4 col-sm-12">
                    <div class="card card-cart shadow-2xl sticky top-20">
                        <div class="card-body pb-1">
                            <div class="col-sm-12 d-flex justify-content-center mt-4">
                                <img class="img-thumbnail" :src="branch.images.length?branch.images[0].path:'/images/default_barber.png'" alt="Card image cap">
                            </div>
                            <h5 class="card-title text-center mt-3 mb-0 font-semibold">{{ branch.name }}</h5>
                            <small class="text-muted leading-normal">{{ branch.address }}</small>
                        </div>
                        <!-- Voucher | Membership-->
                        <template v-if="cardType">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item py-4 px-3 text-2xl font-weight-bold text-capitalize">
                                    Limit:
                                    <span class="font-normal">
                                    {{ card.order?.total_card_use }}/
                                    {{ isVoucher ? card.order?.qty : (card.order?.sessions === 'limited' ? card.order.no_of_session : 'Unlimited') }}
                                    </span>
                                </li>
                                <li class="list-group-item py-4 px-3">
                                  <span class="text-2xl">
                                    {{ card.name }}
                                  </span>
                                    <p class="mt-3 text-lg">
                                        <a href="javascript:void(0);" class="text-blue-600" data-toggle="modal"
                                           data-target="#view-services-modal">
                                            {{ card.all_services ? 'All' : card.services.length }} Services
                                        </a>
                                        <span v-if="!isVoucher && !isMembership"
                                              class="currency pull-right">
                                            {{ moneyFormat(isVoucher ? card.retail_price : card.price) }}
                                        </span>
                                    </p>
                                </li>
                            </ul>
                        </template>
                        <!-- Services -->
                        <template v-else>
                            <ul v-if="form.services.length" class="list-group list-group-flush">
                                <li v-if="step===4 && getSelectedDate " class="list-group-item pl-2 pr-1">
                                    <b>{{ getSelectedDate }} {{ form.time ? 'at ' + form.time : '' }}</b>
                                    <p class="text-muted">{{ getTotalHours }} duration, ends at {{ getEndTime }}</p>
                                </li>
                                <TransitionGroup enter-active-class="animate__animated animate__zoomIn"
                                                 leave-active-class="animate__animated animate__zoomOut" mode="out-in">
                                    <li v-for="service in form.services" :key="`selected-service-${service.id}`"
                                        class="list-group-item p-1">
                                        <small class="name"> {{ service.name }}
                                            <a v-if="step===1" href="javascript:void(0);" @click="remove(service.id)"><i
                                                class="fa fa-remove"></i></a></small>
                                        <p class="text-muted name mt-[-2px]">
                                            {{ timeFormat(service.price_duration) }}
                                            <span class=" pull-right">
                                                <small v-if="offer.id && offerServicePrice(service.id,service.price) " :class="offer.id ? 'currency':''">
                                                    {{ offerServicePrice(service.id,service.price) }}
                                                </small>
                                                <span :class="offer.id && offerServicePrice(service.id,service.price) ? 'old-price':'currency'">AED {{ parseFloat(service.price).toFixed(2) }}</span>
<!--                                            <small>{{ getPriceType(service.price_type) }}</small>-->
                                            </span>
                                        </p>
                                    </li>
                                </TransitionGroup>
                                <li>
                                     <!-- Offer -->
                                    <div class="form-group py-2 mx-2">
                                        <label>Promo Code <small v-if="offer.id" class="text-success"><i class="fa fa-check mr-2"></i>Applied {{ offer?.name }}</small></label>
                                        <input type="number" id="offer-code" :class="`form-control ${offer.id?'active':''}`" minLength="0" maxLength="6"
                                               :readonly="selectedOffer" :disabled="selectedOffer" v-model="form.code">
                                    </div>
                                </li>
                                <li v-if="form.services.length && !isVoucher && !isMembership" class="list-group-item total">
                                    Total<span class="float-right">{{ getTotal }}</span>
                                </li>
                            </ul>
                            <small v-else class="mb-4 mt-4 text-muted text-center">No service selected yet</small>
                        </template>

                        <div class="card-body">
                            <!--  New Card-->
                            <CreditCardView
                                :auth-user="authUser"
                                :show-button="step === 4"
                                @click="book(true)"
                            />
                            <a href="javascript:void(0);" class="btn btn-primary w-100"
                               @click="book(false)">{{ step === 4 ? 'Confirm' : 'Next' }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ViewServicesModal v-if="card" :services="card.services.map(s=>s.service)"/>

    </section>
</template>

<script>
import moment from 'moment-timezone';
import VueHorizontalCalendar from 'vue-horizontal-calendar';
import TimeDateMixin from '@mixins/timeDateMixin';
import HelperMixin from '@mixins/helperMixin';
import ViewServicesModal from "@components/includes/ViewServicesModal";
import CreditCardView from "@components/includes/CreditCardView";
import collect from 'collect.js';

export default {
    name: "Book",
    components: {VueHorizontalCalendar, ViewServicesModal, CreditCardView},
    mixins: [TimeDateMixin, HelperMixin],
    props: {
        authUser: {
            type: [Object],
            default: () => {}
        },
        userAuthCheck: {
            type: [Boolean, Number],
            default: false
        },
        branch: {
            type: Object,
            default: () => {}
        },
        selectedOffer: {
            type: Object,
            default: () => {}
        },
        selectedServiceId: {
            type: Number,
            default: null
        },
        selectedEmployeeId: {
            type: Number,
            default: null
        },
        cardType: {
            type: String,
            default: () => ''
        },
        card: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            authCheck: !!+this.userAuthCheck,
            isLoading: false,
            offer: this.selectedOffer ?? {},
            disabledDates: [],
            services: [],
            step: 1,
            selectedEmployeeBookings: [],
            form: {
                services: [],
                employee_id: 'no_preference',
                branch_id: this.branch.id,
                date: '',
                time: '',
                note: '',
                end_time: '',
                total_duration: '',
                new_card: false,
                tip: 0,
                allow_home_service: false,
                offer_id: this.selectedOffer?.id ??'',
                code:this.selectedOffer?.code ?? ''
            },
            loginForm: {
                email: '',
                password: ''
            }
        }
    },
    mounted() {
        let self = this;
        // Set Services by category group
        this.setServicesIfEmployeeSelected();

        if (+this.selectedServiceId) {
            this.form.services.push(this.branch.services.find(s => s.service.id === +this.selectedServiceId).service);
        } else if (+this.selectedEmployeeId) {
            this.form.employee_id = this.selectedEmployeeId;
        }
        setTimeout(() => {
            // Set Services by voucher || membership
            this.setServicesIfVoucherOrMembership();

            if (document.getElementsByClassName('btn-link').length) {
                document.getElementsByClassName('btn-link')[0].click()
            }
            // Disable Off Days OR Weekend
            this.disableOffDays();
            document.getElementsByClassName('right-arrow')[0].addEventListener('click', function () {
                self.disableOffDays();
            });
        }, 500)
    },
    methods: {
        setServicesIfEmployeeSelected() {
            let employeeServices = [];
            if (this.selectedEmployeeId) {
                for (let i = 0; i < this.branch.services.length; i++) {
                    let branchService = this.branch.services[i];
                    if (branchService.service.employees.length && branchService.service.employees.some(e => e.employee_id === this.selectedEmployeeId)) {
                        employeeServices.push(branchService);
                    }
                }
            }
            this.services = _.groupBy(this.selectedEmployeeId ? employeeServices : this.branch.services, s => s.service.service_category_id);
        },
        setServicesIfVoucherOrMembership() {
            if (this.cardType) {
                this.form.services = this.card.services.map(s => s.service);
            }
        },
        selectedDate(date) {
            if (new Date(date.dateFormat) > new Date()) {
                this.form.date = date;
            } else {
                this.form.date = '';
                this.form.time = '';
            }
        },
        makeTimeSlots(startString, endString, diff = 15) {
            // Take a start/end times
            const start = moment(startString, 'HH:mm:ss A');
            const end = moment(endString, 'HH:mm:ss A');

            // Added starting time in array
            const timeSeries = [start.format('LT')];
            while (start.isSameOrBefore(end)) {
                // add Diff minutes to the starting point
                let addedTime = start.add(diff, 'm').format('LT');
                timeSeries.push(addedTime);
            }
            // if last index greater than end time after diff
            if (timeSeries[timeSeries.length - 1] > end.format('LT')) {
                timeSeries[timeSeries.length - 1] = end.format('LT');
            }
            return timeSeries;
            // return new Set(timeSeries);
        },
        disableOffDays() {
            this.branch.schedule.forEach(schedule => {
                if (+schedule.on === 0) {
                    this.disableDaysByDayName(schedule.day);
                }
            })
        },
        getShortCodeByDayName(dayName) {
            let shortDayName = '';
            switch (dayName) {
                case 'Monday':
                    shortDayName = 'Mo';
                    break;
                case 'Tuesday':
                    shortDayName = 'Tu';
                    break;
                case 'Wednesday':
                    shortDayName = 'We';
                    break;
                case 'Thursday':
                    shortDayName = 'Th';
                    break;
                case 'Friday':
                    shortDayName = 'Fr';
                    break;
                case 'Saturday':
                    shortDayName = 'Sa';
                    break;
                case 'Sunday':
                    shortDayName = 'Su';
                    break;
                default:
                    shortDayName = '';
                    break;
            }
            return shortDayName;
        },
        getDayNameByShortCode(shortCode) {
            let dayName = '';
            switch (shortCode) {
                case 'Mo':
                    dayName = 'Monday';
                    break;
                case 'Tu':
                    dayName = 'Tuesday';
                    break;
                case 'We':
                    dayName = 'Wednesday';
                    break;
                case 'Th':
                    dayName = 'Thursday';
                    break;
                case 'Fr':
                    dayName = 'Friday';
                    break;
                case 'Sa':
                    dayName = 'Saturday';
                    break;
                case 'Su':
                    dayName = 'Sunday';
                    break;
                default:
                    dayName = '';
                    break;
            }
            return dayName;
        },
        disableDaysByDayName(dayName) {
            let dateItems = document.getElementsByClassName('date-list-scroll')[0].getElementsByClassName('date-item');
            if (dateItems.length) {
                for (let i = 0; i < dateItems.length; i++) {
                    if (dateItems[i].getElementsByClassName('date-item-day')[0].textContent === this.getShortCodeByDayName(dayName)) {
                        dateItems[i].classList.add('disabled');
                    }
                }
            }
        },
        setDateTimeOnNextOrBackStep() {
            let date = this.form.date;
            let time = this.form.time;
            setTimeout(() => {
                this.form.date = date;
                this.form.time = time;
                if (date) {
                    let splitDate = date.dateFormat.split('/');
                    let selectorElement = `${splitDate[1]}-${parseInt(splitDate[2])}-${splitDate[0]}`;
                    document.querySelector(`[data-date="${selectorElement}"]`).click();
                }
            }, 100);
        },
        book(newCard) {
            this.form.new_card = !!(newCard ?? false);

            this.setDateTimeOnNextOrBackStep();

            if (this.step === 3) {
                if (!this.form.date || !this.form.time) {
                    return this.$toast.error('Please, Date & Time both are required.');
                }else{
                    this.checkBookingSlotsAvailable();
                }
            }

            if (this.step < 4) {
                this.step++;
                if (this.step === 3 && !this.form.date) {
                    setTimeout(() => {
                        // click tomorrow date
                        document.getElementsByClassName('date-list-scroll')[0].childNodes[1].click();
                    }, 100)
                }
            } else if (this.step === 4) {
                this.bookRequest();
            }
        },
        back() {
            if (this.step > 1) this.step--;
            this.setDateTimeOnNextOrBackStep();
        },
        bookRequest() {
            this.isLoading = true;
            axios.post(`/booking?${this.cardType ? this.cardType.concat('=').concat(this.card.id) : ''}`, this.form)
                .then(res => {
                    this.$toast.success(`Successfully, Booking has been created.`);
                    setTimeout(() => {
                        window.location.href = this.cardType ? `/` : res.data.data.redirect_to;
                    }, 1500);
                })
                .catch(err => {
                    this.isLoading = false;
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                });
        },
        remove(serviceId) {
            this.form.services = this.form.services.filter(s => s.id !== serviceId);
        },
        selectStaff(id) {
            this.form.employee_id = id;
        },
        getDatesByDayName(dayName) {
            let res = [];
            let day = moment()
                .startOf('month')
                .day(dayName);
            if (day.date() > 7) day.add(7, 'd');
            let month = day.month();
            while (month === day.month()) {
                res.push({
                    start: new Date(day.toString()),
                    end: new Date(day.toString()),
                })
                day.add(7, 'd');
            }
            return res;
        },
        getTotalMinutes() {
            return this.form.services.reduce((accumulator, current) => accumulator + Number(current['price_duration']), 0)
        },
        login() {
            this.isLoading = true;
            axios.post('/login/ajax', this.loginForm)
                .then(res => {
                    this.authCheck = res.data.data.authCheck ?? false;
                    this.loginForm.email = '';
                    this.loginForm.password = '';
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                }).finally(() => this.isLoading = false);
        },
        getSelectedEmployeeBookings() {
            this.selectedEmployeeBookings = [];
            if (+this.form.employee_id > 0 && this.form.employee_id !== 'no_preference') {
                axios.get(`/get/uncompleted/bookings/as/employee/${this.form.employee_id}`)
                    .then(res => {
                        this.selectedEmployeeBookings = res.data.data.bookings;
                    })
                    .catch(err => {
                        console.log(err.response.statusText);
                    });
            }
        },
        removeAlreadyBookedTimeSlots(timeSlots) {
            let res = timeSlots;
            let bookedTimeSlots = [];
            if (this.selectedEmployeeBookings.length) {
                this.selectedEmployeeBookings.forEach(b => {
                    const dateIsSame = moment(b.date).isSame(moment(this.form.date?.dateFormat));
                    if (dateIsSame) {
                        let newTimeSlots = this.makeTimeSlots(b.start_time, b.end_time);
                        bookedTimeSlots = [...bookedTimeSlots, ...newTimeSlots];
                    }
                });
                // Removing times from booked
                for (let i = 0; i < bookedTimeSlots.length; i++) {
                    res = res.filter(time => time !== bookedTimeSlots[i]);
                }
            }
            return res;
        },
        offerServicePrice(serviceId, total){
            if(!this.offer.id || !this.offer.services.some(o=>o.service_id === serviceId)){
                return false;
            }
            return this.moneyFormat((total - ((total/100) * (this.offer?.discount ?? 100))));
        },
        checkBookingSlotsAvailable(){
            if(this.form.time && this.form.end_time) {
                let currentBookingSlots = this.makeTimeSlots(this.form.time, this.form.end_time);
                for(let i = 0; i < currentBookingSlots.length; i++) {
                    if(!this.getTiming.items.includes(currentBookingSlots[i])) {
                        this.$toast.error('All time slots are not available.');
                        this.form.time = '';
                        this.form.end_time = '';
                        this.step = 3;
                        return false;
                    }
                }
            }
        }
    },
    computed: {
        isMembership() {
            return this.cardType === 'membership';
        },
        isVoucher() {
            return this.cardType === 'voucher';
        },
        getTitle() {
            let res;
            switch (this.step) {
                case 1:
                    res = `Select${this.cardType ? 'ed ' + this.cardType : ' Service'}`;
                    break;
                case 2:
                    res = 'Select Staff';
                    break;
                case 3:
                    res = 'Select Time';
                    break;
                case 4:
                    res = 'Review and confirm';
                    break;
                default:
                    res = '';
                    break;
            }
            return res;
        },
        getTotal() {
            let sum = +this.form.tip;
            for (let i = 0; i < this.form.services.length; i++) {
                let service = this.form.services[i];
                if(this.offerServicePrice(service.id, service.price)){
                    sum += +this.offerServicePrice(service.id, service.price);
                }else {
                    sum += +service.price;
                }
            }
            return this.moneyFormat(sum);
        },
        getRelatedTeam() {
            let res = [];
            for (let i = 0; i < this.form.services.length; i++) {
                for (let j = 0; j < this.form.services[i].employees.length; j++) {
                    let employee = this.form.services[i].employees[j].employee;
                    if (employee && !res.some(e => e.id === employee.id)) {
                        if (!this.selectedEmployeeId) {
                            res.push(employee);
                        } else if (this.selectedEmployeeId && employee.id === this.selectedEmployeeId) {
                            res.push(employee);
                        }
                    }
                }
            }
            return res;
        },
        getSelectedDate() {
            if (this.form.date) return moment(new Date(this.form.date.dateFormat)).format('LL');
        },
        getTiming() {
            let res = [];
            let schedules = this.branch.schedule;
            for (let i = 0; i < schedules.length; i++) {
               let schedule = schedules[i];
                for (let j = 0; j < schedule.timing.length; j++) {
                    if (!!schedule.on && schedule.day === this.getDayNameByShortCode(this.form.date.day)) {
                        let newTimeSlots = this.makeTimeSlots(schedule.timing[j].start, schedule.timing[j].end);
                        res = [...res, ...newTimeSlots];
                    }
                }
            }

            res = res.filter(({time}, index) => !res.includes(time, index + 1));

            // Remove already booked time slots for selected date, if employee already booked
            res = this.removeAlreadyBookedTimeSlots(res);

            return collect(res).unique();

            // Sorting
            // return res.sort(function (a, b) {
            //     let c = moment(a, 'HH:mm');
            //     let d = moment(b, 'HH:mm');
            //     return c - d;
            // });
        },
        getTotalHours() {
            this.form.total_duration = this.timeFormat(this.getTotalMinutes());
            return this.form.total_duration;
        },
        getEndTime(){
            this.form.end_time = moment(this.form.time, 'LT').add(this.getTotalMinutes(), 'minutes').format('LT');
            return this.form.end_time;
        }
    },
    watch: {
        'form.services'() {
            if (this.form.services.length === 6 && !this.cardType) this.$toast.error("You've reached limit of 6 added services.");
        },
        'form.employee_id'() {
            this.getSelectedEmployeeBookings();
        },
        'form.code'() {
            if(!this.selectedOffer && this.form.code.length === 6){
                this.offer = {};
                this.isLoading = true;
                axios.post(`/account/get/offer/by/code`,{
                    code: this.form.code
                })
                .then(res => {
                    this.offer = res.data.data.offer ?? {};
                    this.form.offer_id = this.offer.id;
                    this.form.code = this.offer.code;
                })
                .catch(err => {
                    this.form.offer_id = '';
                    this.form.code = '';
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                }).finally(() => this.isLoading = false);
            }
        },
        'form.time'() {
            setTimeout(()=>{
              this.checkBookingSlotsAvailable();
            },800)
        },
    }
}
</script>

<style scoped>
#book-section {
    padding-top: 2rem;
    /*background: url("/images/wave1.png");*/
    /*background-repeat: no-repeat;*/
}

.btn-time {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: var(--primary-color);
    opacity: 1;
    box-shadow: 0px 0px 12px -3px grey;
    width: 30%;
}

.btn-time.active {
    color: white !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color);
    opacity: 1;
    /*box-shadow: 0px 0px 12px -3px grey;*/
}

h1 {
    color: black;
}

#accordion .btn.btn-link {
    color: black;
}

#accordion .card {
    border-radius: 0 0 0 20px;
    border-bottom: 4px solid rgb(54 104 101);
}

#accordion .card-header {
    padding: 2px 0;
    background: white;
    border-radius: 0 0 0 20px;
}

.switch {
    width: 50px;
    height: 25px;
}

.switch .slider:before {
    width: 17px;
    height: 17px;
    left: 4px !important;
    bottom: 4px !important;
}

.card-cart {
    border-bottom: 4px solid #366865;
    border-radius: 0 0 0 20px;
    margin-top: 4rem;
}

.card-cart .name {
    font-size: 12px;
}

.card-cart .currency {
    font-size: 14px;
}

.card-cart img {
/*    max-width: 30em;*/
    margin-top: -68px;
/*    margin-left: 40px;*/
    width: 20em;

}

.back {
    color: white;
    margin-right: 8px;
}

.step-title {
    /*color: #f5f5f594;*/
    font-size: 15px;
}

.currency {
    color: #366865;
    font-weight: bolder;
    font-size: 18px;
}

.old-price {
    color: #e62d2d;
    font-weight: normal !important;
    font-size: 12px !important;
    text-decoration: line-through;
}

.fa.fa-arrow-left {
    color: black;
    font-size: 1em;
    padding: 11px 12px;
    border: 1px solid;
    border-radius: 50%;
}

.fa.fa-arrow-left:hover {
    color: white;
    background: black;
}

i.fa.fa-remove {
    color: red;
    font-size: 17px;
}

.total {
    color: black;
    padding: 10px 5px;
    font-weight: bolder;
    font-size: larger;
}

.total p {
    color: white;
    font-weight: bolder;
    font-size: 20px;
}

#staff img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
}

#staff li.list-group-item {
    /*background: radial-gradient(black, transparent);*/
    background: transparent;
    cursor: pointer;
    border-radius: 2px solid rgb(0 0 0 / 9%) !important;
}

#staff li.list-group-item.active p {
    color: white !important;
}

#staff li.list-group-item.active {
    background: #315855;
    border: 1px dashed #24d133;
    border-radius: 10px;
}

#staff li.list-group-item.active img {
    border: 2px solid #17ff17b8;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

div.confirm-section {
    background: white;
    padding: 50px;
    border-radius: 10px;
    margin-top: 2em;
}

.count-service {
    font-size: 3rem;
    color: #366865;
}

textarea {
    height: 10rem;
}

/* The container */
.cb-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cb-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -7px;
    left: 0;
    height: 32px;
    width: 32px;
    /*background-color: #b5cdccbd;*/
    border-radius: 20px;
    border: 1px dashed;
}

/* On mouse-over, add a grey background color */
.cb-container:hover input ~ .checkmark {
    background-color: black;
}

/* When the checkbox is checked, add a blue background */
.cb-container input:checked ~ .checkmark {
    background-color: black;
    border-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cb-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.cb-container .checkmark:after {
    left: 12px;
    top: 5px;
    width: 8px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#detail-section {
    color: black;
    /*height: 100vh;*/
    /*overflow: scroll;*/
}

.card-body {
    padding: 0rem 0.5rem 1rem 0.5rem;
}

#offer-code{
    background-color: white !important;
    font-size: 4em;
    font-weight: bolder;
    text-align: center;
    padding: 0;
}
#offer-code.active{
    background-color: #6ec135 !important;
   color: white;
}
</style>
