<template>
    <div id="sidepanel">
        <div id="profile">
            <div class="wrap">
                <img id="profile-img" :src="$parent.authUser?.user_image_url" class="online" :alt="$parent.authUser?.name" />
                <p class="text-capitalize">{{ $parent.authUser?.name }}</p>
                <!--                <i class="fa fa-chevron-down expand-button" aria-hidden="true"></i>-->
                <!--                <div id="status-options">-->
                <!--                    <ul>-->
                <!--                        <li id="status-online" class="active"><span class="status-circle"></span> <p>Online</p></li>-->
                <!--                        <li id="status-away"><span class="status-circle"></span> <p>Away</p></li>-->
                <!--                        <li id="status-busy"><span class="status-circle"></span> <p>Busy</p></li>-->
                <!--                        <li id="status-offline"><span class="status-circle"></span> <p>Offline</p></li>-->
                <!--                    </ul>-->
                <!--                </div>-->
                <!--                <div id="expanded">-->
                <!--                    <label for="twitter"><i class="fa fa-facebook fa-fw" aria-hidden="true"></i></label>-->
                <!--                    <input name="twitter" type="text" value="mikeross" />-->
                <!--                    <label for="twitter"><i class="fa fa-twitter fa-fw" aria-hidden="true"></i></label>-->
                <!--                    <input name="twitter" type="text" value="ross81" />-->
                <!--                    <label for="twitter"><i class="fa fa-instagram fa-fw" aria-hidden="true"></i></label>-->
                <!--                    <input name="twitter" type="text" value="mike.ross" />-->
                <!--                </div>-->
            </div>
        </div>
        <div id="search">
            <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
            <input type="text" placeholder="Search contacts..." v-model="$parent.$data.keyword"/>
        </div>
        <div id="contacts">
            <transition-group name="flip-list" tag="ul">
                <li v-for="conversation in $parent.getConversations"
                    :key="conversation.id"
                    :class="`contact ${$parent.$data.activeConversation?.id === conversation.id?'active':''}`"
                    @click="select(conversation)"
                    data-toggle="tooltip" title="hello"
                >
                    <div class="wrap">
                        <!-- <span class="contact-status online"></span>-->
                        <img :src="$parent.getOtherUser(conversation)?.user_image_url" :alt="$parent.getOtherUser(conversation)?.name"/>
                        <div class="meta">
                            <div class="name">
                                {{ $parent.getOtherUser(conversation)?.name }}
                                <span v-if="conversation.unread" class="unread-counter">{{ conversation.unread }}</span>
                            </div>
                            <p class="preview">
                                <i v-if="conversation?.last_message?.file" class="fa fa-image mr-1"></i>
                                {{ conversation?.last_message?.text }}
                            </p>
                        </div>
                        <span v-if="isMobile()" class="badge badge-light name-sm">{{ $parent.getOtherUser(conversation)?.name }}</span>
                    </div>
                </li>
            </transition-group>
        </div>
        <div id="bottom-bar">
            <!--  <button id="addcontact"><i class="fa fa-user-plus fa-fw" aria-hidden="true"></i> <span>Add contact</span></button>-->
            <!--  <button id="settings"><i class="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Settings</span></button>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "Sidepanel",
    data(){
        return{

        }
    },
    mounted() {
    },
    methods: {
        select(conversation) {
            this.$emit('change', conversation)
        },
        isMobile() {
            return window.innerWidth <= 735;
        },
    },
}
</script>

<style scoped>
.flip-list-move {
    transition: transform 0.5s;
}
</style>
