<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
      <!--    listing-->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center justify-content-md-end mb-3">
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1" data-toggle="modal"
                 data-target="#offer-modal" @click="reset"><i class="fa fa-plus"></i> Add Offer</a>
            </div>

            <div class="row ">
              <div v-for="offer in offers" class="col-md-4">
                <offer-card
                        :offer-id="offer.id"
                        :name="offer.name"
                        :code="+offer.code"
                        :discount.sync="+offer.discount"
                        :valid-for.sync="offer.valid_for"
                        :select-all.sync="!!offer.all_services"
                        :services-count.sync="offer.services.length"
                        :image.sync="offer.image"
                        size="sm"
                        :can-edit="true"
                        :click-able="true"
                        @click="clickedOffer"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

      <!--create or update modal-->
    <div class="modal fade bs-modal-xl in" id="offer-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-xl-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">{{ form.id ? 'Edit' : 'Create' }} Offer</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i
                    class="fa fa-times"></i></button>
          </div>
          <div class="modal-body">

            <!--            Basic info-->
            <div class="row">
              <div class="col-md-6">

                <div class="col-sm-12">
                    <h3>Create Offer <small>{{ step }}/2 steps</small></h3>
                  <small class="text-muted">Add the offer name, discount and duration of the offer.</small>
                </div>

                <TransitionGroup enter-active-class="animate__animated animate__flipInX"
                                 leave-active-class="animate__animated animate__flipOutX">
<!--                Step 1-->
                  <div v-show="step===1" :key="`step-1`">
                  <div class="col-md-12 mt-4">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Offer name</label>
                      <input type="text" class="form-control" v-model="form.name">
                    </div>
                  </div>

                  <div class="col-md-12">

                    <div class="form-group">
                      <label>Discount %</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">AED</span>
                        </div>
                        <input type="number" class="form-control" aria-label="Amount" v-model="form.discount">
                        <div class="input-group-append">
                          <span class="input-group-text">.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Valid For</label>
                      <select class="form-control" v-model="form.valid_for">
                        <option value="14_days">14 days</option>
                        <option value="1_months">1 month</option>
                        <option value="2_months">2 months</option>
                        <option value="3_months">3 months</option>
                        <option value="4_months">4 months</option>
                        <option value="6_months">6 months</option>
                        <option value="1_years">1 year</option>
                        <option value="3_years">3 years</option>
                        <option value="5_years">5 years</option>
                        <option value="never">Forever</option>
                      </select>
                    </div>
                  </div>

                       <div class="col-md-12 my-4">
                              <label for="">Branch</label>
                              <select class="form-control"  v-model="form.branch_id">
                                  <option value="">Select</option>
                                  <option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.name }}</option>
                              </select>
                          </div>

                    <div class="col-md-12">
                    <!-- text input -->
                    <div class="form-group">
                      <label>Included services</label>
                      <p class="service-info">
                        {{ form.all_selected ? 'All' : form.services.length }} Services
                        <a href="javascript:void(0);" class="btn btn-sm pull-right" data-toggle="modal"
                           data-target="#select-services-modal"><i class="fa fa-pencil"></i></a>
                      </p>
                    </div>
                  </div>

                </div>
<!--                Step 2-->
                <div v-if="step===2" :key="`step-2`">
                     <div class="col-md-12">
                         <div class="row">
                              <div v-for="n in 39" :key="`img-btn-${n}`"
                                   :class="`col-sm-12 col-md-3 p-1 h-[70px] ${(n+1) === form.image?'image-selected':''}`">
                                  <button class="btn w-100 h-100"
                                          :style="{ 'background-image': 'url(/user-uploads/offers/'+(n+1)+'.webp)',
                                                    'background-size': 'cover',
                                                    'background-repeat': 'no-repeat',
                                                    'background-position': 'right', }"
                                          @click="form.image = (n+1)"
                                  ></button>
                              </div>
                         </div>
                     </div>
                </div>
                </TransitionGroup>
              </div>

              <!-- Offer Card preview-->
              <div class="col-md-6">
                  <offer-card
                          :offer-id="form.id"
                          :name="form.name"
                          :discount.sync="+form.discount"
                          :valid-for.sync="form.valid_for"
                          :select-all.sync="!!form.all_selected"
                          :services-count.sync="+form.services.length"
                          :image.sync="form.image"
                          size="lg"
                  />
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
            <a v-if="step>1" href="javascript:void(0);" class="btn btn-dark" @click="back">Back</a>
            <a v-if="step<2" href="javascript:void(0);" class="btn btn-dark" @click="next">Next</a>
            <a v-if="step === 2" href="javascript:void(0);" class="btn btn-success" @click="createUpdate()">
                <i class="fa fa-check"></i> {{ form.id ? 'Update' : 'Save' }}
            </a>
          </div>
        </div>
      </div>
    </div>

      <!--View Offer modal-->
    <div class="modal fade bs-modal-xl in" id="view-offer-modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-xl-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">View Offer</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i
                    class="fa fa-times"></i></button>
          </div>
          <div class="modal-body">

            <!--            Basic info-->
            <div class="row">

              <!--              Offer Card preview-->
              <div class="col-md-7">
                <offer-card
                        :offer-id="viewOffer.id"
                        :name="viewOffer.name"
                        :code="viewOffer.code"
                        :discount.sync="+viewOffer.discount"
                        :valid-for.sync="viewOffer.valid_for"
                        :select-all.sync="!!viewOffer.all_selected"
                        :services-count.sync="+viewOffer.services.length"
                        :image.sync="viewOffer.image"
                        size="lg"
                />
              </div>

              <div class="col-md-5">
                <div class="col-sm-12 text-center">
                    <h3>{{ viewOffer.name }} </h3>
                </div>
                <div class="col-sm-12 text-center">
                    <h6>{{ viewOffer.code }} </h6>
                </div>

                <div class="col-sm-12 text-center">
                    <h6><b>Discount: </b>{{ viewOffer.discount }} %</h6>
                </div>

                <div class="col-sm-12 text-center mt-12">
                  <a href="javascript:void(0);" class="btn btn-outline-dark " @click="edit">
                      <i class="fa fa-pencil"></i>
                  </a>

                  <a href="javascript:void(0);" class="btn btn-outline-danger " @click="remove">
                      <i class="fa fa-remove"></i>
                  </a>
                </div>

              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <SelectServicesModal
            ref="selectService"
            :services="services"
            :all-services="form.all_selected"
            @updated="updateServices"
    />
  </div>
</template>

<script>
import SelectServicesModal from "@components/includes/SelectServicesModal";

export default {
    name: "Offers",
    components: { SelectServicesModal },
    props: {
        services: {
            type: Array,
            default: () => []
        },
        offerData: {
            type: Array,
            default: () => []
        },
        branches: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            isLoading: false,
            offers: this.offerData ?? [],
            viewOffer: {
                services: {}
            },
            step: 1,
            form: {
                id: '',
                branch_id: '',
                name: '',
                code: '',
                discount: 10,
                valid_for: '14_days',
                all_selected: true,
                services: [],
                image: 14,
            }
        }
    },
    methods: {
        next(){
            if(this.step === 1) {
                if(!this.form.name || !this.form.discount || !this.form.valid_for) {
                    return this.$toast.error('All Fields are required.')
                } else if(!this.form.services) {
                    return this.$toast.error('Services are required.')
                }
            }
            if(this.step < 2) this.step++;
        },
        back(){
            if(this.step > 1) this.step--;
        },
        createUpdate(){
            this.isLoading = true;
            axios.post('/account/offers', this.form)
            .then(res => {
                this.$toast.success(`Successfully, Offer has been ${ this.form.id ? 'updated' : 'created' }.`);
                this.offers = res.data.data.offers ?? [];
                $('#offer-modal').modal('hide');
                $('.modal-backdrop').remove();
                this.reset();
            })
            .catch(err => {
                this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
            }).finally(() => this.isLoading = false);
        },
        clickedOffer(offerId){
            this.viewOffer = this.offers.find(v => v.id === offerId);
            this.viewOffer.services = this.viewOffer.services.map(s=>s.service) ?? [];
            $('#view-offer-modal').modal('show');
        },
        edit(){
            this.form.id = +this.viewOffer.id;
            this.form.branch_id = this.viewOffer.branch_id;
            this.form.name = this.viewOffer.name;
            this.form.discount = +this.viewOffer.discount ?? 0;
            this.form.valid_for = this.viewOffer.valid_for ?? '14_days';
            this.form.all_selected = !!this.viewOffer.all_services ?? true;
            this.form.services = this.viewOffer.services ?? [];
            this.form.image = +this.viewOffer.image ?? 14;

            $('#view-offer-modal').modal('hide');
            $('#offer-modal').modal('show');

            this.$refs.selectService.setServices(this.form.services ?? []);
        },
        remove(){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete offer?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No, Cancel',
                confirmButtonText: 'Yes, Delete',
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed) {
                    this.isLoading = true;
                    axios.delete(`/account/offers/${ this.viewOffer.id }`)
                    .then(res => {
                        this.$toast.success(`Successfully, Offer has been deleted`);
                        this.offers = res.data.data.offers ?? [];
                        $('#view-offer-modal').modal('hide');
                        this.reset();
                    })
                    .catch(err => {
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                    })
                    .finally(() => this.isLoading = false);
                }
            })
        },
        reset(){
            this.form.id = '';
            this.form.branch_id = '';
            this.form.name = '';
            this.form.discount = 0;
            this.form.valid_for = '14_days';
            this.form.image = 14;
            this.form.all_selected = true;
            this.form.services = [];
            this.step = 1;

            this.viewOffer = {
                services: {}
            }
            this.$refs.selectService.reset();
        },
        updateServices(data){
            this.form.all_selected = data.all;
            this.form.services = data.services;
        }
    }
}
</script>

<style scoped>
.input-group-text {
    background: white;
}

.service-info {
    padding: 10px 15px;
    font-size: 20px;
    background: #e1dede78;
    border-radius: 6px;
}
label {
    font-weight: 500;
}

.view-btn {
    padding: 1rem 2rem;
    border-radius: 5px;
    box-shadow: 6px 4px 26px -5px;
}

.view-btn i.fa {
    font-size: 2rem;
}
.image-selected{
    border: 5px solid #24d424;
    border-radius: 5px;
}
</style>
