export default {
    methods: {
        isBusinessService(order) {
            return order.type === "App\\BusinessService";
        },
        isVoucher(order) {
            return order.type === "App\\Voucher";
        },
        isMembership(order) {
            return order.type === "App\\Membership";
        },
        isSMSPackage(order) {
            return order.type === "App\\SMSPackage";
        },
        isAdvertisementPackage(order) {
            return order.type === "App\\AdvertisementPackage";
        },
        isProduct(order) {
            return order.type === "App\\Product";
        },
        getStatusText(order){
            return order.status.replaceAll('_',' ');
        },
    }
}

