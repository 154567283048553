<template>
  <!--Select Services modal-->
  <div class="modal fade bs-modal-xl in" id="select-services-modal" role="dialog" aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" id="modal-xl-data-location">
      <div class="modal-content">
        <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">Select Services</span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <!--            Basic info-->
          <div class="row px-4">
            <div class="col-sm-12">
              <label class="sr-only" for="inlineFormInputGroup">Search</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text px-3"><i class="fa fa-search"></i></div>
                </div>
                <input type="text" class="form-control form-control-lg " id="inlineFormInputGroup"
                       placeholder="Search Services" v-model="keyword">
              </div>
            </div>

            <div class="col-md-12">
              <ul class="list-group">

                <li v-if="!keyword" class="list-group-item d-flex justify-content-between align-items-center pb-0">
                  <div class="switch-div">
                    <label class="cb-container">
                      <input type="checkbox" name="all_services" id="all_services" ref="allServices" v-model="selectAll" @click="allClicked">
                      <span class="checkmark"></span>
                    </label>
                    <label class="text-label">
                      <b>All {{ selectAll ? 'Unselect' : 'Select' }}</b>
                    </label>
                  </div>
                </li>

                <template v-if="getCategoryServices.length">
                  <div v-for="(categoryService,i) in getCategoryServices" :key="i">

                    <li class="list-group-item d-flex justify-content-between align-items-center pb-0">
                      <div class="switch-div">
                        <label class="cb-container">
                          <input type="checkbox" :ref="`category${categoryService[0].service_category_id}`"
                                 name="category_services" value="all"
                                 @change="toggleCategoryCheck(categoryService[0].service_category_id)">
                          <span class="checkmark"></span>
                        </label>
                        <label class="text-label">
                          <b>{{ categoryService[0].service_category.name }}</b>
                          <span class="badge badge-dark badge-pill">{{ categoryService.length }}</span>
                        </label>
                      </div>
                    </li>

                    <li v-for="(service,j) in categoryService" :key="`service-${i}-${j}`"
                        class="list-group-item d-flex justify-content-between align-items-center pb-0">
                      <div class="switch-div">
                        <label class="cb-container">
                          <input type="checkbox" name="services[]" :ref="`service${service.id}`" :value="service" v-model="selected">
                          <span class="checkmark"></span>
                        </label>
                        <label class="text-label">
                          {{ service.name }}
                          <small class="badge badge-dark">{{ timeFormat(service.price_duration) }}</small>
                        </label>
                      </div>
                      <span class="price">AED {{ service.price.toFixed(2) }}
                        <small>{{ getPriceType(service.price_type) }}</small>
                      </span>
                    </li>
                  </div>
                </template>

                <h4 v-else class="text-center my-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                       class="bi bi-emoji-frown-fill mr-2" viewBox="0 0 16 16">
                    <path
                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z"/>
                  </svg>
                  Not Services Found
                </h4>
              </ul>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
          <a href="javascript:void(0);"
             :class="`btn btn-success ${selected.length ?'':'disabled' }`" @click="done()"><i class="fa fa-check"></i>
            Selected {{ selected.length }} Services
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TimeDateMixin from '@mixins/timeDateMixin';
import HelperMixin from '@mixins/helperMixin';

export default {
  name: "SelectServicesModal",
  mixins: [TimeDateMixin,HelperMixin],
  props: {
    allServices: {
      type: Boolean,
      default: () => true
    },
    selectedServices:{
      type: Array,
      default: ()=>[]
    },
    services: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      selectAll: false,
      keyword: '',
      categoryServices: [],
      selected: []
    }
  },
  mounted(){
    this.categoryServices = _.groupBy(this.services, s => s.service_category_id);
    setTimeout(() => {
      this.selectAll = this.allServices ?? true;
      this.selected = this.selectAll ? this.services : this.selectedServices;
    },1000)
  },
  methods: {
    done(){
      this.emitSelectedService();
      $('#select-services-modal').modal('hide');
    },
    allClicked(){
      setTimeout(()=>{
        if(this.selectAll) {
          this.selected = this.services;
        } else {
          this.selected = [];
        }
      },10)
    },
    categoryAutoCheckUncheck(){
      _.toArray(this.getCategoryServices).forEach(categoryService => {
        let categoryServiceId = +categoryService[0].service_category_id;
        let selectedCount = this.selected.filter(s => s.service_category_id === categoryServiceId).length;
        if(this.$refs[`category${categoryServiceId}`]) {
          this.$refs[`category${categoryServiceId}`][0].checked = selectedCount === categoryService.length;
        }
      });

      this.selectAll = this.selected.length ===this.services.length;

      this.emitSelectedService();
    },
    toggleCategoryCheck(categoryServiceId){
      let relatedCategoryServices = [];
      // removing services if category services is related
      this.selected = this.selected.length ? this.selected.filter(s => +s.service_category_id !== +categoryServiceId) : [];
      // add all services of category id
      if(this.$refs[`category${categoryServiceId}`][0].checked) {
        relatedCategoryServices = _.toArray(this.categoryServices).find(categoryService => {
          if(categoryService[0].service_category_id === categoryServiceId) {
            return categoryService;
          }
        });
        this.selected = [...this.selected, ...relatedCategoryServices];
      }
    },
    emitSelectedService(){
      this.$emit('updated', {
        all: this.selectAll,
        services: this.selected
      });
    },
    setServices(selectServices){
      this.selected = [];

      setTimeout(()=>{
        if(selectServices){
          selectServices.forEach(service=>{
            this.$refs[`service${service.id}`][0].click();
          })
        }
        this.categoryAutoCheckUncheck();
      },100)
    },
    reset(){
      this.$refs.allServices.checked=false;
      this.$refs.allServices.click();
    }
  },
  computed: {
    getCategoryServices(){
      return _.toArray(this.categoryServices).filter(categoryService => {
        return this.keyword.toLowerCase().split(' ').every(v => categoryService[0].service_category.name.toLowerCase().includes(v));
      });
    }
  },
  watch: {
    selectAll(){
      this.emitSelectedService();
    },
    categoryServices(){
      this.categoryAutoCheckUncheck();
    },
    selected(){
      this.categoryAutoCheckUncheck();
    },
    keyword(){
      setTimeout(() => this.categoryAutoCheckUncheck(), 10)
    }
  },
}
</script>

<style scoped>
.input-group-text {
  background: white;
  font-size: 1.5rem;
}

/* The container */
.cb-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: white;
  border-radius: 7px;
  border: 1px solid black;
}

/* On mouse-over, add a grey background color */
.cb-container:hover input ~ .checkmark {
  background-color: black;
}

/* When the checkbox is checked, add a blue background */
.cb-container input:checked ~ .checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cb-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cb-container .checkmark:after {
  left: 10px;
  top: 3px;
  width: 8px;
  height: 18px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.switch-div .text-label {
  position: relative;
  left: 40px;
  top: -10px;
  font-size: 16px;
}

.price {
  font-size: 20px;
}

.badge {
  font-size: 80%;
}
</style>
