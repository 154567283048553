export default {
    methods: {
        moneyFormat(number, prefix = 'AED '){
            let res = number ? number.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            }) : number;
            return prefix.concat(res);
        },
        getPriceType(type){
            let res;
            switch(type) {
                case 'free':
                    res = '(FREE)';
                    break;
                case 'starts_at':
                    res = '(STARTS AT)';
                    break;
                case 'fixed':
                    res = '(FIXED)';
                    break;
                default:
                    res = '';
                    break;
            }
            return res;
        },
    }
}

