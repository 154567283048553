<template>
    <div class="">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />

        <div v-if="authUser.advertising_is_active" class="row">
            <div class="col-sm-12 text-right">
                <a href="javascript:void(0);" class="btn btn-success" data-toggle="modal"
                   data-target="#advertising-modal">Update Advertising</a>
            </div>
        </div>

        <!-- Pricing Section -->
        <section id="pricing" class="py-8">
            <div class="max-w-12xl px-8 mx-auto lg:px-4">
                <div class="mb-16 text-center">
                    <h2 class="max-w-4xl mx-auto mb-4 text-5xl font-bold text-gray-700 font-heading">
                        <span class="relative pl-2 mr-2 text-green-400">

                  <span class="relative z-10">Advertisement</span>
                  <span class="absolute bottom-0 left-0 w-full h-3 transform -skew-x-6 bg-yellow-100">

                  </span>
                </span>
                        <span>Pricing</span>
                    </h2>
                    <p class="max-w-2xl mx-auto text-lg text-gray-500">Our marketing tools have been designed to help you engage with your customers and manage all of your marketing and promotional activities in one place. From auto campaigns. </p>
                </div>
                <div class="flex flex-wrap -mx-3">
                    <div v-if="packages[0]" class="w-full px-3 mb-6 md:w-1/2 lg:w-1/3">
                        <div class="px-8 pt-12 pb-8 text-center bg-white rounded-lg shadow">
                            <img class="h-24 mx-auto mb-8" src="/images/acorn.png" alt="starter plan image">
                            <h3 class="mb-4 text-5xl font-bold font-heading">{{ packages[0].title }}</h3>
                            <span class="flex items-center justify-center text-2xl font-bold text-gray-900 font-heading">
                                AED {{ packages[0].price }}<span
                                    class="ml-1 text-sm text-blue-900">/ {{ packages[0].days }} Days</span>
                            </span>
                            <div class="form-group text-center mt-4" style="display: inline-flex;">
                                <div class="switch-div mr-2">
                                    <label class="switch">
                                        <input name="booking_button" type="checkbox" v-model="packages[0].auto_renew"
                                               @change="offAutoRenew(packages[0])">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label class="switch-name">Renew</label>
                            </div>
                            <!--                            <p class="mt-3 mb-8 text-sm text-gray-400">This plan is ideal for startups and agencies wanting to add/update their GHL offering</p>-->
                            <!--                            <div class="flex flex-col items-center mb-8">-->
                            <!--                                <ul class="text-gray-400">-->
                            <!--                                    <li class="flex mb-2">-->
                            <!--                                        <svg class="w-6 h-6 mr-1 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
                            <!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>-->
                            <!--                                        </svg>-->
                            <!--                                        <span>Coupon codes for Order Forms</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="flex mb-2">-->
                            <!--                                        <svg class="w-6 h-6 mr-1 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
                            <!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>-->
                            <!--                                        </svg>-->
                            <!--                                        <span>Dynamic Images with custom values</span>-->
                            <!--                                    </li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <div class="">
                                <a href="javascript:void(0);"
                                   class="block px-6 py-5 mb-4 text-base font-semibold leading-none text-center text-white bg-green-400 rounded-lg sm:mb-0 hover:bg-green-700"
                                   @click="buy(packages[0])">
                                    Buy Ads
                                    <i class="fa fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="packages[1]" class="w-full px-3 mb-6 md:w-1/2 lg:w-1/3">
                        <div class="px-8 pt-8 pb-8 text-center text-white transform scale-100 rounded-lg shadow-2xl lg:scale-105 bg-gradient-to-br from-blue-700 via-blue-400 to-green-400">
                            <img class="h-24 mx-auto mb-8" src="/images/cube-b.png" alt="basic plan image">
                            <h3 class="mb-4 text-5xl font-bold font-heading">{{ packages[1].title }}</h3>
                            <span class="flex items-center justify-center text-2xl font-semibold text-white font-heading">
                               AED {{ packages[1].price }}<span class="ml-1 text-sm text-zinc-100">/ {{ packages[1].days
                                                                                                   }} Days</span>
                            </span>
                            <div class="form-group text-center mt-4" style="display: inline-flex;">
                                <div class="switch-div mr-2">
                                    <label class="switch">
                                        <input name="booking_button" type="checkbox" v-model="packages[1].auto_renew"
                                               @change="offAutoRenew(packages[1])">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label class="switch-name">Renew</label>
                            </div>
                            <!--                            <p class="mt-4 mb-8 text-sm">Total Platform! Total WhiteGlove Setup! Configuration & Launch!</p>-->
                            <div class="">
                                <a href="javascript:void(0);"
                                   class="block px-6 py-5 mb-4 text-base font-semibold leading-none text-center text-blue-400 rounded-lg bg-purple-50 hover:bg-yellow-400 sm:mb-0 hover:text-white"
                                   @click="buy(packages[1])">
                                    Buy Ads
                                    <i class="fa fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-if="packages[2]" class="w-full px-3 mb-6 lg:w-1/3">
                        <div class="px-8 pt-8 pb-8 text-center bg-white rounded-lg shadow">
                            <img class="h-24 mx-auto mb-8" src="/images/bucket-a.png" alt="plus plan image">
                            <h3 class="mb-4 text-5xl font-bold font-heading">{{ packages[2].title }}</h3>
                            <span class="flex items-center justify-center text-2xl font-bold text-gray-900 font-heading">
                                AED {{ packages[2].price }}<span
                                    class="ml-1 text-sm text-blue-900">/ {{ packages[2].days }} Days</span>
                            </span>
                            <div class="form-group text-center mt-4" style="display: inline-flex;">
                                <div class="switch-div mr-2">
                                    <label class="switch">
                                        <input name="booking_button" type="checkbox" v-model="packages[2].auto_renew"
                                               @change="offAutoRenew(packages[2])">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <label class="switch-name">Renew</label>
                            </div>
                            <!--                            <p class="mt-4 mb-8 text-sm text-gray-400">Can we build it? Yes we can!!</p>-->
                            <div class="">
                                <a href="javascript:void(0);"
                                   class="block px-6 py-5 mb-4 text-base font-semibold leading-none text-center text-white bg-green-400 rounded-lg sm:mb-0 hover:bg-green-700"
                                   @click="buy(packages[2])">
                                    Buy Ads
                                    <i class="fa fa-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Update Advertisement  -->
         <div class="modal fade bs-modal-xl in" id="advertising-modal" role="dialog" aria-labelledby="myModalLabel"
              aria-hidden="true">
            <div class="modal-dialog modal-lg" id="modal-xl-data-location">
              <div class="modal-content border-none rounded-none">
                <div class="modal-header">
                    <span class="caption-subject font-red-sunglo text-bold uppercase"
                          id="modalLgHeading">Update Advertising</span>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i
                          class="fa fa-times"></i>
                  </button>
                </div>
                <div class="modal-body">
                  <!--  Basic info-->
                    <div class="row">
                      <div class="col-md-4">

                        <div class="col-12">
                            <h3>{{ step }}/2 Steps</h3>
                          <small class="text-muted">Update the advertising Name, discount.</small>
                        </div>

                        <TransitionGroup enter-active-class="animate__animated animate__flipInX"
                                         leave-active-class="animate__animated animate__flipOutX">
        <!--                Step 1-->
                          <div v-show="step===1" :key="`step-1`">
                              <div class="col-sm-12">
                                <!-- text input -->
                                <div class="form-group">
                                  <label>Name</label>
                                  <input type="text" class="form-control" v-model="form.name">
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <!-- text input -->
                                <div class="form-group">
                                  <label>Description</label>
                                  <input type="text" class="form-control" v-model="form.description">
                                </div>
                              </div>

                              <div class="col-md-12">
                                <div class="form-group">
                                  <label>Discount %</label>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">AED</span>
                                    </div>
                                    <input type="number" class="form-control" aria-label="Amount" v-model="form.discount">
                                    <div class="input-group-append">
                                      <span class="input-group-text">.00</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
        <!--                Step 2-->
                        <div v-if="step===2" :key="`step-2`" class="row">
                             <div class="col-md-12 list-images">
                                 <div v-for="n in 109" :key="`img-btn-${n}`"
                                      :class="`col-sm-12 p-1 h-[70px] ${(n+1) === form.image?'image-selected':''}`">
                                     <button class="btn w-100 h-100"
                                             :style="{ 'background-image': 'url(/user-uploads/ads/'+(n+1)+'.webp)',
                                                       'background-size': 'cover',
                                                       'background-repeat': 'no-repeat',
                                                       'background-position': 'right', }"
                                             @click="form.image = (n+1)"
                                     ></button>
                                 </div>
                             </div>
                        </div>
                        </TransitionGroup>
                      </div>

                        <!-- Advertising Card preview-->
                      <div class="col-md-8">
                          <div class="col-md-12 my-4">
                              <label for="">Text Alignment</label>
                              <select class="form-control" v-model="form.text_align">
                                  <option value="left">Left</option>
                                  <option value="center">Center</option>
                                  <option value="right">Right</option>
                              </select>
                          </div>
                          <div class="col-md-12 my-4">
                              <label for="">Branch</label>
                              <select class="form-control"  v-model="form.branch_id">
                                  <option value="">Select</option>
                                  <option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.name }}</option>
                              </select>
                          </div>
                          <advertising-card
                                  :name="form.name"
                                  :description="form.description"
                                  :discount.sync="+form.discount"
                                  :image.sync="form.image"
                                  :text-align.sync="form.text_align"
                                  :size="'sm'"
                          />
                      </div>

                    </div>
                     <div class="col-12 text-right">
                    <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
                    <a v-if="step>1" href="javascript:void(0);" class="btn btn-dark" @click="back">Back</a>
                    <a v-if="step<2" href="javascript:void(0);" class="btn btn-dark" @click="next">Next</a>
                    <button v-if="step === 2"  class="btn btn-success" @click="updateAdvertising()">
                        <i class="fa fa-check"></i> Update
                    </button>
                  </div>
                  </div>
            </div>
            </div>
          </div>
    </div>
</template>

<script>
import Alert from "@components/includes/Alert";

export default {
    name: "Index",
    components: { Alert },
    props: {
        authUser: {
            required: true,
            type: Object,
        },
        allPackages: {
            required: true,
            type: Array,
        },
        order: {
            type: Object,
            default: () => ({})
        },
        branches: {
            type: Array,
            default: () => ([])
        }
    },
    data(){
        return {
            isLoading: false,
            packages: [],
            step:1,
            form: {
                branch_id: '',
                name: '',
                description: '',
                image: 1,
                text_align: 'left',
                discount: 10
            }
        }
    },
    mounted(){
        if(this.allPackages) {
            this.packages = this.allPackages.map(p => {
                p.auto_renew = this.order?.ref_id === p.id && this.order?.auto_renew;
                return p;
            })
        }
        if(this.authUser.advertisement_config){
            this.form.branch_id = this.authUser.advertisement_config.branch_id;
            this.form.name = this.authUser.advertisement_config.name;
            this.form.description = this.authUser.advertisement_config.description;
            this.form.image = +this.authUser.advertisement_config.image;
            this.form.text_align = this.authUser.advertisement_config.text_align;
            this.form.discount = this.authUser.advertisement_config.discount;
        }
    },
    methods: {
        buy(plan){
            swal({
                icon: 'info',
                title: 'Are you sure?',
                text: `Do you want to buy ${ plan.title } package?`,
                buttons: [ "Cancel", `Buy Ads for ${ plan.days } Days` ],
            }).then((isConfirm) => {
                if(isConfirm) {
                    this.isLoading = true;
                    axios.post(`/account/buy/advertisement/package/${ plan.id }/${ plan.auto_renew ?? 0 }`)
                    .then(res => {
                        this.$toast.success(`Successfully, You have purchased ${ plan.title } advertisement package.`);
                        setTimeout(() => {
                            window.location.href = res.data.data.redirect_to;
                        }, 1000);
                    })
                    .catch(err => {
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                        this.isLoading = false
                    })
                }
            });
        },
        updateAdvertising(){
            this.isLoading = true;
            axios.post(`/account/update/advertising`, this.form)
            .then(res => window.location.reload())
            .catch(err => this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText))
            .finally(() => this.isLoading = false);
        },
        next(){
            if(this.step === 1) {
                if(!this.form.name || !this.form.discount || !this.form.description) {
                    return this.$toast.error('All Fields are required.')
                }
            }
            if(this.step < 2) this.step++;
        },
        back(){
            if(this.step > 1) this.step--;
        },
        offAutoRenew(plan){
            if(this.order && this.order?.ref_id === plan.id && !!this.order?.auto_renew) {
                this.isLoading = true;
                axios.get(`/account/update/order/off/renew/${ this.order.id }`)
                .then(res => {
                    this.$toast.success('Auto Renew has been updated')
                })
                .catch(err => this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText))
                .finally(() => this.isLoading = false);
            }
        }
    }
}
</script>

<style scoped>
.advertisementImage {
    height: 200px
}

.ribbon {
    font-size: 17px;
    position: relative;
    display: inline-block;
    /*margin:5em;*/
    text-align: center;
}

.text {
    display: inline-block;
    padding: 0.5em 1em;
    /*min-width: 20em;*/
    width: 100%;
    line-height: 1.2em;
    background: #11ff2e;
    position: relative;
}

.ribbon:after, .ribbon:before,
.text:before, .text:after,
.bold:before {
    content: '';
    position: absolute;
    border-style: solid;
}

.ribbon:before {
    top: 0.3em;
    left: 0.2em;
    width: 100%;
    height: 100%;
    border: none;
    background: #EBECED;
    z-index: -2;
}

.text:before {
    bottom: 100%;
    left: 0;
    border-width: 0.9em 3em 0 0;
    border-color: transparent #18b42a transparent transparent;
}

.text:after {
    top: 100%;
    right: 0;
    border-width: 1em 5em 0 0;
    border-color: #18b42a transparent transparent transparent;
}

.ribbon:after, .bold:before {
    top: 0.5em;
    right: -2em;
    border-width: 1.1em 1em 1.1em 3em;
    border-color: #18b42a transparent #18b42a #18b42a;
    z-index: -1;
}

.bold:before {
    border-color: #EBECED transparent #EBECED #EBECED;
    top: 0.7em;
    right: -2.3em;
}

.list-images {
    height: 30em;
    overflow: scroll;
}

</style>
