<template>
<div class="row">
     <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              loader="dots"
              color="#366865"
              background-color="#a3afeb66"
     />
    <div class="col-md-12">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-sm-12">
                        <h6>Dates</h6>
                        <v-date-picker
                                ref="dt"
                                v-model="range"
                                mode="date"
                                :masks="config.masks"
                                :model-config="config.modelConfig"
                                :max-date='new Date()'
                                :columns="$screens({ default: 1, lg: 6 })"
                                is-range
                                is-expended
                        />

                    </div>
                </div>
                <!-- Custom Tabs -->
                <div class="card mt-4">
                    <div class="card-header d-flex p-0">
                        <h3 class="p-3">Transactions</h3>
                        <a :href="`/account/reports?from=${this.range.start}&to=${this.range.end}&export=pdf`"
                           target="_blank" class="btn btn-lg btn-danger h-full mt-3"><i class="fa fa-file-pdf"></i> Export</a>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_1">
                                <div class="table-responsive">
                                    <table id="earningTable" class="table">
                                        <thead>
                                            <tr>
                                                <th>Charged ID</th>
                                                <th>Order ID</th>
                                                <th>Reference ID</th>
                                                <th>Account ID</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                                <th>Commission</th>
                                                <th>Split Amount</th>
                                                <th>Split At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="data in orderCharges" :key="data.id">
                                            <td>{{ data.id }}</td>
                                            <td>{{ data.order_id }}</td>
                                            <td>{{ data.reference_no }}</td>
                                            <td><span class="badge badge-dark">{{ data.si_charge_status }}</span></td>
                                            <td>{{ data.si_amount }}</td>
                                            <td>{{ data.split_at ? data.commission : '-'}}</td>
                                            <td>{{ data.split_at ? data.split_amount : '-' }}</td>
                                            <td>{{ data.split_at ? data.split_account_id : '-'}}</td>
                                            <td>{{ data.split_at ?? '-'}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- /myTable -->
                        </div>
                        <!-- /.carmyTable -->
                    </div>
                    <!-- ./card -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        orderChargesData:{
            type: Array,
            default: () => []
        },
        from:{
            type: String,
            default: () => ''
        },
        to:{
            type: String,
            default: () => ''
        }
    },
    data(){
        return {
            isLoading: false,
            orderCharges: this.orderChargesData ?? [],
            range: {
                start:this.from,
                end:this.to
            },
            config:{
                masks: {
                    input: 'YYYY-MM-DD',
                },
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD', // Uses 'iso' if missing
                },
            }
        }
    },
    mounted(){
        this.$refs.dt.updateValue(this.range)
    },
    methods:{
        getData(){
                this.isLoading = true;
                axios.get(`/account/reports?from=${this.range.start}&to=${this.range.end}`)
                .then(res => {
                    this.orderCharges = res.data.data.orderCharges ?? [];
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                }).finally(() => this.isLoading = false);
        }
    },
    watch:{
        range(){
           this.getData();
        }
    }
}
</script>

<style scoped>

</style>
