<template>
    <div class="card">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />

        <div class="card-body">
            <div class="row">

                <!-- Admins-->
                <div v-if="isSuperadmin" class="col-sm-12 col-md-4 h-full">
                    <label class="switch-name mb-4">All Admins</label>
                    <multiselect v-model="form.selectedAdmin"
                                 :options="optionsAdmin"
                                 :multiple="false"
                                 :close-on-select="false"
                                 :clear-on-select="false"
                                 :preserve-search="true"
                                 placeholder="Select Admin"
                                 label="name"
                                 track-by="name"
                                 :preselect-first="false"
                                 @select="getCustomers"
                    />
                </div>

                <!-- Customers-->
                <div class="col-sm-12 col-md-4 h-full">
                    <!-- Default switch -->
                    <div class="form-group mb-0" style="display: inline-flex;">
                        <div class="switch-div mr-2">
                            <label class="switch">
                                <input name="booking_button" type="checkbox" v-model="form.all"
                                       :disabled="!optionsCustomers.length">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <label class="switch-name">All Customers</label>
                    </div>
                    <multiselect v-model="form.selectedCustomers"
                                 :options="optionsCustomers"
                                 :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false"
                                 :preserve-search="true"
                                 placeholder="Select Customers"
                                 label="name"
                                 track-by="name"
                                 :preselect-first="false"
                                 :disabled="form.all || !optionsCustomers.length"
                    />
                </div>

                <div class="col-sm-12 col-md-4"></div>

                 <div v-if="isSuperadmin" class="col-sm-12 col-md-4 mt-4">
                     <label for="">Title</label>
                     <input type="text" class="form-control" v-model="form.title" />
                 </div>

                <div class="col-sm-12 mt-4">
                    <label for="">Write</label>
                    <textarea class="form-control" rows="8" v-model="form.text"></textarea>
                </div>

                <div class="col-sm-12 mt-4">
                    <button class="btn btn-success pull-right" @click="send">Send</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "SendNotification",
    components: { Multiselect },
    props: {
        isSuperadmin: {
            type: Boolean,
            default: []
        },
        admins: {
            type: Array,
            default: []
        },
        customers: {
            type: Array,
            default: []
        },
    },
    data(){
        return {
            isLoading: false,
            form: {
                all: false,
                selectedAdmin: [],
                selectedCustomers: [],
                title: '',
                text: '',
            },
            optionsAdmin: this.admins,
            optionsCustomers: this.customers,
        }
    },
    mounted(){

    },
    methods: {
        send(){
            this.isLoading = true;
            axios.post('/account/send/notification', this.form)
                .then(res => {
                    this.$toast.success(`Successfully, Notification has been sent.`);
                    this.form.title = '';
                    this.form.text = '';
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                })
                .finally(() => this.isLoading = false)
        },
        getCustomers(selectedOption, id){
            this.optionsCustomers = [];
            this.form.selectedCustomers = [];
            this.form.all = false;

            this.isLoading = true;
            axios.get(`/account/get/customers/${ selectedOption.id }`)
            .then(res => {
                this.optionsCustomers = res.data.data.customers;
            })
            .catch(err => {
                this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
            })
            .finally(() => this.isLoading = false)
        }
    }
}
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    /*width: 49px !important;*/
    /*height: 23px !important;*/
}
</style>
