<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
    <!-- Listing -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center justify-content-md-end mb-3">
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1 mx-2" data-toggle="modal"
                 data-target="#service-category-modal" @click="reset"><i class="fa fa-plus"></i> Service Categories</a>
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1" data-toggle="modal"
                 data-target="#service-modal" @click="reset"><i class="fa fa-plus"></i> Add New</a>
            </div>
            <div class="table-responsive">
              <table id="myTable" class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Service Category</th>
                  <th>Treatment Type</th>
                  <th>Available For</th>
                  <th>Branches</th>
                  <th>Employees</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="service in allServices" :key="service.id">
                  <td>{{ service.id }}</td>
                  <td>{{ service.name }}</td>
                  <td>
                    <a v-if="service.service_category"
                       href="javascript:void(0);"
                       class="form-control category-color"
                       :style="`position: relative; background: ${service.service_category.color}`">
                    </a>
                    <span :style="`position: relative; top: -10px;`">
                      {{ service.service_category ? service.service_category.name : '' }}
                    </span>
                  </td>
                  <td>{{ service.treatment.type }}</td>
                  <td style="text-transform: capitalize;">{{ service.available_for }}</td>
                  <td><span class="badge badge-info">{{ service.branches.length }}</span></td>
                  <td><span class="badge badge-dark">{{ service.employees.length }}</span></td>
                  <td>
                    <a href="javascript:;" @click="edit(service)" class="btn btn-primary btn-circle edit-row"><i
                        class="icon-pencil"></i></a>
                    <a href="javascript:;" @click="remove(service.id)" class="btn btn-danger btn-circle delete-row"
                       data-toggle="tooltip"><i class="fa fa-times" aria-hidden="true"></i></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create OR Update Modal-->
    <div class="modal fade bs-modal-lg in" id="service-modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-lg-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">{{ form.id ? 'Edit' : 'Create' }} Service</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <!--            Basic info-->
            <div class="row">
              <div class="col-sm-12">
                <h3>Basic Info</h3>
                <small class="text-muted">Add a service name and choose the treatment type.</small>
                <hr>
              </div>
              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Service name</label>
                  <input type="text" class="form-control form-control-lg" v-model="form.name">
                </div>
              </div>

              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Treatment type</label>
                  <select class="form-control form-control-lg" v-model="form.treatment_type">
                    <optgroup v-for="category in categoryTypes"
                              :key="`cat-${category.id}`"
                              v-if="category.category_types.length"
                              :label="category.name">
                      <option v-for="categoryType in category.category_types"
                              :key="`cat-type-${categoryType.id}`"
                              :value="categoryType.id">{{ categoryType.type }}
                      </option>
                    </optgroup>
                  </select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Service available for</label>
                  <select class="form-control form-control-lg" v-model="form.available_for">
                    <option value="everyone">Everyone</option>
                    <option value="females">Females only</option>
                    <option value="males">Males only</option>
                  </select>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Service Category <b>{{ getSelectedCategoryName }}</b></label>
                  <div>
                    <a href="javascript:void(0);"
                       v-for="category in serviceCategories"
                       :key="category.id"
                       :class="`form-control category-color ${+category.id===+form.service_category_id?'active':''}`"
                       :style="`background: ${category.color}`"
                       @click="form.service_category_id=+category.id"></a>
                  </div>
                </div>
              </div>

              <div class="col-md-12 ">
                <label class="w-100">Service description <small
                    class="text-muted pull-right">({{ form.desc.length }}/1000)</small></label>
                <textarea class="form-control form-control-lg " maxlength="1000" autocomplete="off"
                          v-model="form.desc"></textarea>
              </div>

              <div class="col-md-12 mt-4">
                <label class="w-100">Aftercare description <small
                    class="text-muted pull-right">({{ form.after_desc.length }}/1000)</small></label>
                <textarea class="form-control form-control-lg" maxlength="1000" autocomplete="off"
                          v-model="form.after_desc"></textarea>
              </div>
            </div>

            <!-- Branches-->
            <div class="row mt-4">
              <div class="col-sm-12">
                <h3>Branches</h3>
                <small class="text-muted">Choose the location where the service will take place</small>
                <hr>
              </div>
              <div v-for="branch in branches" :key="branch.id" class="col-md-6">
                <div class="card-body py-1">
                  <h5 class="card-title">{{ branch.name }}</h5>
                  <p class="card-text">{{ branch.address }}</p>
                  <div class="switch-div">
                    <label class="switch">
                      <input name="branches[]" type="checkbox" :value="branch.id" v-model="form.branches">
                      <span class="slider round"></span>
                    </label>
                    <label class="switch-label"></label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Home service -->
            <div class="row mt-4">
              <div class="col-sm-12">
                <h3>Home Service</h3>
                <small class="text-muted">Confirm the home service, are you available to provide home service</small>
                <hr>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-0" style="display: inline-flex;">
                  <div class="switch-div mr-2">
                    <label class="switch">
                      <input name="allow_home_service" type="checkbox" v-model="form.allow_home_service">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <label>I would like to provide home service</label></div>
              </div>
            </div>

            <!--  Employees|Team-->
            <div class="row mt-4">
              <div class="col-sm-12">
                <h3>Team</h3>
                <small class="text-muted">Assign team members to the service and manage commission</small>
                <hr>
              </div>

              <div v-for="employee in employees" :key="employee.id"
                   class="col-sm-12 col-md-3 d-flex align-items-center pb-2">
                <img :src="`https://ui-avatars.com/api/?background=random&color=background=random&name=${employee.name}&size=40`"
                     class="rounded mr-2" alt="">
                <b>{{ employee.name }}</b>
                <div class="switch-div ml-2 mt-2">
                  <label class="switch">
                    <input name="employees[]" type="checkbox" :value="employee.id" v-model="form.employees">
                    <span class="slider round"></span>
                  </label>
                  <label class="switch-label"></label>
                </div>
              </div>

            </div>

            <!--Pricing and duration-->
            <div class="row mt-4">
              <div class="col-sm-12">
                <h3>Pricing and Duration</h3>
                <small class="text-muted">Add the pricing options and duration of the service.</small>
                <hr>
              </div>

              <div class="col-sm-12">
                <label>Pricing name</label>
                <input type="text" class="form-control form-control-lg" v-model="form.price_name">
              </div>

              <div class="col-md-3 col-sm-12 mt-2">
                <label>Duration</label>
                <select class="form-control form-control-lg" v-model="form.price_duration">
                  <option value="15">15min</option>
                  <option value="30">30min</option>
                  <option value="45">45min</option>
                  <option value="60">1h</option>
                  <option value="75">1h 15min</option>
                  <option value="90">1h 30min</option>
                  <option value="105">1h 45min</option>
                  <option value="120">2h</option>
                  <option value="135">2h 15min</option>
                  <option value="150">2h 30min</option>
                  <option value="165">2h 45min</option>
                  <option value="180">3h</option>
                  <option value="195">3h 15min</option>
                  <option value="210">3h 30min</option>
                  <option value="225">3h 45min</option>
                  <option value="240">4h</option>
                  <option value="255">4h 15min</option>
                  <option value="270">4h 30min</option>
                  <option value="285">4h 45min</option>
                  <option value="300">5h</option>
                  <option value="315">5h 15min</option>
                  <option value="330">5h 30min</option>
                  <option value="345">5h 45min</option>
                  <option value="360">6h</option>
                  <option value="375">6h 15min</option>
                  <option value="390">6h 30min</option>
                  <option value="405">6h 45min</option>
                  <option value="420">7h</option>
                  <option value="435">7h 15min</option>
                  <option value="450">7h 30min</option>
                  <option value="465">7h 45min</option>
                  <option value="480">8h</option>
                </select>
              </div>

              <div class="col-md-3 col-sm-12 mt-2">
                <label>Price Type</label>
                <select class="form-control form-control-lg" v-model="form.price_type">
                  <option value="free">Free</option>
                  <option value="starts_at">From</option>
                  <option value="fixed">Fixed</option>
                </select>
              </div>

              <div v-if="form.price_type!='free'" class="col-md-3 col-sm-12 mt-2">
                <label>Price <small v-if="form.price_type=='starts_at'" class="text-muted">(From)</small></label>
                <input type="number" class="form-control form-control-lg" placeholder="0.00" v-model="form.price"/>
              </div>

              <div v-if="form.price_type!='free'" class="col-md-3 col-sm-12 mt-2">
                <label>Special price <small class="text-muted">(optional)</small></label>
                <input type="number" class="form-control form-control-lg" placeholder="0.00"v-model="form.special_price"/>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
            <a href="javascript:void(0);" class="btn btn-success" @click="createUpdate()"><i class="fa fa-check"></i>
              {{ form.id ? 'Update' : 'Save' }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <ServiceCategoryModal
        ref="serviceCategories"
    />

  </div>
</template>

<script>
import ServiceCategoryModal from "@components/admin/includes/ServiceCategoryModal";

export default {
  name: "Services",
  components: {ServiceCategoryModal},
  props: {
    services: {
      type: Array,
      default: () => []
    },
    branches: {
      type: Array,
      default: () => []
    },
    categoryTypes: {
      type: Array,
      default: () => []
    },
    employees: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      serviceCategories: [],
      isLoading: false,
      allServices: this.services,
      form: {
        id: '',
        name: '',
        treatment_type: '',
        available_for: 'everyone',
        service_category_id: '',
        allow_home_service: false,
        desc: '',
        after_desc: '',
        branches: [],
        employees: [],
        price_name: 'Basic Price',
        price_duration: '15',
        price_type: 'fixed',
        price: '',
        special_price: ''
      },
    }
  },
  methods: {
    createUpdate(){
      this.isLoading = true;
      axios.post('/account/business-services', this.form)
      .then(res => {
        this.$toast.success(`Successfully, Service has been ${this.form.id ? 'updated' : 'created'}.`);
        this.allServices = res.data.data.services ?? [];
        $('#service-modal').modal('hide');
        this.reset();
        $('.modal-backdrop').remove()
      })
      .catch(err => {
        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
      }).finally(() => this.isLoading = false);
    },
    edit(data){
      this.serviceCategories = this.$refs.serviceCategories.getCategories();
      this.form.id = data.id;
      this.form.name = data.name;
      this.form.treatment_type = data.treatment_type;
      this.form.available_for = data.available_for;
      this.form.service_category_id = data.service_category_id;
      this.form.allow_home_service = data.allow_home_service;
      this.form.desc = data.description;
      this.form.after_desc = data.after_description;
      this.form.price_name = data.price_name;
      this.form.price_duration = data.price_duration;
      this.form.price_type = data.price_type;
      this.form.price = data.price;
      this.form.special_price = data.special_price;
      this.form.branches = data.branches.map(b => b.branch_id);
      this.form.employees = data.employees.map(e => e.employee_id);

      $('#service-modal').modal('show');
    },
    remove(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete Service?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete',
        reverseButtons: true
      }).then((result) => {
        if(result.isConfirmed) {
          this.isLoading = true;
          axios.delete(`/account/business-services/${id}`)
          .then(res => {
            this.$toast.success(`Successfully, Service has been deleted`);
            this.allServices = res.data.data.services ?? [];
          })
          .catch(err => {
            this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
          })
          .finally(() => this.isLoading = false);
        }
      })
    },
    reset(){
      this.form.id = '';
      this.form.name = '';
      this.form.treatment_type = '';
      this.form.available_for = 'everyone';
      this.form.service_category_id = '';
      this.form.allow_home_service = false;
      this.form.desc = '';
      this.form.after_desc = '';
      this.form.branches = [];
      this.form.employees = [];
      this.form.price_name = '';
      this.form.price_duration = '15';
      this.form.price_type = 'fixed';
      this.form.price = '';
      this.form.special_price = '';
      this.serviceCategories = this.$refs.serviceCategories.getCategories();
    },
  },
  computed: {
    getSelectedCategoryName(){
      return this.form.service_category_id && this.serviceCategories ? this.serviceCategories.find(c => +c.id === +this.form.service_category_id).name : '';
    }
  }
}
</script>

<style scoped>

</style>
