<template>
  <div :class="`col-12 w-100 h-100 ${classes}`">

    <div v-if="desc" class="text-center py-3">
      {{ desc }}
    </div>

    <!--    Large View-->
    <div v-if="size === 'lg'"
         :class="`overflow-hidden card card-${size} card-${theme} card-${size}-${theme} ${clickAble?'clickable':''}`"
         @click="clickVoucher">
       <div v-if="purchased" class="origin-top rotate-45 mt-9 mr-9 w-72 text-center text-md absolute -right-[9em] p-1" style="background: rgb(19, 222, 78);box-shadow: 0px 0px 20px 20px rgb(19 222 78);">
         <p class="text-white font-semibold">Paid</p>
      </div>
      <div :class="`card-${size}-body `">
        <div class="col-12 w-full text-center py-2">
          <p class="grid justify-center">
            <svg class="" width="100" height="100" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
              <path d="M35.0564 15.0002L18.4628 7.66508C17.5888 7.27875 16.5636 7.56461 16.0157 8.34745L11.359 15.0002"
                    :stroke="`#${theme === 5 ?'000':'fff'}`" stroke-width="1" stroke-linecap="round"/>
              <path
                  d="M43 15H5C4.44772 15 4 15.4477 4 16V40C4 40.5523 4.44772 41 5 41H43C43.5523 41 44 40.5523 44 40V16C44 15.4477 43.5523 15 43 15Z"
                  fill="none" :stroke="`#${theme === 5 ?'000':'fff'}`" stroke-width="1"/>
              <path d="M19 23L24.1026 33L29 23" :stroke="`#${theme === 5 ?'000':'fff'}`" stroke-width="1" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </p>
          <h4 class="mb-0 text-2xl"><b>{{ name }}</b></h4>
          <span>{{ address }}</span>
        </div>
        <hr>
        <div class="col-12 text-center py-2 text-xl">
          <h4 class="py-2 font-semibold">Retail Value</h4>
          <h5>{{ moneyFormat(retailPrice) }}</h5>
        </div>
        <hr>
        <div class="col-12 text-center py-2 text-xl">
          <h4 class="py-2 font-semibold">Voucher Value</h4>
          <h5>{{ moneyFormat(value) }}</h5>
        </div>
        <hr>
        <div class="col-12 text-center py-2 text-xl">
          <h4 class="py-2 font-semibold">Voucher Code</h4>
          <h2 class="tracking-[0.3em] font-bold text-2xl text-amber-400">{{getOrder ? getOrder.code :'XXXXXX'}}</h2>
          <a v-if="bookingButton" href="javascript:void(0);" class="btn btn-default btn-lg w-50 mt-1">Book now</a>
        </div>
        <hr v-if="!bookingButton">
        <div class="col-12 text-center py-2">
          <span>Redeem on {{ selectAll ? 'all' : servicesCount }} Services</span><br>
          <span>{{ getValidFor }}</span><br>
          <span>For multiple-use until redeemed</span>
        </div>
      </div>
    </div>

    <!--    Small View-->
    <div v-else :class="`overflow-hidden card card-${size} card-${theme} card-${size}-${theme} ${clickAble?'clickable':''} `" @click="clickVoucher">
       <div v-if="purchased" class="origin-top rotate-45 mt-9 mr-9 w-72 text-center text-md absolute -right-[9em] p-1" style="background: rgb(19, 222, 78);box-shadow: 0px 0px 3px 3px rgb(19 222 78);">
         <p class="text-white font-semibold">Paid</p>
      </div>
      <div :class="`card-${size}-body d-flex px-3`">
        <div class="col-md-2">
          <svg width="80" height="80" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
            <path d="M35.0564 15.0002L18.4628 7.66508C17.5888 7.27875 16.5636 7.56461 16.0157 8.34745L11.359 15.0002"
                  :stroke="`#${theme === 5 ?'000':'fff'}`" stroke-width="1" stroke-linecap="round"/>
            <path
                d="M43 15H5C4.44772 15 4 15.4477 4 16V40C4 40.5523 4.44772 41 5 41H43C43.5523 41 44 40.5523 44 40V16C44 15.4477 43.5523 15 43 15Z"
                fill="none" :stroke="`#${theme === 5 ?'000':'fff'}`" stroke-width="1"/>
            <path d="M19 23L24.1026 33L29 23" :stroke="`#${theme === 5 ?'000':'fff'}`" stroke-width="1" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="col-md-10 ml-4 text-center voucher-sm-detail">
          <span class="font-semibold text-xl">Voucher Value</span>
          <h5 class="font-normal text-lg">{{ moneyFormat(value) }}</h5>
          <div class="grid gap-1 md:grid-cols-2 sm:grid-cols-1">
            <div class="md:text-left md:ml-4 sm:mt-6">{{ title }}</div>
            <div class="md:text-right md:mr-4 sm:mt-6">{{ moneyFormat(retailPrice) }}</div>
            <div class="md:text-left md:ml-4">{{ getValidFor }}</div>
            <div class="md:text-right md:mr-4"></div>

            <div class="text-left md:ml-4 ">Redeem on {{ selectAll ? 'all' : servicesCount }} Services</div>
            <div class="text-right md:mr-4"> <i v-if="value !== retailPrice" :class="`badge badge-dark badge-${theme}`">Save {{ percentOf(value, retailPrice) }}%</i></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="enableClientNote && clientNote" class="text-center mt-4">
      <h5 class="font-semibold">Notes from {{name}}</h5>
      <p>{{ clientNote }}</p>
    </div>

  </div>
</template>

<script>
import helperMixin from '@mixins/helperMixin';

export default {
  name: "VoucherCard",
  mixins: [helperMixin],
  props: {
    name: {
      type: String,
      default: 'Glamour Salaon'
    },
    address: {
      type: String,
      default: 'Your location will appear here'
    },
    classes: {
      type: String,
      default: ''
    },
    theme: {
      type: [Number, String],
      default: 1
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    clickAble: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    title: {
      type: String,
      default: ''
    },
    validFor: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0
    },
    retailPrice: {
      type: [Number, String],
      default: 0
    },
    desc: {
      type: String,
      default: ''
    },
    bookingButton: {
      type: Boolean,
      default: true
    },
    voucherId: {
      type: Number,
      default: 0
    },
    selectAll: {
      type: Boolean,
      default: true
    },
    servicesCount: {
      type: Number,
      default: 0
    },
    enableClientNote: {
      type: Boolean,
      default: false
    },
    clientNote: {
      type: String,
      default: ''
    },
    purchased: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    percentOf(value, retailPrice){
      return Math.abs(Math.round((retailPrice / value) * 100 - 100));
    },
    clickVoucher(){
      if(this.clickAble) this.$emit('click', this.voucherId);
    }
  },
  computed: {
    getValidFor(){
      return this.validFor === 'never' ? 'No Expiry Date' : 'Valid for ' + this.validFor.replaceAll('_', ' ');
    },
    getOrder(){
      return this.order;
    }
  }
}
</script>

<style scoped>
/*
* Gradient Site
* https://cssgradient.io/
* @Author Khuram Qadeer.
**/
p {
  color: black;
}

.card {
  /*max-width: 700px;*/
  color: white;
  border: none;
  background: linear-gradient(0deg,#8b60ed,#b372bd);
  /*box-shadow: 0px 0px 35px 0px rgb(71 28 98);*/
  border-radius: 5px;
}

.badge {
  background-color: white;
  color: #8b60ed;
  font-size: 16px
}

.card-1 {
  background: linear-gradient(0deg,#f19a1a,#ffc73c);
  /*box-shadow: 0px 0px 35px 0px rgba(0,0,0,1);*/
}

.badge-1 {
  color: #f19a1a;
}

.card-2 {
  background: linear-gradient(180deg,#f44881,#ec454f);
  /*box-shadow: 0px 0px 35px 0px rgba(99,102,55,1);*/
}

.badge-2 {
  color: #f44881;
}

.card-3 {
  background: linear-gradient(180deg,#6edcc4,#1aab8b);
  /*box-shadow: 0px 0px 35px 0px rgba(163,92,187,1);*/
}

.badge-3 {
  color: #6edcc4;
}

.card-4 {
  background: linear-gradient(351deg, #3f5efb,#5cd9d8);
  /*box-shadow: 0px 0px 35px 0px rgba(63,94,251,1);*/
}
/*for print view */
.card-5 {
  background: white;
  color: black;
  border: 2px dotted;
}

.badge-4 {
  color: #3f5efb;
}

hr {
  border-color: rgb(226 227 225 / 30%);
  margin: 0;
}

.card-lg-body {
  padding: 1rem;
}

.card-sm-body {
  padding: 2rem 0rem 2rem 0rem;
}

.voucher-sm-detail p {
  padding: 0 2rem;
}

.voucher-sm-detail p.first {
  font-size: 18px !important;
}

.clickable {
  cursor: pointer;
}

.modal-open .modal {
  z-index: 99999;
}
</style>
