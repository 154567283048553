/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Vue from 'vue';

window.Vue = require('vue');

import 'vue-multiselect/dist/vue-multiselect.min.css'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(ToastPlugin, {
    position: 'top-right'
});

// Import stylesheet
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

import 'animate.css';

import StarRating from 'vue-star-rating';

import Meta from "vue-meta";

Vue.use(Meta);

import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * @Description Global Components
 * @Author Khuram Qadeer.
 */
Vue.component('loading', Loading);
Vue.component('StarRating', StarRating);
Vue.component('voucher-card', require('@components/includes/VoucherCard').default);
Vue.component('membership-card', require('@components/includes/MembershipCard').default);
Vue.component('chat', require('@components/conversation/Chat').default);
Vue.component('offer-card', require('@components/includes/OfferCard').default);
Vue.component('advertising-card', require('@components/includes/AdvertisingCard').default);

/**
 * @Description Front Components
 */
Vue.component('home', require('@components/front/Home').default);
Vue.component('search-modal', require('@components/front/SearchModal').default);
Vue.component('branch-show', require('@components/front/BranchShow').default);
Vue.component('book', require('@components/front/Book').default);
Vue.component('buyVoucherOrMembership', require('@components/front/BuyVoucherOrMembership').default);
Vue.component('search-service-category', require('@components/front/SearchServiceCategory').default);
Vue.component('products-show', require('@components/front/ProductsShow').default);

/**
 * @Description Admin Components
 */
Vue.component('branches', require('@components/admin/branches/Index').default);
Vue.component('employees', require('@components/admin/employees/Employees').default);
Vue.component('employee-orders', require('@components/admin/employees/EmployeeOrders').default);
Vue.component('services', require('@components/admin/services/Index').default);
Vue.component('vouchers', require('@components/admin/vouchers/Index').default);
Vue.component('memberships', require('@components/admin/memberships/Index').default);
Vue.component('booking-calendar', require('@components/admin/booking/Calendar').default);
Vue.component('admin-dashboard', require('@components/admin/dashboard/Dashboard').default);
Vue.component('admin-sms-packages', require('@components/admin/sms-packages/Index').default);
Vue.component('admin-send-sms', require('@components/admin/sms-packages/SendSMS').default);
Vue.component('admin-advertisement-packages', require('@components/admin/advertisement_packages/Index').default);
Vue.component('admin-show-order', require('@components/admin/orders/ShowOrder').default);
Vue.component('offers', require('@components/admin/offers/Index').default);
Vue.component('transactions', require('@components/admin/reports/Transactions').default);
Vue.component('admin-send-notification', require('@components/admin/send-notification/SendNotification').default);

/**
 * @Description Super Admin Components
 */
Vue.component('superadmin-company-show', require('@components/superadmin/company/CompanyShow').default);
Vue.component('superadmin-dashboard', require('@components/superadmin/dashboard/Dashboard').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.filter('strLimit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

// Vue.config.productionTip = false;
window.appName = 'MyBarber';

const app = new Vue({
    el: '#app',
});

const searchApp = new Vue({
    el: '#search-app',
});
