<template>
    <div id="frame">

        <!--back page-->
        <a href="javascript:void(0);" class="btn btn-back" @click="goToBack">
            <i class="fa fa-arrow-left"></i>
        </a>

        <Sidepanel
            @change="changeConversation"
        />

        <div class="content">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="false"
                     loader="dots"
                     color="#366865"
                     background-color="#a3afeb66"
            />

            <Topbar/>

            <Messages/>

            <div class="message-input">
                <span v-if="formMessage.file" class="text-file">{{ formMessage.file?.name }}</span>
                <div class="wrap">
                    <input type="textarea" placeholder="Write your message..." @keypress.enter.prevent="send()" v-model="formMessage.text"/>
                    <a href="javascript:void(0);" @click="$refs.attachment.click()"><i class="fa fa-paperclip attachment" aria-hidden="true"></i></a>
                    <input type="file" accept="image/*" class="form-control form-control-lg" ref="attachment" @change="onFileChange" hidden>
                    <button type="button" @click="send">
                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidepanel from "@components/conversation/partials/Sidepanel";
import Topbar from "@components/conversation/partials/Topbar";
import Messages from "@components/conversation/partials/Messages";

export default {
    name: "Chat",
    components: {Topbar, Sidepanel, Messages},
    props: {
        authUser: {
            required: true,
            type: Object,
        },
        allConversations: {
            required: true,
            type: [Array, Object],
        },
    },
    data() {
        return {
            isLoading: false,
            conversations: this.allConversations,
            keyword: '',
            activeConversation: {},
            messages: [],
            formMessage: {
                file: '',
                text: '',
            }
        }
    },
    mounted() {
        this.subscribeAsPrivateChannel();
        setTimeout(() => {
            this.activeConversation = this.getConversations[0] ?? {};
            this.getMessages(this.activeConversation.id);
        }, 1000)
    },
    methods: {
        subscribeAsPrivateChannel() {
            Echo.private(`App.User.${this.authUser.id}`)
                .listen('NewMessage', (e) => {
                    this.handleNewMessage(e.message);
                })
                .error((error) => {
                    console.error(error);
                });
        },
        handleNewMessage(message) {
            // new message related to current active conversation
            if (+message.conversation_id === +this.activeConversation.id) {
                this.messages.push(message);
                this.activeConversation.last_message = message;
            } else {
                this.getConversations.map(c => {
                    if (+c.id === +message.conversation_id) {
                        c.unread++;
                        c.last_message = message;
                    }
                })
            }
            this.scrollToBottom();
        },
        scrollToBottom() {
            for (let i = 1; i < 20; i++) {
                setTimeout(() => {
                    let container = this.$el.querySelector("#messages");
                    container.scrollTop = container.scrollHeight;
                }, (10 * i));
            }
        },
        onFileChange(e) {
            this.formMessage.file = e.target.files[0];
        },
        send() {
            let formData = new FormData();
            formData.append('conversation_id', this.activeConversation?.id);
            formData.append('to', this.getOtherUser(this.activeConversation)?.id);
            formData.append('text', this.formMessage.text);
            formData.append('file', this.formMessage.file);

            this.isLoading = true;
            axios.post('/conversation/send/message', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    this.handleNewMessage(res.data.data.message);
                    this.formMessage.conversation_id = '';
                    this.formMessage.to = '';
                    this.formMessage.file = '';
                    this.formMessage.text = '';
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                }).finally(() => this.isLoading = false);
        },
        changeConversation(conversation) {
            this.activeConversation = conversation;
            this.getMessages(+this.activeConversation.id);
        },
        goToBack() {
            return window.history.back();
        },
        getOtherUser(data) {
            return +this.authUser.id === +data.from ? data.to_user : data.from_user;
        },
        getMessages(conversationId) {
            let _this = this;
            let form = {
                conversation_id: conversationId,
                last_id: ''
            }
            this.isLoading = true;
            axios.post('/conversation/get/messages', form)
                .then(res => {
                    this.messages = res.data.data.messages;
                    this.scrollToBottom();
                    this.isLoading = false;
                    this.setSeen(+conversationId);
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                    this.isLoading = false;
                });
        },
        async setSeen(conversationId) {
            await axios.get(`/conversation/make/messages/seen/${conversationId}`);
            await this.setZeroInConversation(conversationId);
        },
        setZeroInConversation(conversationId) {
            this.getConversations.find(c => +c.id === +conversationId).unread = 0;
        },
        viewImg(src) {
            if (src) {
                $('#img-viewer-modal img').attr('src', src);
                $('#img-viewer-modal').modal('show');
            }
        },

    },
    computed: {
        getConversations() {
            // Searching
            if (this.keyword) {
                return this.conversations.filter(con => {
                    return this.getOtherUser(con).name.toLowerCase().includes(this.keyword.toLowerCase());
                })
            }
            // Sorting by unread messages
            return _.sortBy(this.conversations, [(conversation) => {
                return conversation.unread;
            }]).reverse();
        }
    },
}
</script>

<style scoped>

</style>
