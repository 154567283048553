<template>
  <section class="container">
    <div class="row">
      <div class="col-md-12 px-0">
        <carousel :per-page="1" pagination-active-color="#366865" :pagination-padding="4" id="img-slider">
          <template v-if="branch.images">
            <slide v-for="image in branch.images" :key="`img-main-${image.path}`"><img :src="image.path" alt=""></slide>
          </template>
          <slide v-else><img src="/images/default_barber.png" alt=""></slide>
        </carousel>
      </div>

      <div class="col-md-12 py-1 mt-4">
        <h1 class="branch-title">
          <b>{{ branch.name }}</b>
          <small class="text-muted">
              {{ branch.category.name === 'Other' ? branch.custom_main_category : branch.category.name }}
          </small>
        </h1>

          <StarRating v-if="branch.rating"
                      :inline="true"
                      :star-size="22"
                      :rating="branch.rating"
                      :increment="0.1"
                      :show-rating="false"
                      :read-only="true"
                      active-color="#366865"
                      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
          />
          <span v-if="branch.rating" class="badge badge-success mt-1 text-sm">{{ branch.rating }}</span>
        <span><i class="fa fa-pin"></i> {{ branch.address }}</span>
        <p class="mt-2 text-capitalize available_for">
          <svg v-if="branch.available_for === 'male_only'" width="20" height="20" viewBox="0 0 256 256">
            <path fill="currentColor"
                  d="M216 32h-48a8 8 0 0 0 0 16h28.7l-42.1 42.1a80 80 0 1 0 11.3 11.3L208 59.3V88a8 8 0 0 0 16 0V40a8 8 0 0 0-8-8Zm-66.7 165.3a64 64 0 1 1 0-90.6a64.3 64.3 0 0 1 0 90.6Z" />
          </svg>
          <svg v-else-if="branch.available_for === 'female_only'" width="20" height="20" viewBox="0 0 256 256">
            <path fill="currentColor"
                  d="M208 96a80 80 0 1 0-88 79.6V200H88a8 8 0 0 0 0 16h32v24a8 8 0 0 0 16 0v-24h32a8 8 0 0 0 0-16h-32v-24.4A80.1 80.1 0 0 0 208 96ZM64 96a64 64 0 1 1 64 64a64.1 64.1 0 0 1-64-64Z" />
          </svg>
          <svg v-else width="20" height="20" viewBox="0 0 32 32">
            <path fill="currentColor"
                  d="M22 3v2h3.563l-3.375 3.406A6.962 6.962 0 0 0 18 7c-1.87 0-3.616.74-4.938 2.063a6.94 6.94 0 0 0 .001 9.875c.87.87 1.906 1.495 3.062 1.812c.114-.087.242-.178.344-.28a3.45 3.45 0 0 0 .874-1.532a4.906 4.906 0 0 1-2.875-1.407C13.524 16.588 13 15.336 13 14s.525-2.586 1.47-3.53C15.412 9.523 16.664 9 18 9s2.587.525 3.53 1.47A4.956 4.956 0 0 1 23 14c0 .865-.245 1.67-.656 2.406c.096.516.156 1.058.156 1.594c0 .498-.042.99-.125 1.47c.2-.163.378-.348.563-.532C24.26 17.614 25 15.87 25 14c0-1.53-.504-2.984-1.406-4.188L27 6.438V10h2V3h-7zm-6.125 8.25c-.114.087-.242.178-.344.28c-.432.434-.714.96-.874 1.533c1.09.14 2.085.616 2.875 1.406c.945.943 1.47 2.195 1.47 3.53s-.525 2.586-1.47 3.53C16.588 22.477 15.336 23 14 23s-2.587-.525-3.53-1.47A4.948 4.948 0 0 1 9 18c0-.865.245-1.67.656-2.406A8.789 8.789 0 0 1 9.5 14c0-.498.042-.99.125-1.47c-.2.163-.377.348-.563.533C7.742 14.384 7 16.13 7 18c0 1.53.504 2.984 1.406 4.188L6.72 23.875l-2-2l-1.44 1.406l2 2l-2 2l1.44 1.44l2-2l2 2l1.405-1.44l-2-2l1.688-1.686A6.932 6.932 0 0 0 14 25c1.87 0 3.616-.74 4.938-2.063C20.26 21.616 21 19.87 21 18s-.74-3.614-2.063-4.938c-.87-.87-1.906-1.495-3.062-1.812z" />
          </svg>
          {{ branch.available_for.replaceAll('_', ' ') }}
        </p>
      </div>

      <div class="col-md-8 col-sm-12">
        <h4>About {{ branch.name }}</h4>
        <p>{{ branch.desc }}</p>
      </div>

        <!-- Time Schedule -->
      <div class="col-md-4 col-sm-12 mt-4">
        <ul>
          <li v-for="(schedule,i) in branch.schedule" :key="`schedule-${i}`">
            <b>{{ schedule.day }}</b><br>
            <template v-if="+schedule.on">
              <span v-for="(time,j) in schedule.timing" :key="`time-${j}`">
                Open {{ time.start }} - {{ time.end }} Close<br>
              </span>
            </template>
            <span v-else>OFF</span>
          </li>
        </ul>

          <div v-if="branch.allow_home_service" class="alert alert-success text-center mt-4">Available for home service</div>

          <div class="col-12" data-toggle="tooltip"
             title="Login as customer account">
            <a v-if="branch.services.length"
               :href="isCustomer ? `/booking/${branch.slug}` : '#'"
               class="btn btn-primary book-now mt-4 w-full">Let's Book</a>
        </div>
      </div>
    </div>

      <!-- Services -->
    <div class="row">
      <div v-if="branch.services.length" class="row mt-2 ml-0" id="services">
        <div class="col-md-12 py-4 px-0">
          <h1 class="section-title">Services</h1>
        </div>

        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a v-for="(data,i) in getServices" :key="`tab-${i}`" :class="`nav-link ${i===0?'active':''}`"
             :id="`v-pills-${i}-tab`" data-toggle="pill"
             :href="`#v-pills-${i}`" role="tab"
             :aria-controls="`v-pills-${i}`" :aria-selected="i===0">
            {{ data[0].service.service_category.name }}
            <span class="badge badge-dark ml-2">{{ data.length }}</span>
          </a>
        </div>

        <div class="tab-content" id="v-pills-tabContent">
          <div v-for="(data,i) in getServices" :key="`tab-content-${i}`" :class="`tab-pane fade ${i===0?'show active':''}`"
               :id="`v-pills-${i}`" role="tabpanel" :aria-labelledby="`v-pills-${i}-tab`">
            <ul class="list-group list-group-flush">
              <li v-for="(serviceData,j) in data" :key="`tab-content-services-${j}`"
                  class="list-group-item bg-transparent">
                    <a :href="isCustomer?`/booking/${branch.slug}/${serviceData.service.id}`: 'javascript:void(0);'">
                        <b>{{serviceData.service.name }}</b></a>
                <p class="text-slate-900">{{ serviceData.service.description | strLimit(50) }}</p>
                <p class="text-muted">{{ timeConvert(serviceData.service.price_duration) }}, <span
                        class="currency">{{ moneyFormat(serviceData.service.price) }}</span></p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Offers-->
      <template v-if="offers.length">
        <div class="col-md-12 py-4 mt-2">
          <h1 class="section-title">Offers</h1>
        </div>
        <div class="col-md-12 px-0">
          <carousel :perPageCustom="[[480, 1],[768, 2], [1024, 3]]" :navigationEnabled="true"
                    :paginationEnabled="false">
            <slide v-for="offer in offers" :key="`offer-${offer.id}`">
              <offer-card
                      :offer-id="offer.id"
                      :name="offer.name"
                      :code="offer.code"
                      :discount.sync="+offer.discount"
                      :valid-for.sync="offer.valid_for"
                      :select-all.sync="!!offer.all_services"
                      :services-count.sync="+offer.services.length"
                      :image.sync="offer.image"
                      size="sm"
                      :can-edit="true"
                      :click-able="true"
                      @click="clickedOffer"
              />
            </slide>
          </carousel>
        </div>
      </template>

      <!-- Vouchers-->
<!--      <template v-if="vouchers.length">-->
<!--        <div class="col-md-12 py-4 mt-2">-->
<!--          <h1 class="section-title">Vouchers</h1>-->
<!--        </div>-->
<!--        <div class="col-md-12 px-0">-->
<!--          <carousel :perPageCustom="[[480, 1],[768, 2], [1024, 3]]" :navigationEnabled="true"-->
<!--                    :paginationEnabled="false">-->
<!--            <slide v-for="voucher in vouchers" :key="`voucher-${voucher.id}`">-->
<!--              <voucher-card-->
<!--                      :purchased="alreadyPurchased('voucher',voucher.id)"-->
<!--                      :voucher-id="voucher.id"-->
<!--                      :theme="voucher.theme"-->
<!--                      size="sm"-->
<!--                      :click-able="true"-->
<!--                      :title="voucher.title"-->
<!--                      :value="+voucher.value"-->
<!--                      :retail-price="+voucher.retail_price"-->
<!--                      :valid-for="voucher.valid_for"-->
<!--                      :select-all="!!voucher.all_services"-->
<!--                      :services-count="+voucher.services.length"-->
<!--                      classes="pl-0"-->
<!--                      @click="clickedVoucher"-->
<!--              />-->
<!--            </slide>-->
<!--          </carousel>-->
<!--        </div>-->
<!--      </template>-->

      <!-- Memberships-->
      <template v-if="memberships.length">
        <div class="col-md-12 py-4 ">
          <h1 class="section-title">Memberships</h1>
        </div>
        <div class="col-md-12 px-0">
          <carousel :perPageCustom="[[480, 1],[768, 2], [1024, 3]]" :navigationEnabled="true"
                    :paginationEnabled="false">
            <slide v-for="membership in memberships" :key="`membership-${membership.id}`">
              <membership-card
                      :purchased="alreadyPurchased('membership',membership.id)"
                      :membership-id="membership.id"
                      :theme="membership.theme"
                      size="sm"
                      :click-able="true"
                      :title="membership.name"
                      :price="+membership.price"
                      :valid-for="membership.valid_for"
                      :select-all="!!membership.all_services"
                      :services-count="+membership.services.length"
                      :sessions.sync="membership.sessions"
                      :no-of-session.sync="+membership.no_of_session"
                      classes="pl-0"
                      @click="clickedMembership"
              />
            </slide>
          </carousel>
        </div>
      </template>
    </div>

    <!-- Products-->
    <div v-if="products.length">
        <div class="col-md-12 py-4 pl-0">
          <h1 class="section-title">Products</h1>
        </div>
        <div class="products">
          <div v-for="product in products" :key="`p-${product.id}`" class="item">
            <div class="first-half">
              <div class="mdc-card__primary-action media-image top-card mdc-elevation--z8 rounded-sm shadow-xl"
                   :style="`background-image: url(${product.image})`"
                   @click="viewImg(`${product.image}`)">
                <div class="mdc-card__media mdc-card__media--square">
                  <div class="mdc-card__media-content"></div>
                </div>
              </div>
            </div>
            <div class="last-half">
              <div class="card-info">
                <h2><a href="javascript:void(0);">{{ product.name }}</a></h2>
                <h4>{{ moneyFormat(product.price) }}</h4>
                <h6>{{ product.description | strLimit(30) }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 py-4 text-center">
            <a v-if="isCustomer" :href="`/show/products/${branch.slug}`" class="btn btn-primary book-now">Let's Buy Products</a>
        </div>
      </div>

    <!-- Booking with Staff-->
    <div class="row">
      <div class="col-12">
        <div class="col-md-12 py-4 pl-0">
          <h1 class="section-title">Book with staff</h1>
        </div>
        <carousel :perPageCustom="[[480, 2],[768, 3], [1024, 5]]" pagination-active-color="#366865"
                  :pagination-padding="1">
          <slide v-for="employee in employees" :key="`e-dp-${employee.id}`">
            <a :href="isCustomer ? `/booking/${branch.slug}/0/${employee.id}`: 'javascript:void(0);'" class="col-sm px-1">
              <div class="mini-card bg-ligth">
                <div class="mini-card-icon">
                  <img src="https://besnappy.com/images/customers/dribbble@2x.jpg" alt="">
                </div>
                <div class="mini-card-content">
                  <span class="mini-card-nb text-capitalize">{{ employee.name }}</span>
                  <small class="ml-1 text-uppercase text-muted">{{ employee.title }}</small>
                  <StarRating
                          :text-class="`ml-1 text-muted`"
                          :star-size="13"
                          :read-only="true"
                          :rating="3.3"
                          :show-rating="true"
                          active-color="#366865"
                  />
                </div>
              </div>
            </a>
          </slide>
        </carousel>
      </div>
    </div>

    <!-- Map -->
    <div class="row">
      <div class="col-md-12 mt-4">
        <MapWithPin :lat="branch.lat" :lng="branch.lng" />
      </div>
    </div>

    <!-- Reviews-->
    <Reviews :reviews="reviews" />

      <!-- <div class="fixed top-72 left-1 w-20 bg-transparent"> -->
      <!--  <a href="" class="px-3 py-2 rounded-md bg-indigo-500 hover:bg-indigo-700 text-gray-50">Book</a> -->
      <!-- </div> -->

  </section>
</template>

<script>
import Reviews from '@components/includes/Reviews';
import MapWithPin from '@components/includes/MapWithPin';
import helperMixin from '@mixins/helperMixin';

export default {
    name: "BranchShow",
    components: { Reviews, MapWithPin },
    mixins: [helperMixin],
    props: {
        authUser: {
            type: Object,
            default: () => ({})
        },
        isCustomer: {
            type: Boolean,
            default: () => false
        },
        branch: {
            type: Object,
            default: () => ({})
        },
        vouchers: {
            type: Array,
            default: () => []
        },
        memberships: {
            type: Array,
            default: () => []
        },
        offers: {
            type: Array,
            default: () => []
        },
        userVouchers: {
            type: Array,
            default: () => []
        },
        userMemberships: {
            type: Array,
            default: () => []
        },
        products: {
            type: Array,
            default: () => []
        },
        employees: {
            type: Array,
            default: () => []
        },
        reviews: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            services: []
        }
    },
    mounted(){
        this.services = _.groupBy(this.branch.services, s => s.service.service_category_id);
        setTimeout(() => {
            $('.nav.flex-column.nav-pills a:first').click();
        }, 1000);
    },
    methods: {
        timeConvert(n){
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if(num > 60)
                return rhours + "h " + rminutes + "min";
            else
                return num + " min";
        },
        redirectTo(url){
            window.location.href = url;
        },
        clickedVoucher(voucherId){
            if(this.isCustomer){
                if(this.alreadyPurchased('voucher', voucherId)) {
                    window.location.href = `/booking/${ this.branch.slug }?vid=${ voucherId }`;
                } else window.location.href = `/voucher/booking/${ this.branch.slug }/${ voucherId }`;
            }
        },
        clickedOffer(offerId){
            if(this.isCustomer){
                window.location.href = `/booking/${ this.branch.slug }/0/0/${ offerId }`;
            }
        },
        clickedMembership(membershipId){
            if(this.isCustomer){
                if(this.alreadyPurchased('membership', membershipId)) {
                    window.location.href = `/booking/${ this.branch.slug }?mid=${ membershipId }`;
                } else window.location.href = `/membership/booking/${ this.branch.slug }/${ membershipId }`;
            }
        },
        viewImg(src){
            if(src) {
                $('#img-viewer-modal img').attr('src', src);
                $('#img-viewer-modal').modal('show');
            }
        },
        alreadyPurchased(type, id){
            let res = false;
            if(this.authUser) {
                if(type === 'voucher') {
                    res = this.userVouchers.some(v => +v.voucher_id === +id);
                } else if(type === 'membership') {
                    res = this.userMemberships.some(m => +m.membership_id === +id);
                }
            }
            return res;
        }
    },
    computed: {
        getServices(){
          return  _.groupBy(this.branch.services, s => s.service.service_category_id);
        }
    }
}
</script>

<style>
#img-slider .VueCarousel-dot {
    width: 50px !important;
    height: 5px !important;
    background-color: #c9d1d1 !important;
    border-radius: 1px !important;
}
#img-slider .VueCarousel-dot.VueCarousel-dot--active {
    width: 50px !important;
    height: 5px !important;
    background-color: rgb(54, 104, 101) !important;
    border-radius: 1px !important;
}
#img-slider .VueCarousel-wrapper{
    box-shadow: 2px 3px 20px 0px gray;
}
</style>
<style lang="scss" scoped>
#services a {
    color: black;
}

#services a:hover {
    color: #366865;
}

.nav-pills .nav-link {
    font-size: 13px;
    font-weight: bolder;
    padding: 12px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #366865;
    background-color: transparent;
    border-left: 3px solid #366865;
    border-radius: 0px;
    padding: 7px;
    font-size: 18px;
}

div#v-pills-tabContent {
    padding-left: 30px;
}

#img-slider img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
@media screen and (min-width: 1300px) {
    #img-slider img {
        height: 35vw;
    }
}
ul.list-group {
    max-height: 600px;
    overflow: scroll;
}

.switch {
    width: 50px;
    height: 25px;
}

.switch .slider:before {
    width: 17px;
    height: 17px;
}

.currency {
    color: #366865;
    font-weight: bolder;
    //font-size: 18px;
}

.book-now {
    color: white;
    width: 300px;
    margin-left: 20px;
}

.book-now:hover {
    color: white;
    box-shadow: 0px 0px 20px 8px #366865;
}

.products {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: flex-start;
}
.products .item{
    flex: 0 0 350px;
}

.available_for svg {
    position: relative;
    top: 3px;
}

.mini-card {
    cursor: pointer;
    padding: 1px !important;
    min-height: 6em;
    border-radius: 10px;
    //border-bottom: 2px solid gray;

    &:hover {
        transition: all .2s;
        background: var(--dark-secondary-color);
        //border-bottom: 1px solid var(--primary-color);
    }

    & img {
        width: 90px !important;
        height: 75px !important;
        object-fit: cover !important;
    }

    & .vue-star-rating {
        margin-left: 1em;
    }

    .mini-card-icon {
        background-color: transparent;
        // border: 1px solid  $primary;
        border-radius: 5px 0px 0px 5px;
        display: block;
        width: 95px;
        height: 98px;
        float: left;
        // border-right: 1px solid  $gray-300;
    }

    .mini-card-content {
        margin: 7px 0 0 0;
        //text-align: center;

        .mini-card-nb {
            font-size: 15px;
            display: block;
            //margin: 0 1rem;
            color: var(--primary-color);
            font-weight: bolder;
        }
    }
}

.mini-card i > svg {
    width: 100%;
    // border-right: 1px solid  $gray-300;

    path {
        // fill: $primary;
    }
}
</style>
