<template>
    <a v-if="authUser?.card_si_ref_no && authUser?.si_status" class="credit-card-panel">
        <div class="card card--front">
            <label class="w-100">Card Holder
                <span class="fa fa-check-circle" style="color: lawngreen;"></span>
            </label>
            <div class="card__info">{{ authUser.name }}</div>
            <label>Card Number</label>
            <div class="card__info">{{ authUser?.si_status.si_card_prefix.slice(0,4) }} **** **** {{ authUser?.si_status.si_card_suffix.slice(0,4) }}</div>
            <label>Expire</label>
            <div class="card__info">{{ authUser?.si_status.si_card_expiry.slice(0,2)}}/{{ authUser?.si_status.si_card_expiry.slice(2,4)}}</div>

            <a v-if="showButton" href="javascript:void(0);" class="btn btn-light mt-2"
               @click="$emit('click',$event)">Continue & Add New Credit Card</a>
        </div>
    </a>
</template>

<script>
export default {
    name: "CreditCardView",
    props: {
        authUser: {
            type: [Object],
            default: () => {}
        },
        showButton:{
            type: [Boolean],
            default: false
        }
    }

}
</script>

<style scoped>
.card.card--front {
    margin: 1rem 0;
    padding: 7px;
    color: white;
    background: -webkit-linear-gradient(107deg, #306ee2, #6653b7 53%);
    text-transform: capitalize;
}

.card.card--front label {
    color: lightgrey;
    font-size: 12px;
    margin: 0;
}

.card.card--front .card__info {
    font-weight: bolder;
    font-family: monospace;
    font-size: 20px;
}
</style>
