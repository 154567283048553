<template>
  <div class="col-sm-12 px-0">
    <div class="form-group">
      <label for="name">Search Location<span
          class="required-span"></span> </label>
      <input id="location_search" type="text" placeholder="Search Location" class="form-control form-control-lg">
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data(){
    return {
      address: '',
      lat: '',
      lng: '',
      marker: '',
      map: '',
    }
  },
  mounted(){
    let self = this;
    setTimeout(() => {
      let input = document.getElementById('location_search');
      let options = {
        // type: ['bus_station''],
        // types: ['(regions)'],
        // types: ['address'],
        // types: ['establishment'],
        // types: ['geocode'],
        // types: ['(cities)'],
        componentRestrictions: {country: 'ae'},
      };
      self.map = new window.google.maps.Map(
          document.getElementById("map"),
          {
            center: {lat: 25.1972, lng: 55.2744},
            zoom: 16,
            mapTypeControl: false,
            draggable: true,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          });

      self.marker = new window.google.maps.Marker({
        position: {lat: 25.1972, lng: 55.2744},
        map: self.map,
        animation: google.maps.Animation.DROP,
        icon: '/images/pin.webp',
        draggable: true,
      });

      window.google.maps.event.addListener(self.marker, "dragend", function(){
        self.updateMap(self.marker.getPosition().lat(), self.marker.getPosition().lng());
      });

      let autocomplete = new window.google.maps.places.Autocomplete(input, options);
      window.google.maps.event.addListener(autocomplete, 'place_changed', function(){
        const place = autocomplete.getPlace();
        if(!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        self.updateMap(place.geometry.location.lat(), place.geometry.location.lng(), $('#location_search').val());
      });
    }, 1000);
  },
  methods: {
    updateMap(lat, lng, location = ''){
      this.address = location;
      this.lat = lat;
      this.lng = lng;
      // var city = '';
      // var state = '';
      // var country = '';
      $('#location_search').val(this.address);
      let latlng = new window.google.maps.LatLng(this.lat, this.lng);
      // This is making the Geocode request
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({'latLng': latlng}, (results, status) => {
        if(status !== window.google.maps.GeocoderStatus.OK) {
          console.log(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if(status == window.google.maps.GeocoderStatus.OK) {
          if(!location) {
            this.address = (results[0].formatted_address);
            $('#location_search').val(this.address)
          }

          // results[0].address_components.forEach(address_component=>{
          //   address_component.types.forEach(type=>{
          //     if(type === 'locality') city = address_component.long_name;
          //     if(type === 'administrative_area_level_1') state = address_component.long_name;
          //     if(type === 'country' || type === 'administrative_area_level_2') country = address_component.long_name;
          //   })
          // })
        }
      });

      this.marker.setVisible(false);
      this.map.setCenter(latlng)
      this.marker.setPosition(latlng);
      this.marker.setVisible(true);
      setTimeout(() => {
        this.$emit('location-changed', {
          address: this.address,
          lat: this.lat,
          lng: this.lng
        });
      }, 1000)
    },
    reset(){
      this.updateMap(25.1972,  55.2744);
    }
  }
}
</script>

<style scoped>
#map {
  height: 250px;
  margin-top: 1rem;
}
</style>
