<template>
    <img v-bind="$attrs" @click="view" />
</template>

<script setup>
import { useAttrs } from 'vue'
const attrs = useAttrs();

const view = () => {
    if(attrs.src) {
        $('#img-viewer-modal img').attr('src', attrs.src);
        $('#img-viewer-modal').modal('show');
    }
}
</script>

<style scoped>
.modal-lg{
    max-width: 90%;
}
</style>

