<template>
    <section>
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />

        <div class="row mt-4">
            <div class="col-sm-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item "><b>Buyer Name: </b> {{ order?.user?.name }}</li>
                          <li class="list-group-item "><b>Buyer Email: </b> {{ order?.user?.email }}</li>
                          <li class="list-group-item "><b>Buyer Phone: </b> (+971) {{ order?.user?.mobile }}</li>
                        </ul>

                        <div v-if="isBusinessService(order)">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item"> <b>In Branch: </b> {{ order.branch.name }}</li>
                              <li class="list-group-item"> <b>Employee: </b> {{ order.employee ? order.employee?.name : 'No Preference'
                                                                             }}</li>
                              <li class="list-group-item"><b>Date: </b> {{ momentFormat(order.date,'LL') }}</li>
                              <li class="list-group-item"> <b>Start Time: </b> {{ order.start_time }}</li>
                              <li class="list-group-item"> <b>End Time: </b> {{ order.end_time }}</li>
                              <li class="list-group-item"> <b>Duration: </b> {{ order.total_duration }}</li>
                              <li class="list-group-item"> <b>Tip: </b> {{ moneyFormat(order.tip) }}</li>
                            </ul>
                        </div>
                        <div v-if="isVoucher(order)">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item"> <b>Voucher Name: </b> {{ order.order_voucher.title }}</li>
                              <li class="list-group-item"> <b>Limit: </b> {{ order.order_card_use.length}}/{{ order.order_voucher.qty }}</li>
                              <li class="list-group-item"> <b>Valid For: </b> {{ order.order_voucher.valid_for.replaceAll('_', ' ') }}</li>
                            </ul>
                        </div>
                        <div v-if="isMembership(order)">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item"> <b>Membership Name: </b> {{ order.order_membership.title }}</li>
                              <li class="list-group-item"> <b>Sessions: </b> {{ order.order_card_use.length
                                                                             }}/{{ order.order_membership.sessions === 'limited' ? order.order_membership.no_of_sessions : 'Unlimited'
                                                                             }}</li>
                            </ul>
                        </div>
                        <div v-if="isSMSPackage(order) || isAdvertisementPackage(order)">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item"> <b>Package: </b>
                                  {{ order.metadata.package.title
                                  }} - {{ isSMSPackage(order) ? 'SMS Package' : 'Advertisement Package' }}
                              </li>
                            </ul>
                        </div>

                        <div v-if="order.refund_at" class="alert alert-success">
                             <ul class="list-group list-group-flush">
                              <li> <b>Refund Ref ID: </b>{{ order.refund_ref_id }}</li>
                              <li> <b>Refund Amount: </b>{{ moneyFormat(order.refund_amount) }}</li>
                              <li> <b>Refund At: </b>{{ momentFormat(order.refund_at) }}</li>
                              <li> <b>Refund Note: </b>{{ order.refund_note }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="col-sm-12 px-0">
                            <h3>Order #{{ order.id }} <b class="badge badge-dark">{{ moneyFormat(order.amount) }}</b></h3>
                            <h6 class="text-capitalize">
                                <small class="text-muted">{{ momentFormat(order.created_at) }}</small>
                                <span class="badge badge-info">{{ getStatusText(order) }}</span>
                                <a v-if="order.status === 'awaiting_setup'" :href="`/initiate/transaction/${order.id}`" class="btn btn-outline-danger pull-right">
                                    <i class="fa fa-redo" aria-hidden="true"></i> Re-Try
                                </a>

                                <button v-if="!order.refund_at && isSuperadmin" class="btn btn-sm btn-outline-danger pull-right" data-toggle="modal" data-target="#refund-modal">Refund</button>
                            </h6>
                        </div>

                        <!-- Reviews -->
                        <section v-if="showReviewSection()" class="col-sm-12 bg-zinc-100 p-6 shadow-md rounded-md">
                             <div class="rating-box">
                                 <p class="display-4 font-semibold text-success mb-3">Review</p>
                                 <p v-if="canWriteReview()" class="text-xl ml-4">
                                     <span class="font-semibold text-xl text-blue-700">{{ reviewForm.rating.toFixed(1)}}</span> Out of 5.0</p>
                             </div>

                            <!-- Give Review-->
                             <div v-if="canWriteReview()" class="col-12">
                                 <StarRating :inline="true"
                                             :star-size="25"
                                             :rating="5"
                                             :show-rating="false"
                                             :read-only="false"
                                             :increment="0.1"
                                             active-color="#366865"
                                             :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                                             v-model="reviewForm.rating"
                                 />

                                 <div class="col-12 mt-4 px-0">
                                    <textarea class="form-control"
                                              placeholder="Share your experience with this barber to MyBarber community..."
                                              v-model="reviewForm.text"
                                    ></textarea>
                                </div>
                                <div class="col-12 mt-4 text-right px-0">
                                    <button class="btn btn-success" @click="sendReview">Submit Feedback</button>
                                </div>
                             </div>

                            <!-- Review List-->
                            <template v-if="order.reviews.length">
                                <div v-for="review in order.reviews" class="col-12 my-4 bg-lime-50 p-4 rounded-md">
                                     <StarRating :inline="true"
                                                 :star-size="22"
                                                 :rating="review.rating"
                                                 :increment="0.1"
                                                 :show-rating="false"
                                                 :read-only="true"
                                                 active-color="#366865"
                                                 :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                                     />
                                     <span class="badge badge-success mt-1 text-sm">{{ review.rating }}</span>
                                     <p class="text-muted text-lg mt-2"><i>{{ review.text ?? 'No feedback given' }}</i></p>
                                </div>
                            </template>

                            <!--  Not given-->
                            <p v-else-if="!canWriteReview()" class="alert alert-danger text-lg mt-2"><i> No feedback given </i></p>
                        </section>

                        <!-- Transactions-->
                        <div class="col-sm-12 mt-6">
                            <h4 class="font-normal">Transactions</h4>
                            <table class="table table-responsive">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">SI Ref ID/Card ID</th>
                                      <th scope="col">Reference ID</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="charge in order.order_charges" :key="`charge-${charge.id}`">
                                      <th scope="row">{{ charge.id }}</th>
                                      <td>{{ charge.si_ref_no }}</td>
                                      <td>{{ charge.reference_no }}</td>
                                      <td class="text-capitalize"><span class="badge badge-info">{{ charge.si_charge_status }}</span></td>
                                      <td>{{ moneyFormat(charge.si_amount) }}</td>
                                    </tr>
                                  </tbody>
                            </table>
                        </div>
                        <hr>

                        <!--  BusinessService-->
                        <div v-if="isBusinessService(order)">
                            <!-- Booked services-->
                            <div class="col-sm-12 mt-8">
                                <h4 class="font-normal">Booked Services</h4>
                                <table class="table table-responsive">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Note</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="orderService in order.services" :key="`orderService-${orderService.id}`">
                                        <th scope="row">{{ orderService.id }}</th>
                                        <td>{{ orderService.service?.name }}</td>
                                        <td>{{ orderService.service.service_category?.name }}</td>
                                        <td>{{ moneyFormat(orderService.service.price) }}</td>
                                        <td>{{ orderService.note }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                        </div>

                        <!--  Voucher | Membership -->
                        <div v-if="isVoucher(order) || isMembership(order)">
                            <!-- Card Uses -->
                            <div class="col-sm-12 mt-8">
                                <h4 class="font-normal">Booked by card</h4>
                                <table class="table table-responsive">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Branch</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Note</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="cardUse in order.order_card_use" :key="`cardUse-${cardUse.id}`">
                                        <th scope="row">{{ cardUse.id }}</th>
                                        <td>{{ cardUse.branch?.name }}</td>
                                        <td>{{ cardUse.date }}</td>
                                        <td>{{ cardUse.time }}</td>
                                        <td class="text-capitalize"><span class="badge badge-info">{{ cardUse.status.replace('_', ' ') }}</span></td>
                                        <td>{{ cardUse.note }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                        </div>

                        <!--  Products -->
                        <div v-if="isProduct(order)">
                            <div class="col-sm-12 mt-8">
                                <h4 class="font-normal">Products</h4>
                                <table class="table table-responsive">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="orderProduct in order.order_products"
                                        :key="`orderProduct-${orderProduct.id}`">
                                        <th scope="row">{{ orderProduct.id }}</th>
                                        <td>{{ orderProduct?.product?.name }}</td>
                                        <td>{{ orderProduct.qty }}</td>
                                        <td>{{ moneyFormat(orderProduct.price) }}</td>
                                        <td>{{ moneyFormat(orderProduct.total) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Refund Modal  -->
         <div v-if="isSuperadmin" class="modal fade bs-modal-xl in" id="refund-modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" id="modal-xl-data-location">
              <div class="modal-content border-none rounded-none">
                <div class="modal-header">
                  <span class="caption-subject font-red-sunglo text-bold uppercase" id="modalLgHeading">Order Refund</span>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i></button>
                </div>
                <div class="modal-body">
                  <!--  Basic info-->
                  <div class="row">
                      <div class="col-sm-12">
                          <div class="form-group">
                              <label>Refunded Date</label><br>
                              <date-range-picker
                                      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                      single-date-picker="single"
                                      :time-picker="true"
                                      :time-picker24-hour="true"
                                      opens="inline"
                                      show-dropdowns="false"
                                      :ranges="false"
                                      :show-dropdowns="true"
                                      :autoApply="true"
                                      v-model="refundForm.date"
                              ></date-range-picker>
                          </div>
                      </div>

                      <div class="col-sm-12 col-md-6 px-4">
                          <div class="form-group">
                              <label>Transaction ID</label>
                              <input type="text" class="form-control" v-model="refundForm.ref_id">
                          </div>
                      </div>
                      <div class="col-sm-12 col-md-6 px-4">
                           <div class="form-group">
                              <label>Amount</label>
                               <input type="number" class="form-control" v-model="refundForm.amount">
                           </div>
                      </div>
                      <div class="col-sm-12 px-4">
                          <div class="form-group">
                              <label>Note</label>
                              <textarea class="form-control w-full" rows="5" v-model="refundForm.note"></textarea>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
                    <button type="button" id="update-todo-item" class="btn btn-success" @click="refund"><i class="fa fa-check"></i>Refund</button>
                </div>
              </div>
            </div>
         </div>

    </section>
</template>

<script>
import OrderMixin from '@mixins/orderMixin';
import timeDateMixin from '@mixins/timeDateMixin';
import helperMixin from '@mixins/helperMixin';
import DateRangePicker from "vue2-daterange-picker"
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
export default {
    name: "ShowOrder",
    components: { DateRangePicker },
    mixins: [ OrderMixin, timeDateMixin, helperMixin ],
    props: {
        authUser: {
            required: true,
            type: Object
        },
        isSuperadmin: {
            required: true,
            type: Boolean
        },
        order: {
            required: true,
            type: Object
        },
    },
    data(){
        return {
            isLoading: false,
            reviewForm: {
                order_id: this.order.id,
                rating: 5,
                text: '',
            },
            refundForm:{
                order_id: this.order.id,
                ref_id:'',
                amount: 0,
                note:'',
                date: {
                    startDate:'',
                    endDate:'',
                },
            }
        }
    },
    mounted(){
        this.refundForm.date.startDate = new Date(moment().format('LL'));
        this.refundForm.date.endDate = new Date(moment().format('LL'));
    },
    methods: {
        showReviewSection(){
          return (this.order.type === 'App\\BusinessService' && this.order.status === 'completed')
                  || ((this.order.type === 'App\\Voucher' || this.order.type === 'App\\Membership')
                          && this.order.order_card_use.length
                          && this.order.order_card_use.some(cu=> cu.status === 'completed'));
        },
        canWriteReview(){
            let isBusinessService = this.order.user_id === this.authUser.id && this.order.status === 'completed' && this.order.type === "App\\BusinessService" && !this.order.reviews.length;
            let isCardUse = (this.order.type === "App\\Voucher" || this.order.type === "App\\Membership") && this.order.user_id === this.authUser.id && this.order.order_card_use.length
                    && this.order.order_card_use.some(cu=> cu.status === 'completed' && !this.order.reviews.some(r=> r.ref_id === cu.id));
            return isBusinessService || isCardUse;
        },
        sendReview(){
            if(this.canWriteReview()){
                this.isLoading = true;
                axios.post(`/store/review`, this.reviewForm)
                .then(res => {
                    this.$toast.success(`Successfully, Review has been created.`);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch(err => {
                    this.isLoading = false;
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                });
            }
        },
        refund(){
                this.isLoading = true;
                axios.post(`/super-admin/charge/refund`, this.refundForm)
                .then(res => {
                    this.$toast.success(`Successfully, Order has been updated.`);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch(err => {
                    this.isLoading = false;
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                });
        }
    }
}
</script>

<style>
.swal2-input[type=number] {
    max-width: 100%;
}
#refund-modal .hourselect.form-control, #refund-modal .minuteselect.form-control{
    text-align: center;
}
</style>

<style scoped>

</style>
