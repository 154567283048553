<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
      <!--    listing-->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center justify-content-md-end mb-3">
              <a href="javascript:void(0);" class="btn btn-rounded btn-primary mb-1" data-toggle="modal"
                 data-target="#branch-modal" @click="reset"><i class="fa fa-plus"></i> Add New</a>
            </div>
            <div class="table-responsive">
              <table id="myTable" class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Business Type</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="branch in allBranches" :key="branch.id">
                  <td>{{ branch.id }}</td>
                  <td>{{ branch.name }}</td>
                  <td>{{ branch.email }}</td>
                  <td>+971 {{ branch.contact }}</td>
                  <td>
                    {{ branch.category.name === 'Other' ? branch.custom_main_category + " (Other)" : branch.category.name
                    }}
                  </td>
                  <td>
                    <a href="javascript:;" @click="edit(branch)" class="btn btn-primary btn-circle edit-row"><i
                            class="icon-pencil"></i></a>
                    <a href="javascript:;" @click="remove(branch.id)" class="btn btn-danger btn-circle delete-row"
                       data-toggle="tooltip"><i class="fa fa-times" aria-hidden="true"></i></a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!--create or update modal-->
    <div class="modal fade bs-modal-lg in" id="branch-modal" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" id="modal-lg-data-location">
        <div class="modal-content">
          <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">{{ form.id ? 'Edit' : 'Setup' }} Branch</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control form-control-lg" autocomplete="off" v-model="form.name">
                </div>
              </div>
              <div class="col-md-6">
                <!-- text input -->
                <div class="form-group">
                  <label>Email address</label>
                  <input type="email" class="form-control form-control-lg" autocomplete="off" v-model="form.email">
                </div>
              </div>

              <div class="col-md-6">
                <label>Contact number</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">+971</span>
                  </div>
                  <input type="text" class="form-control form-control-lg"
                         aria-label="contact" aria-describedby="basic-addon1" maxlength="9" v-model="form.contact">
                </div>
              </div>

              <div class="col-md-6">
                <label>Choose your main business type</label>
                <select class="form-control form-control-lg select2" v-model="form.category_id">
                  <option v-for="(cat,i) in categories" :key="`category-${i}`" :value="cat.id">{{ cat.name }}</option>
                </select>
              </div>

              <div class="col-md-6" v-if="categories.some(c=>c.id == form.category_id && c.name == 'Other')">
                <!-- text input -->
                <div class="form-group">
                  <label>Other business type</label>
                  <input type="text" class="form-control form-control-lg" v-model="form.custom_main_category">
                </div>
              </div>

<!--              <div class="col-md-6">-->
<!--                <label>Time Slot Difference</label>-->
<!--                <select class="form-control form-control-lg select2" v-model="form.diff_time_slot">-->
<!--                  <option value="15">15 Minutes</option>-->
<!--                  <option value="30">35 Minutes</option>-->
<!--                  <option value="45">45 Minutes</option>-->
<!--                </select>-->
<!--              </div>-->

              <div class="col-md-6">
                <label>Available For</label>
                <select class="form-control form-control-lg select2" v-model="form.available_for">
                  <option value="male_only">Male Only</option>
                  <option value="female_only">Female Only</option>
                  <option value="for_everyone">For Everyone</option>
                </select>
              </div>

              <div class="col-md-6 mt-3">
                <div class=" mt-4">
                  <label>
                      <input type="checkbox" v-model="form.allow_home_service"
                             style="position: initial !important; right: 0 !important; margin-right: 3px;">
                      Would you like to provide service at home?
                  </label>
                </div>
              </div>

              <div class="col-md-12 mt-2">
                <label>Description</label>
                <textarea class="form-control form-control-lg" v-model="form.desc"></textarea>
              </div>

              <div class="col-12 mt-2">
                <GoogleMap
                        ref="googleMap"
                        @location-changed="getLocation($event)"
                />
              </div>

              <div class="col-12">
                <OneWeekSchedule
                        ref="weekSchedule"
                />
              </div>

              <div class="col-12 mt-3 px-3">
                <ImageUploader
                        ref="uploader"
                        @added="addFile"
                        @removed="removeFile"
                />
              </div>

            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times"></i>Cancel</button>
            <a href="javascript:void(0);" class="btn btn-success" @click="createUpdate()"><i class="fa fa-check"></i>
              {{ form.id ? 'Update' : 'Save' }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@components/admin/includes/GoogleMap";
import OneWeekSchedule from "@components/admin/includes/OneWeekSchedule";
import ImageUploader from "@components/admin/includes/ImageUploader";

export default {
    name: "Branches",
    components: { OneWeekSchedule, GoogleMap, ImageUploader },
    props: {
        categories: {
            type: Array,
            default: []
        },
        branches: {
            type: Array,
            default: []
        }
    },
    data(){
        return {
            isLoading: false,
            allBranches: this.branches,
            form: {
                id: '',
                name: '',
                email: '',
                contact: '',
                category_id: '',
                diff_time_slot: '15',
                custom_main_category: '',
                available_for: 'everyone',
                desc: '',
                address: '',
                lat: '',
                lng: '',
                allow_home_service: false,
                schedule: [],
                files: []
            },
        }
    },
    methods: {
        createUpdate(){
            this.isLoading = true;
            this.form.schedule = JSON.stringify(this.$refs.weekSchedule.getSchedule());
            axios.post('/account/branches', this.form)
            .then(res => {
                this.$toast.success(`Successfully, Branch has been ${ this.form.id ? 'updated' : 'created' }.`);
                this.allBranches = res.data.data.branches;
                $('#branch-modal').modal('hide');
                this.reset();
            })
            .catch(err => {
                this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
            }).finally(() => this.isLoading = false);
        },
        edit(data){
            this.form.id = data.id;
            this.form.name = data.name;
            this.form.email = data.email;
            this.form.contact = data.contact;
            this.form.category_id = data.category_id;
            this.form.diff_time_slot = data.diff_time_slot ?? 15;
            this.form.custom_main_category = data.custom_main_category;
            this.form.allow_home_service = data.allow_home_service;
            this.form.desc = data.desc;
            this.form.available_for = data.available_for;
            this.$refs.googleMap.updateMap(data.lat, data.lng, data.address);
            this.$refs.weekSchedule.setSchedule(data.schedule);
            this.$refs.uploader.manuallyAddFile(data.images);
            $('#branch-modal').modal('show');
        },
        remove(branchID){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete branch?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No, Cancel',
                confirmButtonText: 'Yes, Delete',
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed) {
                    axios.delete(`/account/branches/${ branchID }`)
                    .then(res => {
                        this.$toast.success(`Successfully, Branch has been deleted`);
                        this.allBranches = res.data.data.branches;
                    })
                    .catch(err => {
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                    });
                }
            })
        },
        reset(){
            this.form.id = '';
            this.form.name = '';
            this.form.email = '';
            this.form.contact = '';
            this.form.category_id = '';
            this.form.diff_time_slot = 15;
            this.form.custom_main_category = '';
            this.form.desc = '';
            this.form.available_for = 'everyone';
            this.form.address = '';
            this.form.lat = '';
            this.form.lng = '';
            this.form.allow_home_service = false;
            this.form.schedule = [];
            this.form.files = [];
            this.$refs.googleMap.reset();
            this.$refs.weekSchedule.reset();
            this.$refs.uploader.reset();
        },
        getLocation(e){
            this.form.address = e.address;
            this.form.lat = e.lat;
            this.form.lng = e.lng;
        },
        addFile(file){
            this.form.files.push(file);
        },
        removeFile(file){
            this.form.files = this.form.files.filter(f => f.name !== file.name);
        }
    }
}
</script>

<style scoped>

</style>
