<template>
    <div class="contact-profile">
        <img :src="$parent.getOtherUser($parent.$data.activeConversation)?.user_image_url"
             :alt="$parent.getOtherUser($parent.$data.activeConversation)?.name"/>
        <p>{{ $parent.getOtherUser($parent.$data.activeConversation)?.name }}</p>
<!--                <div class="social-media">-->
<!--                    <i class="fa fa-facebook" aria-hidden="true"></i>-->
<!--                    <i class="fa fa-twitter" aria-hidden="true"></i>-->
<!--                    <i class="fa fa-instagram" aria-hidden="true"></i>-->
<!--                </div>-->
    </div>
</template>

<script>
export default {
    name: "Topbar",
}
</script>

<style scoped>

</style>
