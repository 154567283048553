<template>
  <div :class="`col-12 w-100 h-100 ${classes}`">

    <!--    Large View-->
    <div v-if="size === 'lg'"
         :class="`overflow-hidden card card-${size} ${clickAble?'clickable':''}`"
         :style="{ 'background-image': 'url(/user-uploads/offers/'+image+'.webp)' }"
         @click="clickOffer">
      <div :class="`card-${size}-body `">
        <div class="col-12 w-full text-center py-2">
          <p class="grid justify-center">
              <svg class="text-white" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"
                   fill="none"><path fill="#ffffff"
                                     d="M11 4H4v7l9 9.01L20 13l-9-9zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5S8 5.67 8 6.5S7.33 8 6.5 8z"
                                     opacity=".3" /><path fill="currentColor"
                                                          d="M12.41 2.58C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42l-9-9zM13 20.01L4 11V4h7v-.01l9 9l-7 7.02z" /><circle
                      cx="6.5" cy="6.5" r="1.5" fill="currentColor" /></svg>
          </p>
          <h4 class="mb-0 text-4xl"><b>{{ name }}</b></h4>
        </div>
        <hr>
        <div class="col-12 text-center py-2 text-xl">
          <h4 class="py-2 font-semibold">Discount</h4>
          <h5>AED {{ discount }} %</h5>
        </div>
          <hr>
          <div class="col-12 text-center py-2 text-xl">
          <h4 class="py-2 font-semibold">Promo Code</h4>
          <h2 class="tracking-[0.3em] font-bold text-2xl text-amber-400">{{ code }}</h2>
        </div>
        <hr>
        <div class="col-12 text-center py-2">
          <span>Redeem on {{ selectAll ? 'all' : servicesCount }} Services</span><br>
          <span>{{ getValidFor }}</span><br>
          <span>For multiple-use until redeemed</span>
        </div>
      </div>
    </div>

    <!--    Small View-->
    <div v-else :class="`overflow-hidden card card-${size} ${clickAble?'clickable':''} `"
         :style="{ 'background-image': 'url(/user-uploads/offers/'+image+'.webp)' }"
         @click="clickOffer">

      <div :class="`card-${size}-body d-flex`">
        <div class="col-md-2">
            <svg class="text-white" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"
                 fill="none"><path fill="#ffffff"
                                   d="M11 4H4v7l9 9.01L20 13l-9-9zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5S8 5.67 8 6.5S7.33 8 6.5 8z"
                                   opacity=".3" /><path fill="currentColor"
                                                        d="M12.41 2.58C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42l-9-9zM13 20.01L4 11V4h7v-.01l9 9l-7 7.02z" /><circle
                    cx="6.5" cy="6.5" r="1.5" fill="currentColor" /></svg>
        </div>
        <div class="col-md-10 ml-4 text-center offer-sm-detail">
          <div class="font-semibold text-2xl">{{ name }}</div>
          <h3 class="font-normal text-lg"><b>Discount: </b> {{ discount }} %</h3>
<!--          <div class="font-semibold text-2xl mb-2">Promo Code</div>-->
          <h2 class="tracking-[0.3em] font-bold text-xl badge badge-light">{{ code }}</h2>
          <div class="grid gap-1 md:grid-cols-2 sm:grid-cols-1 mt-4">
              <div class="md:text-left md:ml-4">{{ getValidFor }}</div>
              <div class="md:text-right md:mr-4"></div>
              <div class="text-left md:ml-4 ">Redeem on {{ selectAll ? 'all' : servicesCount }} Services</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
    name: "OfferCard",
    props: {
        offerId: {
            type: [ Number, String ],
            default: ''
        },
        name: {
            type: String,
            default: 'Offer Name'
        },
        code: {
            type: [Number,String],
            default: 'XXXXXX'
        },
        validFor: {
            type: String,
            default: ''
        },
        discount: {
            type: [ Number, String ],
            default: 0
        },
        image: {
            type: Number,
            default: () => 20
        },
        selectAll: {
            type: Boolean,
            default: true
        },
        servicesCount: {
            type: Number,
            default: 0
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        clickAble: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'sm'
        },
        classes: {
            type: String,
            default: ''
        },
    },
    methods: {
        clickOffer(){
            if(this.clickAble) this.$emit('click', this.offerId);
        }
    },
    computed: {
        getValidFor(){
            return this.validFor === 'never' ? 'No Expiry Date' : 'Valid for ' + this.validFor.replaceAll('_', ' ');
        }
    }
}
</script>

<style scoped>
/*
* Gradient Site
* https://cssgradient.io/
* @Author Khuram Qadeer.
**/
p {
    color: black;
}
hr {
    border-color: rgb(226 227 225 / 30%);
    margin: 0;
}

.card {
    /*max-width: 700px;*/
    color: white;
    border: none;
    /*background: linear-gradient(0deg,#8b60ed,#b372bd);*/
    /*box-shadow: 0px 0px 35px 0px rgb(71 28 98);*/
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    aspect-ratio: 16/9;
}

.card-lg-body {
    padding: 1rem;
}

.card-sm-body {
    padding: 2rem 0rem 0rem 0rem;
}

.clickable {
    cursor: pointer;
}

.modal-open .modal {
    z-index: 99999;
}
</style>
