<template>
  <!--Select Services modal-->
  <div class="modal fade bs-modal-xl in" id="view-gift-modal" role="dialog" aria-labelledby="myModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" id="modal-xl-data-location">
      <div class="modal-content border-none rounded-none">
        <div class="modal-header">
            <span class="caption-subject font-red-sunglo uppercase text-capitalize text-xl font-semibold"
                  id="modalLgHeading">
              {{ giftType }} Voucher
            </span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body px-0">
          <div class="flex flex-column text-center gap-4">
            <div>
              <h1 class="text-capitalize text-xl font-semibold">Voucher Preview</h1>
            </div>
            <div class="bg-zinc-200 p-3">
              <p><small class="text-slate-400 text-xl text-capitalize">Voucher Subject {{ giftType }}:</small> Gift
                Voucher form {{ authUser.name }}</p>
            </div>

            <div v-if="forName">
              <p class="text-capitalize"><small class="text-slate-400 text-lg">Voucher For:</small> {{ forName }}</p>
            </div>

            <div>
              <voucher-card
                  :name.sync="branch.name"
                  :address.sync="branch.address"
                  :theme="giftType === 'print'?5:+card.theme"
                  size="lg"
                  :value.sync="+card.value"
                  :valid-for.sync="card.valid_for"
                  :booking-button="false"
                  :select-all.sync="card.all_selected"
                  :services-count.sync="+card.services.length"
                  :desc.sync="card.desc"
                  :enable-client-note.sync="!!card.enable_client_note"
                  :client-note.sync="card.client_note"
              />
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer bg-white">
        <button type="button" class="btn btn-danger text-danger" data-dismiss="modal">Close</button>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "ViewGiftModal",
  props: {
    authUser: {
      type: [Object,Array],
      default: () => {
      }
    },
    forName: {
      type: String,
      default: ''
    },
    giftType: {
      type: String,
      default: () => 'print'
    },
    branch: {
      type: Object,
      default: () => {
      }
    },
    card: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped>

</style>
