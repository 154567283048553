<template>
  <!--Select Services modal-->
  <div class="modal fade bs-modal-xl in" id="view-services-modal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" id="modal-xl-data-location">
      <div class="modal-content border-none rounded-none">
        <div class="modal-header">
            <span class="caption-subject font-red-sunglo text-bold uppercase"
                  id="modalLgHeading">Services</span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <!--            Basic info-->
          <div class="row px-4">
            <div class="col-md-12">
              <ul v-if="getServices.length" class="list-group">
                <li v-for="(service,i) in getServices" :key="`view-service-${i}`"
                    class="list-group-item d-flex justify-content-between align-items-center border-none hover:ring-1 hover:ring-zinc-200	">
                  <span class=" text-xl">{{i+1}}) {{ service.name }}</span>
                  <span class="text-xl">{{ moneyFormat(service.price.toFixed(2)) }}
                        <small>{{ getPriceType(service.price_type) }}</small>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer bg-white">
        <button type="button" class="btn btn-danger text-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</template>

<script>
import TimeDateMixin from '@mixins/timeDateMixin';
import HelperMixin from '@mixins/helperMixin';

export default {
  name: "ViewServicesModal",
  mixins: [TimeDateMixin,HelperMixin],
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getServices(){
      return this.services;
    }
  }
}
</script>

<style scoped>

/* Hide the browser's default checkbox */
.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.cb-container:hover input ~ .checkmark {
  background-color: black;
}

/* When the checkbox is checked, add a blue background */
.cb-container input:checked ~ .checkmark {
  background-color: black;
}
/* Show the checkmark when checked */
.cb-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cb-container .checkmark:after {
  left: 10px;
  top: 3px;
  width: 8px;
  height: 18px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.switch-div .text-label {
  position: relative;
  left: 40px;
  top: -10px;
  font-size: 16px;
}
</style>
