<template>
  <div v-if="reviews.length" class="container-fluid px-1 py-5">
     <div class="col-md-12 py-4 pl-0">
         <h1 class="section-title">Reviews</h1>
     </div>
    <div class="row justify-content-center">
      <div class="col-xl-7 col-lg-10 col-md-10 col-12 text-center mb-5" id="list-reviews">
        <!--        Total Ratings-->
<!--        <div class="card rounded-lg shadow-xl">-->
<!--          <div class="row justify-content-left d-flex">-->
<!--            <div class="col-md-4 d-flex flex-column">-->
<!--              <div class="rating-box">-->
<!--                <h1 class="pt-4 display-3">4.0</h1>-->
<!--                <p class="text-light">Out of 5.0</p>-->
<!--              </div>-->
<!--              <div class="mt-1">-->
<!--                <StarRating-->
<!--                    :inline="true"-->
<!--                    :star-size="25"-->
<!--                    :read-only="true"-->
<!--                    :rating="5"-->
<!--                    :show-rating="false"-->
<!--                    active-color="#366865"-->
<!--                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;            rating Bars&ndash;&gt;-->
<!--            <div class="col-md-8">-->
<!--              <div class="rating-bar0 justify-content-center">-->
<!--                <table class="text-left mx-auto">-->
<!--                  <tr>-->
<!--                    <td class="rating-label">Excellent</td>-->
<!--                    <td class="rating-bar">-->
<!--                      <div class="bar-container">-->
<!--                        <div class="bar" style="width:70%"></div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td class="text-right">123</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="rating-label">Good</td>-->
<!--                    <td class="rating-bar">-->
<!--                      <div class="bar-container">-->
<!--                        <div class="bar" style="width:55%"></div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td class="text-right">23</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="rating-label">Average</td>-->
<!--                    <td class="rating-bar">-->
<!--                      <div class="bar-container">-->
<!--                        <div class="bar" style="width:45%"></div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td class="text-right">10</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="rating-label">Poor</td>-->
<!--                    <td class="rating-bar">-->
<!--                      <div class="bar-container">-->
<!--                        <div class="bar" style="width:30%"></div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td class="text-right">3</td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="rating-label">Terrible</td>-->
<!--                    <td class="rating-bar">-->
<!--                      <div class="bar-container">-->
<!--                        <div class="bar" style="width:10%"></div>-->
<!--                      </div>-->
<!--                    </td>-->
<!--                    <td class="text-right">0</td>-->
<!--                  </tr>-->
<!--                </table>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!--        Reviews-->
        <div v-for="review in reviews" :key="`review-${review.id}`" class="review">
          <div class="row d-flex">
            <div class="">
              <img class="profile-pic" :src="review.user.user_image_url" alt="">
            </div>
            <div class="d-flex flex-column">
              <h3 class="text-left mb-0 text-capitalize">{{ review.user.name }}</h3>
              <div>
                <p class="text-left">
                  <StarRating
                      :rating="review.rating"
                      :round-start-rating="false"
                      :inline="true"
                      :star-size="14"
                      :read-only="true"
                      :show-rating="false"
                      active-color="#366865"
                      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                  />
                  <span class="rating-text">({{ review.rating }})</span>
                </p>
              </div>
            </div>
            <div class="ml-auto">
              <p class="text-muted pt-5 pt-sm-3">{{ diffForHumans(review.created_at) }}</p>
            </div>
          </div>
          <div class="row text-left">
            <p class="comment">{{ review.text }}</p>
          </div>
        </div>


      <!-- Next & Previous Buttons -->
<!--      <div  class="row mt-4" id="next-prev-pagination">-->
<!--        <div class="col-md-12 text-center">-->
<!--          <a href="javascript:void(0);"-->
<!--             :class="`btn btn-outline-dark inline-flex px-4 py-2`"-->
<!--            >-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">-->
<!--              &lt;!&ndash;! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;-->
<!--              <path-->
<!--                  d="M9.375 233.4l128-128c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H480c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38c12.5 12.5 12.5 32.75 0 45.25c-12.49 12.49-32.74 12.51-45.25 0l-128-128C-3.125 266.1-3.125 245.9 9.375 233.4z"/>-->
<!--            </svg>-->
<!--            Prev-->
<!--          </a>-->
<!--          <a href="javascript:void(0);"-->
<!--             :class="`btn btn-outline-dark inline-flex px-4 py-2`"-->
<!--             >-->
<!--            Next-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">-->
<!--              &lt;!&ndash;! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. &ndash;&gt;-->
<!--              <path-->
<!--                  d="M502.6 278.6l-128 128c-12.51 12.51-32.76 12.49-45.25 0c-12.5-12.5-12.5-32.75 0-45.25L402.8 288H32C14.31 288 0 273.7 0 255.1S14.31 224 32 224h370.8l-73.38-73.38c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l128 128C515.1 245.9 515.1 266.1 502.6 278.6z"/>-->
<!--            </svg>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->

      </div>

    </div>
  </div>

</template>

<script>
import TimeDateMixin from '@mixins/timeDateMixin';

export default {
  name: "Reviews",
    mixins:[TimeDateMixin],
    props:{
      reviews:{
          type: Array,
          default: ()=>[]
      }
    }
}
</script>

<style lang="scss" scoped>

h3 {
  font-size: 1rem;
}

.card {
  /*border-radius: 5px;*/
  background-color: #fff;
  margin-top: 30px;
  padding: 30px 60px;
  //border: none;
  //border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.rating-box {
  width: 130px;
  height: 130px;
  margin-right: auto;
  margin-left: auto;
  //background-color: #FBC02D;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 10px;
}

.rating-label {
  font-weight: bold
}

.rating-bar {
  width: 300px;
  padding: 8px;
  border-radius: 5px
}

.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 5px
}

.bar {
  height: 13px;
  background-color: var(--primary-color);
  border-radius: 10px
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  object-fit: cover;
}

.review {
  padding: 3%;
  border-bottom: 1px solid #dfdfdf;

  & .comment{
      font-size: 12px;
      font-weight: 400;
      line-height: 21px;
    }
  & .rating-text{
    font-size: 14px;
    color: #6c757d !important;
    position: relative;
    top: 2px;
  }
}

#next-prev-pagination {
  & svg{
    position: relative;
    //top: 5px;
    margin: 0px 5px;
  }
  & a:hover{
    background: #d7e7e6b3;
    color: black;
  }
}

#list-reviews{
    height: 31em;
    overflow: scroll;
}
</style>
