<template>
    <section>
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 loader="dots"
                 color="#366865"
                 background-color="#a3afeb66"
        />
        <div class="container">
            <div class="row">

                <!-- Cart -->
                <div class="col-md-4 col-sm-12">
                    <div class="card card-cart shadow-2xl sticky top-20">
                        <div class="card-body text-center">
                            <img class="img-thumbnail " :src="branch.images.length?branch.images[0].path:'/images/default_barber.png'"
                                 alt="Card image cap">
                            <h5 class="card-title mt-3 mb-0 font-semibold">{{ branch.name }}</h5>
                            <small class="text-muted leading-normal">{{ branch.address }}</small>
                        </div>
                        <div class="card-body">
                            <input type="text" placeholder="Search" class="form-control" v-model="keyword">

                            <ul v-if="form.selectedProducts" class="list-group list-group-flush mt-4">
                                <TransitionGroup enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut" mode="out-in">
                                    <li v-for="product in form.selectedProducts" :key="`selected-product-${product.id}`"
                                        class="list-group-item px-2 py-4">
                                        <small class="name">
                                            {{ product.name }}
                                            <a href="javascript:void(0);" class="text-danger" @click="remove(product.id)">
                                                <i class="fa fa-remove"></i></a>
                                        </small>
                                        <span class="currency pull-right">
                                            {{ moneyFormat(product.price * product.selectedQuantity) }}
                                        </span>

                                        <div class="flex flex-row gap-2 w-full rounded-lg relative bg-transparent mt-1 justify-center">
                                            <button :disabled="product.selectedQuantity < 2" @click="product.selectedQuantity--"
                                                    :class="`h-10 w-12 rounded cursor-pointer outline-none border shadow-lg bg-slate-100 hover:bg-black hover:text-white ${product.selectedQuantity === 1?'disabled':''}`">
                                                <span class="m-auto text-lg">−</span>
                                            </button>
                                            <input type="number" v-model="product.selectedQuantity"
                                                   class="py-1 w-16 shadow-lg bg-white rounded text-center hover:text-black focus:text-black  md:text-basecursor-default flex items-center  outline-none"
                                                   name="custom-input-number" value="1"/>
                                            <button :disabled="product.selectedQuantity === product.qty" @click="product.selectedQuantity++"
                                                    class="h-10 w-12 rounded cursor-pointer border shadow-lg bg-slate-100 hover:bg-black hover:text-white">
                                                <span class="m-auto text-lg">+</span>
                                            </button>
                                        </div>
                                        <p class="text-center">
                                            <small v-if="product.selectedQuantity === product.qty" class="text-danger">Max Stock {{product.qty}}</small>
                                        </p>
                                    </li>
                                </TransitionGroup>
                                <li v-if="form.selectedProducts.length" class="list-group-item total">
                                    Total<span class="float-right">{{ getTotal }}</span>
                                </li>
                            </ul>
                            <small v-else class="mb-4 mt-4 text-muted text-center">No product selected yet</small>

                            <a v-if="authUser && form.selectedProducts.length" href="javascript:void(0);" class="btn btn-primary w-100 mt-4" @click="buy">Ready To Checkout</a>
                            <a v-else-if="!authUser" href="/login" class="btn btn-primary w-100 mt-4">Login</a>
                        </div>
                    </div>
                </div>

                <!-- Products -->
                <div class="col-md-8 col-sm-12 my-4" id="detail-section">
                    <div class="grid md:grid-cols-4 sm:grid-cols-2 gap-4 justify-content-center place-self-center">
                        <div v-for="product in getProducts"
                             :key="`p-${product.id}`"
                             class=" rounded-lg overflow-hidden shadow-lg"
                        >
                            <img class="w-full h-64 object-cover object-center" :src="product.image" alt="Sunset in the mountains" @click="viewImg(`${product.image}`)">
                            <div class="px-6 py-4">
                                <div class="font-bold text-xl mb-2 text-capitalize">
                                    {{ product.name }}
                                    <div class="switch-div pull-right">
                                        <label class="switch" style="height: 22px !important; width: 47px !important;">
                                            <input :id="`cbx-${product.id}`" type="checkbox" name="selectedProducts[]" :value="product" v-model="form.selectedProducts">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <p class="text-gray-700 text-base mb-4">{{ product.description }}</p>
                                <span class="currency">
                                    {{ moneyFormat(product.price) }}
                                </span>
                                <!--                                <div class="mt-8 flex justify-start items-center">-->
                                <!--                                    <img class="w-10 h-10 rounded-full mr-4 object-cover" src="https://randomuser.me/api/portraits/men/92.jpg" alt="Avatar of Jonathan Reinink">-->
                                <!--                                    <div class="text-sm">-->
                                <!--                                        <p class="text-gray-900 leading-none">Jonathan Reinink</p>-->
                                <!--                                        <p class="text-gray-600">Aug 18</p>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import helperMixin from '@mixins/helperMixin';

export default {
    name: "ProductsShow",
    mixins: [ helperMixin ],
    props: {
        authUser: {
            type: Object,
            default: () => ({})
        },
        branch: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            isLoading: false,
            products: [],
            keyword: '',
            form: {
                selectedProducts: []
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.isLoading = true;
            axios.get(`/get/products/${this.branch.user_id}`)
                .then(res => {
                    this.products = res.data.data.products.map(p=>{
                        p.selectedQuantity=1;
                        return p;
                    });
                })
                .finally(() => this.isLoading = false);
        },
        remove(productId) {
            this.form.selectedProducts = this.form.selectedProducts.filter(p => p.id !== productId);
        },
        buy(){
            this.isLoading = true;
            axios.post(`/buy/products`,this.form)
                .then(res => {
                    this.$toast.success(`Successfully, Order has been created.`);
                    setTimeout(() => {
                        window.location.href = res.data.data.redirect_to;
                    }, 1500);
                })
                .catch(err => {
                    this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText);
                    this.isLoading = false
                });
        },
        viewImg(src) {
            if (src) {
                $('#img-viewer-modal img').attr('src', src);
                $('#img-viewer-modal').modal('show');
            }
        },
    },
    computed: {
        getTotal() {
            let sum = 0;
            for (let i = 0; i < this.form.selectedProducts.length; i++) {
                sum += +(this.form.selectedProducts[i].price * this.form.selectedProducts[i].selectedQuantity);
            }
            return this.moneyFormat(sum);
        },
        getProducts() {
            // Searching
            if (this.keyword) {
                return this.products.filter(p => {
                    return p.name.toLowerCase().includes(this.keyword.toLowerCase()) || p.description.toLowerCase().includes(this.keyword.toLowerCase());
                })
            }
            return this.products;
        }
    }
}
</script>

<style scoped>
input {
    background-color: initial !important;
}

.card-cart {
    border-bottom: 4px solid #366865;
    border-radius: 0 0 0 20px;
    margin-top: 4rem;
}

.card-cart .name {
    font-size: 12px;
    text-transform: capitalize;
}

.card-cart .currency {
    font-size: 14px;
}

.card-cart img {
    max-width: 75%;
    margin-top: -68px;
    margin-left: 55px;
}

.currency {
    color: #366865;
    font-weight: bolder;
    font-size: 18px;
}

i.fa.fa-remove {
    color: red;
    font-size: 17px;
}

.total {
    color: black;
    padding: 10px 5px;
    font-weight: bolder;
    font-size: larger;
}

.total p {
    color: white;
    font-weight: bolder;
    font-size: 20px;
}
.slider:before {
    left: 3px !important;
    bottom: 4px !important;
}
</style>
