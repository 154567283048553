<template>
  <div class="row">
    <div class="col-12">
      <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-sending="sendingEvent"
          @vdropzone-success="success"
          @vdropzone-error="error"
          @vdropzone-removed-file="removed"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            <i class='fa fa-cloud-upload'></i> Drag & Drop </h3>
          <div class="subtitle">OR click to select a file from your system</div>
        </div>
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "ImageUploader",
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function(){
    return {
      dropzoneOptions: {
        url: '/account/image/validate',
        addRemoveLinks: true,
      }
    }
  },
  methods: {
    sendingEvent(file, xhr, formData){
      formData.append("_token",  document.head.querySelector('meta[name="csrf-token"]').content);
    },
    success(file, response){
      $('#dropzone .dz-message').hide();
      this.$emit('added',file);

    },
    error(file, res, xhr){
      this.$toast.error(res.message);
    },
    removed(file, error, xhr){
      this.$emit('removed', file);
      if(file.id){
        axios.get(`/account/image/delete/${file.id}`);
      }
    },
    reset(){
      $('#dropzone .dz-message').show();
      $('#dropzone .dz-preview').remove();
    },
    manuallyAddFile(images){
      this.reset();
      if(images.length){
        $('#dropzone .dz-message').hide();
        for(let i = 0; i < images.length; i++) {
          this.$refs.myVueDropzone.manuallyAddFile({size: 300, name: "PNG", type: "image/png", id: images[i].id},
              window.location.origin+'/'+images[i].path
          );
        }
      }
    }
  }
}
</script>

<style scoped>
div#dropzone {
    display: block;
  background: #36686529;
  border-radius: 10px;
  min-height: 200px;
}
.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #366865;
}

.subtitle {
  color: #366865;
}

.fa.fa-cloud-upload {
  font-size: 40px;
}
</style>
