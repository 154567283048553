<template>
  <div class="row">
       <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="true"
                loader="dots"
                color="#366865"
                background-color="#a3afeb66"
       />
    <div class="col-md-12">
      <div class="card card-light">
        <div class="">
          <div id='calendar'></div>
        </div>
      </div>
    </div>

      <!--    Event Detail Modal-->
    <div class="modal fade bd-example-modal-md" id="event-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Booking #{{ currentEvent.id }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="currentEvent.id" class="modal-body">
            <div class="row text-center">

              <div :class="`col-sm-12 text-center svg_div_${currentEvent.status}`">
<!--                  SVG Completed-->
                  <svg v-if="isStatus('completed')" class="ml-auto mr-auto" width="60" height="60" viewBox="0 0 24 24">
                    <path fill="" d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92c-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93c-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M15.5 8.5l-4.88 4.88l-2.12-2.12l-1.06 1.06l3.18 3.18l5.94-5.94L15.5 8.5M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 0 1 4.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0 0 11 22v-2a8.002 8.002 0 0 1-3.9-1.63Z" />
                  </svg>
<!--                 SVG Awaiting time-->
                  <svg v-else-if="isStatus('awaiting_time')" class="ml-auto mr-auto" width="60" height="60" viewBox="0 0 24 24">
                    <path fill="" d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92c-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93c-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M15.5 8.5l-4.88 4.88l-2.12-2.12l-1.06 1.06l3.18 3.18l5.94-5.94L15.5 8.5M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 0 1 4.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0 0 11 22v-2a8.002 8.002 0 0 1-3.9-1.63Z" />
                  </svg>
<!--                SVG Expired | Cancelled-->
                   <svg v-else-if="isStatus('expired') || isStatus('cancelled')" class="ml-auto mr-auto" width="60" height="60" viewBox="0 0 24 24">
                    <path fill="" d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92c-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93c-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 0 1 4.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0 0 11 22v-2a8.002 8.002 0 0 1-3.9-1.63M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8Z" />
                   </svg>
<!--                  SVG Pending-->
                <svg v-else class="ml-auto mr-auto" width="60" height="60" viewBox="0 0 24 24">
                  <path d="M13 2.03v2.02c4.39.54 7.5 4.53 6.96 8.92c-.46 3.64-3.32 6.53-6.96 6.96v2c5.5-.55 9.5-5.43 8.95-10.93c-.45-4.75-4.22-8.5-8.95-8.97m-2 .03c-1.95.19-3.81.94-5.33 2.2L7.1 5.74c1.12-.9 2.47-1.48 3.9-1.68v-2M4.26 5.67A9.885 9.885 0 0 0 2.05 11h2c.19-1.42.75-2.77 1.64-3.9L4.26 5.67M2.06 13c.2 1.96.97 3.81 2.21 5.33l1.42-1.43A8.002 8.002 0 0 1 4.06 13h-2m5.04 5.37l-1.43 1.37A9.994 9.994 0 0 0 11 22v-2a8.002 8.002 0 0 1-3.9-1.63M12.5 7v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5Z" />
                </svg>
                <p class="text-xl text-capitalize">{{ currentEvent?.status?.replaceAll('_', ' ') }} {{ show('cancelled_at') }}</p>
              </div>

              <div class="col-sm-12 text-left mt-4 text-capitalize">

                  <!-- Cancel Booking | Customer, SuperAdmin, Admin can cancel booking-->
                  <div v-if="canCancelBooking()"
                       class="flex flex-wrap px-4 py-6 shadow-2xl bg-red-600 text-white rounded justify-content-between mb-4">
                      <p class="text-xl font-semibold">Do you want to cancel the booking? </p>
                      <a href="javascript:void(0);" class="btn btn-sm btn-light" @click="cancelBooking">Cancel</a>
                  </div>
                  <!-- Complete Booking | SuperAdmin, Admin can complete booking-->
                  <div v-if="isStatus('awaiting_time') && (isRole('admin') || isRole('superadmin'))"
                       class="flex flex-wrap px-4 py-6 shadow-2xl bg-green-600 text-white rounded justify-content-between mb-4">
                      <p class="text-xl font-semibold">Do you want to complete the booking? </p>
                      <a href="javascript:void(0);" class="btn btn-sm btn-light" @click="completeBooking">Complete</a>
                  </div>

                <!-- Voucher | Membership -->
                <div v-if="isVoucher() || isMembership()">
                  <p><b>{{ isVoucher() ? 'Voucher' : 'Membership' }}: </b> {{ show('card_name') }}</p>
                  <p><b>Valid For: </b> {{ show('card_valid_for') }}</p>
                  <p><b>Price: </b> {{ show('card_price') }} </p>
                  <p v-if="isVoucher()"><b>Quantity: </b> {{ show('card_qty') }}</p>
                  <p><b>Card Use: </b> {{ show('card_use') }}</p>
                </div>
                  <p><b>Customer: </b> {{ show('customer_name') }} </p>
                  <p v-if="(isRole('admin') || isRole('superadmin')) && (isStatus('awaiting_time') || isStatus('expired'))">
                      <b>Change Date: </b>
                      <v-date-picker v-model="date" mode="date" :min-date="new Date()">
                          <template v-slot="{ inputValue, inputEvents }">
                              <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                          </template>
                      </v-date-picker>
                  </p>
                  <p v-else><b>Date: </b> {{ show('date') }}, {{ show('time') }} </p>
                <p v-if="show('employee_name')">
                    <b>Staff Preference: </b> {{ show('employee_name') }}
                    <small>{{ show('employee_expertise') }}</small>
                </p>
                <p v-if="isBusinessService()">
                    <b>Tip: </b> {{ show('tip') }}
                </p>
                <p><b>Paid Amount: </b> {{ show('amount') }}</p>
                <p><b>Booked At: </b> {{ show('created_at') }}</p>
              </div>
              <div v-if="isBusinessService()" class="col-sm-12 text-left my-3">
                <h4 class="text-xl font-semibold"><b>Services</b></h4>
                <ul class="list-group list-group-flush text-capitalize">
                  <li v-for="(service,i) in currentEvent.booking.services" :key="`bs-index-${i}`"
                      class="text-md">
                    <b>{{ i + 1 }})</b> {{ service.service.name }}
                    <small class="text-muted ml-2 text-md">
                        ({{ timeFormat(service.price_duration) }}, {{ moneyFormat(service.price) }}{{ getPriceType(service.price_type) }})
                    </small>
                  </li>
                </ul>
              </div>
              <div v-if="show('note')" class="col-sm-12 text-left my-3">
                <h4 class="text-xl font-semibold"><b>Booking Note:</b></h4>
                  <p>{{ show('note') }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button v-if="(isRole('admin') || isRole('superadmin')) && (isStatus('awaiting_time') || isStatus('expired'))" type="button" class="btn btn-success" @click="updateBookingDate()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Booking Modal-->
    <div v-if="isRole('admin') || isRole('superadmin')" class="modal fade bd-example-modal-md" id="add-booking-modal" tabindex="-1"
         role="dialog"
         aria-labelledby="mySmallModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Booking</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <label>Customer</label>
                    <select class="form-control" v-model="formBooking.customer_id">
                        <option value="">Select</option>
                        <option v-for="customer in customers"
                                :key="`customer-${customer.id}`"
                                :value="customer.id">{{ customer.name }}</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label>Branches</label>
                    <select class="form-control" v-model="formBooking.branch_id">
                        <option value="">Select</option>
                        <option v-for="branch in branches"
                                :key="`branch-${branch.id}`"
                                :value="branch.id">{{ branch.name }}</option>
                    </select>
                </div>

                <div class="col-sm-6 mt-4">
                   <!-- text input -->
                    <div class="form-group">
                      <p class="service-info">
                        {{ formBooking.all_selected ? 'All' : formBooking.services.length }} Services
                        <a href="javascript:void(0);" class="btn btn-sm pull-right" data-toggle="modal"
                           data-target="#select-services-modal"><i class="fa fa-pencil"></i></a>
                      </p>
                    </div>
                </div>

                <div class="col-sm-6 mt-4">
                    <label>Employees</label>
                    <select class="form-control" v-model="formBooking.employee_id">
                        <option value="no_preference">No Preference</option>
                        <option v-for="employee in employees"
                                :key="`employee-${employee.id}`"
                                :value="employee.id">{{ employee.name }}</option>
                    </select>
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="">Date For Booking</label>
                    <v-date-picker v-model="formBooking.date" mode="date" :min-date="calendarConfig.tomorrow">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="bg-white border px-2 py-1 rounded w-full"
                               :value="inputValue"
                               v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                </div>
                 <div class="col-sm-6 mt-2">
                    <label for="">Start Time</label>
                     <br>
                     <vue-timepicker :minute-interval="15" format="hh:mm A" v-model="formBooking.time" />
                    <p class="text-muted"> {{ getEndTime }}</p>
                </div>

                <!--                allow charge-->
              <div class="col-sm-12 mt-4">
                <div class="switch-div">
                  <label class="switch mr-2">
                    <input name="view" type="checkbox" v-model="formBooking.allow_charge">
                    <span class="slider round"></span>
                  </label>
                  <label style="position:relative; right: 4px; top:-13px;">
                      <a href=""><b>Would you like to charge for this booking by customer? </b></a>
                      <i class="fa fa-info-circle" data-toggle="tooltip"
                         title="Customer will receive the link for this booking payment and after login he can pay via link"
                         style="font-size: 20px"></i>
                  </label>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-success" @click="createBooking()">Create</button>
          </div>
        </div>
      </div>
    </div>

    <SelectServicesModal
            ref="selectService"
            :services="services"
            :all-services="formBooking.all_selected"
            @updated="updateServices"
    />
  </div>
</template>

<script>
import HelperMixin from '@mixins/helperMixin';
import TimeDateMixin from '@mixins/timeDateMixin';
import SelectServicesModal from "@components/includes/SelectServicesModal";
import momentTimezone from "moment-timezone";
import moment from "moment";
import VueTimepicker from 'vue2-timepicker';

export default {
    components: { SelectServicesModal, VueTimepicker },
    mixins: [ HelperMixin, TimeDateMixin ],
    props: {
        role: {
            required: true,
            type: String,
        },
        bookings: {
            type: Array,
            default: () => []
        },
        services: {
            type: Array,
            default: () => []
        },
        employees: {
            type: [ Array, Object ],
            default: () => []
        },
        branches: {
            type: Array,
            default: () => []
        },
        customers: {
            type: Array,
            default: () => []
        }
    },
    metaInfo: {
        script: [
            {
                src: "https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.10.2/fullcalendar.min.js",
                integrity: 'sha512-o0rWIsZigOfRAgBxl4puyd0t6YKzeAw9em/29Ag7lhCQfaaua/mDwnpE2PVzwqJ08N7/wqrgdjc2E0mwdSY2Tg==',
                crossorigin: 'anonymous',
                referrerpolicy: 'no-referrer'
            }
        ],
    },
    data(){
        return {
            isLoading: true,
            calendar: '',
            events: [],
            currentEvent: {},
            calendarConfig:{
              tomorrow: new Date(moment().add(1, 'd'))
            },
            date: new Date(),
            formBooking: {
                branch_id: '',
                customer_id: '',
                employee_id: 'no_preference',
                date: '',
                time: '12:00 AM',
                end_time: '',
                total_duration: '',
                all_selected: false,
                services: [],
                allow_charge: true,
            }
        };
    },
    mounted(){
        this.calendarSetup();
    },
    methods: {
        setEvents(){
            let _this = this;
            this.bookings.forEach(booking => {
                let isVoucher = booking.type.includes('Voucher');
                let isMembership = booking.type.includes('Membership');
                if((isVoucher || isMembership) && booking.order_card_use.length) {
                    booking.order_card_use.forEach(function(cardUse){
                        _this.events.push({
                            id: booking.id,
                            type: booking.type,
                            card_use_id: cardUse.id,
                            title: _this.isRole('employee') ? booking.user?.name : (cardUse.employee?.name ?? ' No preference'),
                            status: cardUse.status,
                            start: _this.eventFormat(cardUse.date, cardUse.time),
                            end: _this.eventFormat(cardUse.date, cardUse.time)
                        });
                    });
                } else {
                    _this.events.push({
                        id: booking.id,
                        type: booking.type,
                        card_use_id: null,
                        title: booking.employee?.name ?? ' No preference',
                        status: booking.status,
                        start: _this.eventFormat(booking.date, booking.start_time),
                        end: _this.eventFormat(booking.date, booking.end_time)
                    });
                }
            });
        },
        async calendarSetup(){
            let _this = this;
            await this.setEvents();
            $(function($){
                setTimeout(async() => {
                    let btnBooking = _this.isRole('admin') ? {
                        customButtons: {
                            addBooking: {
                                text: 'Add Booking',
                                click: function(){
                                    $('#add-booking-modal').modal('show');
                                }
                            }
                        }
                    } : {};
                    _this.calendar = await $('#calendar').fullCalendar({
                        ...btnBooking,
                        header: {
                            left: `prev,next today ${ _this.isRole('admin') ? 'addBooking' : '' }`,
                            center: 'title',
                            right: 'month,agendaWeek,agendaThreeDay,agendaDay,listYear',
                        },
                        allDay: false,
                        events: _this.events,
                        eventOverlap: true,
                        // displayEventTime: true,
                        timeFormat: 'hh:mm a',
                        locale: 'en',
                        eventLimit: true,
                        views: {
                            month: {
                                eventLimit: 5
                            },
                            agendaThreeDay: {
                                type: 'agenda',
                                duration: { days: 3 },
                                buttonText: '3 days'
                            }
                        },
                        // defaultView:'agendaDay',
                        eventRender: function(event, eventElement, view){
                            if(event.title) {
                                eventElement.find('.fc-title').prepend(`&nbsp&nbsp<i class="fa fa-user-circle eventIcon text-white" aria-hidden="true"></i>&nbsp;`);
                            }
                            eventElement.prepend(`<i class="eventIcon text-white">#${event.id}</i>&nbsp;`);
                            eventElement.find('.fc-time').prepend(`<i class="fa fa-clock-o eventIcon text-white" aria-hidden="true"></i>&nbsp;`);
                        },
                        eventAfterRender: function(event, element, view){
                            element.addClass(`booking_${ event.status }`)
                        },
                        eventClick: _this.eventClick,
                        editable: false,
                        eventDrop: _this.eventDrop,
                    });
                    _this.isLoading = false;
                    $(function(){
                        $('[data-toggle="tooltip"]').tooltip();
                    })
                }, 2000)
            });
        },
        eventFormat(date, time){
            let dateTime = (momentTimezone(date).format('Y-MM-DD')).concat(' ').concat(time);
            return momentTimezone(dateTime).format('Y-MM-DD hh:mm:ss');
        },
        eventClick(calEvent, jsEvent, view){
            this.currentEvent = {
                id: calEvent.id,
                type: calEvent.type,
                card_use_id: calEvent.card_use_id,
                status: calEvent.status,
                booking: this.bookings.find(b => b.id === calEvent.id)
            }
            $('#event-modal').modal('show');
            this.date = this.show('date');
        },
        eventDrop(event, dayDelta, minuteDelta, allDay, revertFunc){
            // console.log(event);
        },
        isBusinessService(){
            return this.currentEvent?.type?.includes('BusinessService');
        },
        isVoucher(){
            return this.currentEvent?.type?.includes('Voucher');
        },
        isMembership(){
            return this.currentEvent?.type?.includes('Membership');
        },
        isStatus(status){
            let isBusinessService = this.isBusinessService() && this.currentEvent.booking.status === status;
            let isVoucher = this.isVoucher() && this.getOrderCardUse('Voucher', this.currentEvent.card_use_id)?.status === status;
            let isMembership = this.isMembership() && this.getOrderCardUse('Membership', this.currentEvent.card_use_id)?.status === status;
            return isBusinessService || isVoucher || isMembership;
        },
        isCardActive(){
            let isVoucherAndActive = this.isVoucher() && this.currentEvent.booking.order_voucher?.status === 'active';
            let isMembershipAndActive = this.isMembership() && this.currentEvent.booking.order_membership?.status === 'active';
            return isVoucherAndActive && isMembershipAndActive;
        },
        isRole(roleName){
            return this.role === roleName;
        },
        getOrderCardUse(type, id){
            let res = {};
            if(type.includes('Voucher') || type.includes('Membership')) {
                res = this.currentEvent.booking.order_card_use.find(c => +c.id === +id);
            }
            return res;
        },
        show(data){
            let res = '';
            let booking = this.currentEvent.booking;
            let orderCardUse = this.getOrderCardUse(this.currentEvent.type, this.currentEvent.card_use_id);
            let employee = this.isBusinessService() && booking.employee ? booking.employee : ((this.isVoucher() || this.isMembership()) && orderCardUse.employee ? orderCardUse.employee : {})
            switch(data) {
                case 'customer_name':
                    res = booking.user?.name;
                    break;
                case 'date':
                    res = this.isBusinessService() ? this.momentFormat(booking.date, 'LL') : this.momentFormat(orderCardUse?.date, 'LL');
                    break;
                case 'time':
                    res = this.isBusinessService() ? `${ booking.start_time } - ${ booking.end_time }` : orderCardUse?.time;
                    break;
                case 'employee_name':
                    res = employee ? employee.name : 'No preference';
                    break;
                case 'employee_expertise':
                    res = employee && employee.title ? `(${ employee.title })` : '';
                    break;
                case 'amount':
                    res = this.moneyFormat(booking.amount);
                    break;
                case 'created_at':
                    res = this.isBusinessService() ? this.momentFormat(booking.created_at, 'LL') : this.momentFormat(orderCardUse?.created_at, 'LL');
                    break;
                case 'created_at_original':
                    res = this.isBusinessService() ? booking.created_at : orderCardUse?.created_at;
                    break;
                case 'card_name':
                    res = this.isVoucher() ? booking.order_voucher.name : booking.order_membership.name;
                    break;
                case 'card_valid_for':
                    res = this.isVoucher() ? booking.order_voucher.valid_for.replaceAll('_', ' ') : booking.order_membership.valid_for.replaceAll('_', ' ');
                    break;
                case 'card_price':
                    res = this.moneyFormat(this.isVoucher() ? booking.order_voucher.retail_price : booking.order_membership.price);
                    break;
                case 'card_qty':
                    res = this.isVoucher() ? booking.order_voucher.qty : '';
                    break;
                case 'card_use':
                    res = this.isVoucher() ? booking.order_voucher.total_card_use : booking.order_membership.total_card_use;
                    break;
                case 'note':
                    res = this.isBusinessService() ? booking.note : orderCardUse.note;
                    break;
                case 'tip':
                    res = this.isBusinessService() ? booking.tip : 0;
                    break;
                case 'cancelled_at':
                    if(this.isStatus('cancelled')) {
                        res = this.isBusinessService() ? `at ${ this.momentFormat(booking.cancelled_at, 'LLL') }` : `at ${ this.momentFormat(orderCardUse.cancelled_at, 'LLL') }`;
                    }
                    break;
                default:
                    res = '';
                    break;
            }
            return res;
        },
        canCancelBooking(){
            let statusIsMatch = false;
            let bookingCreatedAt = '';
            let booking = this.currentEvent.booking;

            let roles = (this.isRole('customer') || this.isRole('admin') || this.isRole('superadmin'));
            if(booking.type?.includes('Voucher')){
               let orderCardUse = this.getOrderCardUse('Voucher', this.currentEvent.card_use_id);
                statusIsMatch = orderCardUse.status === 'awaiting_time';
                bookingCreatedAt = orderCardUse.created_at;
            }else if(booking.type?.includes('Membership')){
                let orderCardUse = this.getOrderCardUse('Membership', this.currentEvent.card_use_id);
                statusIsMatch = orderCardUse.status === 'awaiting_time';
                bookingCreatedAt = orderCardUse.created_at;
            }else {
                statusIsMatch = booking.status === 'awaiting_charge';
                bookingCreatedAt = booking.created_at;
            }
            return this.isInTwoHourFromNow(bookingCreatedAt) && statusIsMatch && roles;
        },
        cancelBooking(){
            let form = {
                id: this.currentEvent.id,
                type: this.currentEvent.type.replaceAll('App\\', '').toLowerCase(),
                card_use_id: this.currentEvent.card_use_id,
            };
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to cancel booking?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes, Cancel',
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed) {
                    this.isLoading = true;
                    axios.post(`/booking/cancel`, form)
                    .then(res => {
                        this.$toast.success(`Successfully, Booking has been cancelled.`);
                        window.location.reload();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                    });
                }
            })
        },
        completeBooking(){
            let form = {
                id: this.currentEvent.id,
                type: this.currentEvent.type.replaceAll('App\\', '').toLowerCase(),
                card_use_id: this.currentEvent.card_use_id,
            };
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to complete booking?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes, Complete it!',
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed) {
                    this.isLoading = true;
                    axios.post(`/booking/complete`, form)
                    .then(res => {
                        this.$toast.success(`Successfully, Booking has been completed.`);
                        window.location.reload();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                    });
                }
            })
        },
        createBooking(){
            this.isLoading = true;
            axios.post(`/account/calendar/booking`, this.formBooking)
            .then(res => {
                this.$toast.success(`Successfully, Booking has been created.`);
                window.location.reload();
            })
            .catch(err => {
                this.isLoading = false;
                this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
            });
        },
        updateServices(data){
            this.formBooking.all_selected = data.all;
            this.formBooking.services = data.services;
        },
        getTotalMinutes(){
            return this.formBooking.services.reduce((accumulator, current) => accumulator + Number(current['price_duration']), 0)
        },
        updateBookingDate(){
            let form = {
                id: this.currentEvent.id,
                type: this.currentEvent.type.replaceAll('App\\', '').toLowerCase(),
                card_use_id: this.currentEvent.card_use_id,
                date: momentTimezone(this.date).format('Y/MM/DD'),
            };
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to update the booking date?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes, Cancel',
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed) {
                    this.isLoading = true;
                    axios.post(`/account/calendar/booking/update/date`, form)
                    .then(res => {
                        this.$toast.success(`Successfully, Booking Date has been updated.`);
                        window.location.reload();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.$toast.error(err.response.status === 422 ? err.response.data.message : err.response.statusText)
                    });
                }
            })
        }
    },
    computed: {
        getEndTime(){
            this.formBooking.total_duration = this.timeFormat(this.getTotalMinutes());
            this.formBooking.end_time = momentTimezone(this.formBooking.time, 'LT').add(this.getTotalMinutes(), 'minutes').format('LT');
            return this.formBooking.time && this.getTotalMinutes() ? `End Time: ${ this.formBooking.end_time }` : '';
        }
    }
};
</script>

<style lang="scss">
#calendar {
    & h2 {
        font-size: 1.3em;
    }

    & .fc-button {
        text-transform: capitalize;
        background: white;
        color: var(--dark);
        font-size: 1em;

        &.fc-state-active {
            background: var(--dark);
            color: white;
        }
    }

    & .fc-event {
        border-color: white;
        font-size: 10px;

        & .fa {
            color: white;
            font-size: 12px;
        }
    }

    & #booking-detail {
        padding: 3%;
    }

    & #coupon-detail-modal {
        margin-left: 3%;
        margin-top: 45%;
    }

    & #modal-close {
        margin-right: 43%;
    }
}

.fc-content {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1px;
    color: white;
}

.fc-content:hover {
    padding: 3px;
}

.fc-time {
    font-weight: normal;
}

.fc-title {
    display: flex;
    flex-wrap: wrap;
}

.booking_awaiting_charge {
    background: #9808be;
    fill: #9808be;
    text-align: left;

    & p {
        font-size: 1.2em;
        margin-top: 2px;
    }

    & .fc-event-dot {
        background-color: #9808be;
        position: relative;
        top: 1px;
    }
}

.booking_awaiting_time {
    background: #87be19;
    fill: #87be19;
    text-align: left;

    & p {
        font-size: 1.2em;
        margin-top: 2px;
    }

    & .fc-event-dot {
        background-color: #87be19;
        position: relative;
        top: 1px;
    }
}

.booking_completed {
    background: green;
    fill: green;
    text-align: left;

    & p {
        font-size: 1.2em;
        margin-top: 2px;
    }

    & .fc-event-dot {
        background-color: green;
        position: relative;
        top: 1px;
    }
}

.booking_expired {
    background: red;
    fill: red;
    text-align: left;

    & p {
        //color: red;
        font-size: 1.2em;
        margin-top: 2px;
    }

    & .fc-event-dot {
        background-color: red;
        position: relative;
        top: 1px;
    }
}

.booking_cancelled {
    background: #6b6b6b;
    fill: #6b6b6b;
    text-align: left;

    & p {
        font-size: 1.2em;
        margin-top: 2px;
    }

    & .fc-event-dot {
        background-color: #6b6b6b;;
        position: relative;
        top: 1px;
    }
}

.svg_div_awaiting_charge {
    & svg {
        fill: #9808be;
    }

    color: #9808be;
}

.svg_div_awaiting_time {
    & svg {
        fill: #87be19;
    }

    color: #87be19;
}

.svg_div_completed {
    & svg {
        fill: green;
    }

    color: green;
}

.svg_div_expired {
    & svg {
        fill: red;
    }

    color: red;
}

.svg_div_cancelled {
    & svg {
        fill: #6b6b6b;
    }

    color: #6b6b6b;
}

.service-info {
    padding: 17px 15px;
    font-size: 15px;
    background: #e1dede78;
    border-radius: 6px;
    position: relative;
    top: 2px;
}
</style>
