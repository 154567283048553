<template>
  <section>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"
             loader="dots"
             color="#366865"
             background-color="#a3afeb66"
    />
    <!--  TopBar-->
    <div class="container mt-2 pb-2" id="searching-page">
      <div class="row" id="topbar">
        <div class="col-md-10 col-sm-12">
          <h3 class="pull-left font-weight-900 md:text-4xl sm:text-2xl">{{ branches.total }} Result Found</h3>
        </div>
        <div class="col-md-2 col-sm-12">
          <div class="switch-div pull-right" style="position: relative; top: 2.7em;">
            <label class="map-view" style="position:relative; top: -13px; right: 4px;">Map View</label>
            <label class="switch" style="height: 22px !important; width: 47px !important;">
              <input name="view" type="checkbox" v-model="mapView">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="col-md-2 col-sm-12 mt-1 px-0">
          <select class="form-control w-full" v-model="availableFor">
            <option value="male_only">Male Only</option>
            <option value="female_only">Female Only</option>
            <option value="for_everyone">For Everyone</option>
          </select>
        </div>

      </div>
    </div>

    <!-- Map-->
    <div v-show="mapView" class="container-lg px-4">
      <div class="row">
          <div class="col-sm-12">
                <div id="view_map"></div>
          </div>
      </div>
    </div>

    <!-- Listing -->
    <div v-show="!mapView" class="container mt-2 pb-5">
      <div class="row">
        <div v-for="branch in getBranches" :key="`b-${branch.id}`" class="col-sm-12 col-md-2 col-lg-3 mt-4 px-2">
          <div class="card rounded-2xl bg-transparent border-none">
            <carousel :per-page="1" pagination-active-color="#366865" :pagination-padding="4">
              <template v-if="branch.images.length">
                <slide v-for="image in branch.images" :key="`img-${image.id}`">
                  <img class="card-img-top rounded-xl aspect-radio-16-9" :src="image.path" alt="Card image cap">
                </slide>
              </template>
              <slide v-else>
                <img class="card-img-top rounded-xl aspect-radio-16-9" src="/images/default_barber.png" alt="Card image cap">
              </slide>
            </carousel>
            <div class="card-body py-2 px-1 border-bottom-linear-gradient">
              <a :href="`/show/${branch.slug}`" class="card_title leading-6">{{ branch.name | strLimit(40) }}
                  <span v-if="branch.rating" class="fa fa-star checked"></span>
                  <b  v-if="branch.rating">{{branch.rating}}</b>
              </a>
              <p class="card-text text-muted leading-6">{{ branch.address | strLimit(40) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Next & Previous Buttons -->
    <div v-if="branches.last_page > 1" class="row mt-4" id="next-prev-pagination">
      <div class="col-md-12 text-center">
        <a href="javascript:void(0);"
           :class="`btn btn-outline-dark inline-flex px-4 py-2 ${page>1?'':'disabled'}`"
           @click="getPaginationData('prev')">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
            <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M9.375 233.4l128-128c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H480c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38c12.5 12.5 12.5 32.75 0 45.25c-12.49 12.49-32.74 12.51-45.25 0l-128-128C-3.125 266.1-3.125 245.9 9.375 233.4z"/>
          </svg>
          Prev
        </a>
        <a href="javascript:void(0);"
           :class="`btn btn-outline-dark inline-flex px-4 py-2 ${page<branches.last_page?'':'disabled'}`"
           @click="getPaginationData('next')">
          Next
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
            <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
                d="M502.6 278.6l-128 128c-12.51 12.51-32.76 12.49-45.25 0c-12.5-12.5-12.5-32.75 0-45.25L402.8 288H32C14.31 288 0 273.7 0 255.1S14.31 224 32 224h370.8l-73.38-73.38c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l128 128C515.1 245.9 515.1 266.1 502.6 278.6z"/>
          </svg>
        </a>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "SearchServiceCategory",
  props: {
    allBranches: {
      type: Array | Object,
      default: () => []
    }
  },
  data(){
    return {
      isLoading: false,
      mapView: false,
      markers: [],
      infoWindows: [],
      branches: this.allBranches,
      page: 1,
      availableFor: 'for_everyone'
    }
  },
  mounted(){
    this.setupMap();
  },
  methods: {
    setupMap(){
      let self = this;
      $(document).ready(function(){
        let el = $("#view_map");
        let centerLatLng = new window.google.maps.LatLng(self.getBranches[0].lat, self.getBranches[0].lng);

        let options = {
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          animation: window.google.maps.Animation.DROP,
          zoom: 13,
          center: centerLatLng,
          styles: self.getMapStyle()
        };
        let map = new window.google.maps.Map(el.get(0), options);

        for(let i = 0; i < self.getBranches.length; i++) {

          let branch = self.getBranches[i];
          let markerId = 'pin_' + branch.id;
          let infoWindow = new window.google.maps.InfoWindow({
            content: self.makeHtmlContent(branch),
            id: branch.id
          });
          self.infoWindows.push(infoWindow);
          let marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(branch.lat, branch.lng),
            map,
            title: branch.name,
            animation: window.google.maps.Animation.DROP,
            icon: '/images/pin.webp',
            id: markerId,
          });

          self.markers.push(marker);

          marker.addListener("click", () => {
            self.closeInfoWindows();
            infoWindow.open(map, marker);
          });
          marker.addListener('mouseover', function(){
            self.closeInfoWindows();
            infoWindow.open(map, marker);
          });
        }

        window.google.maps.event.addListener(map, "click", function(event){
          self.closeInfoWindows();
        });

      });
    },
    makeHtmlContent(branch){
      return `
        <div class="card" style="width: 270px">
            <img src="${branch.images.length ? branch.images[0].path : '/images/default_barber.png'}" style="object-fit: cover; height: 200px;" alt="">
            <div class="card-body p-3">
              <a href="/show/${branch.slug}" style="font-weight: bolder;color: black;"><b>${branch.name}</b></a>
              <p class="card-text text-muted mt-2" style="line-height: 15px;">${branch.address}</p>
              <p>
                <span class="fa fa-star checked"></span>
                <b>5.0 Great</b>
                <span class="text-muted">1200 ratings</span>
              </p>
            </div>
        </div>
      `;
    },
    closeInfoWindows(){
      for(let i = 0; i < this.infoWindows.length; i++) {
        this.infoWindows[i].close();
      }
    },
    getMapStyle(){
      return [
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
      ];
    },
    getPaginationData(action = ''){
      if(action === 'next') this.page++
      else if(action === 'prev') this.page--;

      this.isLoading = true;
      axios.get(`${window.location.href}?available_for=${this.availableFor}&page=${this.page}`)
      .then(res => {
        this.branches = res.data.data.branches;
        this.page = +this.branches.current_page;
        this.setupMap();
      })
      .catch(e => console.log(e))
      .finally(() => this.isLoading = false);
    }
  },
  computed: {
    getBranches(){
      if(this.branches && this.branches.data) {
        return this.branches.data;
      }
    }
  },
  watch: {
    availableFor(){
      this.page = 1;
      this.getPaginationData();
    }
  }
}
</script>

<style lang="scss" scoped>
#topbar {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.card_title {
  font-weight: bolder;
  color: black;
}

.card_title:hover {
  color: #366865;
  text-decoration: underline;
}

.card-img-top {
  //height: 250px;
  object-fit: cover;
}

.card p {
  color: black;
}

div#view_map {
  //width: 1500px;
  height: 600px;
  box-shadow: 0px 0px 20px 0px;
}

#next-prev-pagination {
& svg{
    position: relative;
    //top: 5px;
    margin: 0px 5px;
  }
& a:hover{
    background: #d7e7e6b3;
    color: black;
  }
}
.slider:before{
     left: 3px !important;
     bottom: 4px !important;
 }
</style>
