<template>
  <div :class="`col-12 w-100 h-100 ${classes}`">

    <div v-if="desc" class="text-center py-3">
      <p>{{ desc }}</p>
    </div>

    <!--    Large View-->
    <div v-if="size === 'lg'"
         :class="`overflow-hidden card card-${size} card-${theme} card-${size}-${theme} ${clickAble?'clickable':''}`"
         @click="clickMembership">
      <div v-if="purchased" class="origin-top rotate-45 mt-9 mr-9 w-72 text-center text-md absolute -right-[9em] p-1" style="background: rgb(19, 222, 78);box-shadow: 0px 0px 20px 20px rgb(19 222 78);">
         <p class="text-white font-semibold">Paid</p>
      </div>
      <div :class="`card-${size}-body`">
        <div class="col-12 text-center py-2 text-xl">
          <p class="grid justify-center">
          <svg class="fill-white" width="100" height="100" viewBox="0 0 24 24">
            <path fill="" d="M20 10H4V4h16m0 11H4v-2h16m0-11H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2l4 2v-5h4c1.11 0 2-.89 2-2V4c0-1.11-.89-2-2-2Z"/>
          </svg>
          </p>
          <h4 class="mb-0 text-2xl"><b>{{ name }}</b></h4>
          <span>{{ address }}</span>
        </div>
        <hr>
        <div class="col-12 text-center py-2 text-xl">
          <h4 class="py-2 font-semibold">Membership Price</h4>
          <h2><b>{{ moneyFormat(price) }}</b></h2>
        </div>
        <hr>
        <div class="col-12 text-center py-2">
          <span>Redeem on {{ selectAll ? 'all' : servicesCount }} Services</span><br>
          <span>{{ getValidFor }}</span><br>
          <span class="text-capitalize">{{ sessions }}{{ sessions === 'limited' ? ' - ' + noOfSession : '' }} Sessions </span><br>
          <span>For multiple-use until redeemed</span>
        </div>
      </div>
    </div>

    <!--    Small View-->
    <div v-else :class="`overflow-hidden card card-${size} card-${theme} card-${size}-${theme} ${clickAble?'clickable':''}`"
         @click="clickMembership">
      <div v-if="purchased" class="origin-top rotate-45 mt-9 mr-9 w-72 text-center text-md absolute -right-[9em] p-1" style="background: rgb(19, 222, 78);box-shadow: 0px 0px 3px 3px rgb(19 222 78);">
         <p class="text-white font-semibold">Paid</p>
      </div>
      <div :class="`card-${size}-body d-flex`">
        <div class="col-md-2 col-sm-2">
          <svg class="member-svg" width="70" height="70" viewBox="0 0 24 24">
            <path fill="currentColor"
                  d="M20 10H4V4h16m0 11H4v-2h16m0-11H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2l4 2v-5h4c1.11 0 2-.89 2-2V4c0-1.11-.89-2-2-2Z"/>
          </svg>
        </div>
        <div class="col-md-10 ml-4 text-center voucher-sm-detail">
          <h4>{{ title }}</h4>
          <h4><b>{{ moneyFormat(price) }}</b></h4>
          <!--          <p class="text-center m-0">-->
          <!--            <a v-if="canEdit" href="javascript:void(0);" class="btn btn-primary btn-sm"><i class="fa fa-pencil"></i></a>-->
          <!--            <a v-if="canEdit" href="javascript:void(0);" class="btn btn-danger btn-sm "><i class="fa fa-trash"></i></a>-->
          <!--          </p>-->
          <br>

          <span class="pull-left pl-4 d-inline-flex">
               <svg width="18" height="18" viewBox="0 0 24 24" class="mr-2">
                 <path fill="currentColor" d="M15 13h1.5v2.82l2.44 1.41l-.75 1.3L15 16.69V13m4-5H5v11h4.67c-.43-.91-.67-1.93-.67-3a7 7 0 0 1 7-7c1.07 0 2.09.24 3 .67V8M5 21a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h1V1h2v2h8V1h2v2h1a2 2 0 0 1 2 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 0 1-7 7c-1.91 0-3.64-.76-4.9-2H5m11-9.85A4.85 4.85 0 0 0 11.15 16c0 2.68 2.17 4.85 4.85 4.85A4.85 4.85 0 0 0 20.85 16c0-2.68-2.17-4.85-4.85-4.85Z"/>
               </svg>
              Redeem on {{ selectAll ? 'all' : servicesCount }} Services
            </span>
          <br>
          <br>
          <span class="pull-left pl-4">{{ getValidFor }}</span><br>
          <span class="pull-left text-capitalize pl-4">{{ sessions }}{{ sessions === 'limited' ? ' - ' + noOfSession : '' }} Sessions </span><br>
          <span class="pull-left pl-4">For multiple-use until redeemed</span>

        </div>
      </div>
    </div>

    <div v-if="terms" class="text-center mt-4">
      <h5 class="font-semibold">Terms & Conditions</h5>
      <p>{{ terms }}</p>
    </div>

  </div>
</template>

<script>
import helperMixin from '@mixins/helperMixin';

export default {
  name: "MembershipCard",
  mixins:[helperMixin],
  props: {
    name: {
      type: String,
      default: 'Glamour Salaon'
    },
    address: {
      type: String,
      default: 'Your location will appear here'
    },
    classes: {
      type: String,
      default: ''
    },
    theme: {
      type: [Number, String],
      default: 1
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    clickAble: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    title: {
      type: String,
      default: ''
    },
    validFor: {
      type: String,
      default: ''
    },
    price: {
      type: [Number, String],
      default: 0
    },
    sessions: {
      type: String,
      default: ''
    },
    noOfSession: {
      type: Number,
      default: 0
    },
    desc: {
      type: String,
      default: ''
    },
    membershipId: {
      type: Number,
      default: 0
    },
    selectAll: {
      type: Boolean,
      default: true
    },
    servicesCount: {
      type: Number,
      default: 0
    },
    terms: {
      type: String,
      default: ''
    },
    purchased: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    clickMembership(){
      if(this.clickAble) this.$emit('click', this.membershipId);
    }
  },
  computed: {
    getValidFor(){
      return 'Valid for ' + this.validFor.replaceAll('_', ' ');
    }
  }
}
</script>

<style scoped>
/*
* Gradient Site
* https://cssgradient.io/
* @Author Khuram Qadeer.
**/

.card {
  /*max-width: 700px;*/
  color: white;
  border: none;
  background: linear-gradient(0deg,#8b60ed,#b372bd);
  /*box-shadow: 0px 0px 35px 0px rgb(71 28 98);*/
  border-radius: 5px;
}

.badge {
  background-color: white;
  color: rgb(173, 84, 254);
  font-size: 16px
}

.card-1 {
  background: linear-gradient(0deg,#f19a1a,#ffc73c);
  /*box-shadow: 0px 0px 35px 0px rgba(0,0,0,1);*/
}

.badge-1 {
  color: rgb(71, 28, 98);
}

.card-2 {
  background: linear-gradient(180deg,#f44881,#ec454f);
  /*box-shadow: 0px 0px 35px 0px rgba(99,102,55,1);*/
}

.badge-2 {
  color: rgb(99, 102, 55);
}

.card-3 {
  background: linear-gradient(180deg,#6edcc4,#1aab8b);
  /*box-shadow: 0px 0px 35px 0px rgba(163,92,187,1);*/
}

.badge-3 {
  color: rgb(163, 92, 187);
}

.card-4 {
  background: linear-gradient(351deg, #3f5efb,#5cd9d8);
  /*box-shadow: 0px 0px 35px 0px rgba(63,94,251,1);*/
}

.badge-4 {
  color: rgb(63, 94, 251);
}

.member-svg {
  /*width: 10rem;*/
  /*position: relative;*/
  /*top: 4rem;*/
}

hr {
  border-color: rgb(226 227 225 / 30%);
  margin: 0;
}

.card-lg-body {
  padding: 1rem;
}

.card-sm-body {
  padding: 2rem 0rem 2rem 0rem;
}

.voucher-sm-detail p {
  padding: 0 2rem;
}

.voucher-sm-detail p.first {
  font-size: 18px !important;
}

.clickable {
  cursor: pointer;
}

.modal-open .modal {
  z-index: 99999;
}
</style>
