<template>
    <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="search-modal" tabindex="-1" role="dialog"
         aria-labelledby="search-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content px-4 py-2">
                <div class="row py-4">
                    <div class="col-md-12">
                        <input class="p-2 w-100 search-input col-12 shadow " id="search-input" placeholder="Search for a service or venue" v-model="keyword">
                        <a href="javascript:void(0);" class="btn btn-nearby" @click="getLocation">
                            <i class="fa fa-location-arrow" aria-hidden="true"></i>
                            Near me
                        </a>
                    </div>
                    <div class="col-md-12 mt-3">
                        <h5>Categories</h5>
                        <!--            <a href="javascript:void(0);" class="btn btn-tag" @click="getLocation">-->
                        <!--              <i class="fa fa-location-arrow" aria-hidden="true"></i>-->
                        <!--              Near me-->
                        <!--            </a>-->
                        <a href="/services" class="btn btn-tag">
                            <i class="fa fa-star-o" aria-hidden="true"></i>
                            All Categories
                        </a>
                        <a v-for="category in categories"
                           :key="`cat-search-${category.id}`"
                           :id="`cat-search-${category.id}`"
                           :href="`/services/${category.slug}`"
                           class="btn btn-tag">
                            {{ category.name }}
                        </a>
                    </div>

                    <div class="col-md-12 mt-2 min-h-full" id="venue-section">
                        <h5>Venue</h5>
                        <loading :active.sync="isLoading"
                                 :can-cancel="false"
                                 :is-full-page="false"
                                 color="#366865"
                                 background-color="#a3afeb66"
                        />
                        <ul v-if="branches.length" class="list-group list-group-light">
                            <li v-for="branch in branches" :key="`branch-search-${branch.id}`"
                                class="list-group-item d-flex justify-content-between align-items-center p-1 ">
                                <div class="d-flex align-items-center">
                                    <img :src="`${branch.images.length?branch.images[0].path:'/images/default_barber.png'}`" alt=""
                                         class="img-thumbnail img-sm">
                                    <div class="ms-3">
                                        <p class="fw-bold mb-1">
                                            <b><a :href="`/show/${branch.slug}`">{{ branch.name }}</a></b>
                                              <span v-if="branch.rating" class="fa fa-star checked"></span>
                                              <b v-if="branch.rating">{{branch.rating}}</b>
                                        </p>
                                        <small class="text-muted mb-0 text-addr">{{ branch.address }}</small>
                                    </div>
                                </div>
                                <!--                  <p>-->
                                <!--                      <span class="fa fa-star checked">-->
                                <!--                      </span> <b>5.0 Great</b> <span class="text-muted">(1200 ratings)</span>-->
                                <!--                  </p>-->
                            </li>
                        </ul>
                        <Alert
                            v-else
                            :type="`${keyword.length?'danger':'gray'}`"
                            :title="`${keyword.length?'Not Matched':'Enter keyword for searching'}`"
                            :message="`${keyword.length?'Use difference keyword for get matched venue or services.':'Find most matched venue or services'}`"
                            class="my-4"
                        />
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Alert from "@components/includes/Alert";

export default {
    name: "SearchModal",
    components: {Alert},
    props: {
        allCategories: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isLoading: false,
            keyword: '',
            lat: '25.059235792112',
            lng: '55.150645758203',
            categories: this.allCategories,
            branches: []
        }
    },
    mounted() {
        this.lat = localStorage.getItem('mybarber_nearby_lat');
        this.lng = localStorage.getItem('mybarber_nearby_lng');
    },
    methods: {
        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            }
        },
        showPosition(position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            localStorage.setItem('mybarber_nearby_lat', this.lat);
            localStorage.setItem('mybarber_nearby_lng', this.lng);
        }
    },
    watch: {
        keyword() {
            if (this.keyword) {
                this.isLoading = true;
                let url = `/search/${this.keyword}`;
                if (this.lat && this.lng) {
                    url += `/${this.lat}/${this.lng}`;
                }
                axios.get(url)
                    .then(res => {
                        this.categories = _.toArray(this.allCategories).filter(c => {
                            return this.keyword.toLowerCase().split(' ').every(v => c.name.toLowerCase().includes(v));
                        });
                        this.branches = res.data.data.branches;
                    })
                    .catch(e => console.log(e))
                    .finally(() => this.isLoading = false);
            } else {
                this.isLoading = false;
                this.categories = this.allCategories;
            }
        }
    }
}
</script>

<style scoped>
.search-input {
    font-size: 20px;
    padding-left: 13px !important;
    color: var(--primary-color)
}

#search-input:focus-visible {
    border: none !important;
}

.btn-tag {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 5px 1rem;
    border-radius: 8px;
    margin: 3px 2px;
}

.btn-nearby {
    color: white;
    background: black;
    padding: 11px 2rem;
    /*border-radius: 30px 10px 5px 30px;*/
    position: absolute;
    right: 1em;
}

.btn-tag.active {
    background: var(--primary-color);
    color: white;
}

.img-sm {
    width: 80px;
    height: 60px;
    margin-right: 10px;
    object-fit: cover;
}

.list-group-item {
    /*border-top: none;*/
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-color: var(--dark-secondary-color);
}

.text-addr {
    position: relative;
    top: -7px;
}

#venue-section a {
    color: var(--primary-color);
}
</style>
