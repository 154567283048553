export default {
    methods: {
        timeFormat(minutesNumber){
            const hours = (minutesNumber / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            if(minutesNumber > 60)
                return rhours + "h " + rminutes + "min";
            else
                return minutesNumber + " min";
        },
        momentFormat(date = null,format='LLLL'){
            return date ? moment(new Date(date)).format(format) : '';
        },
        diffForHumans(date = null){
            return date ? moment(date, "YYYYMMDD").fromNow() : '';
        },
        isInTwoHourFromNow(date){
            let dateTwoHours = moment(date).add(2, 'hours').format('DD/MM/YYYY hh:mm:ss');
            let now = moment(new Date()).format('DD/MM/YYYY hh:mm:ss');
            return dateTwoHours > now;
        },
        convertTime24to12(time24h) {
            let time = time24h
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24h];

            if (time.length > 1) {
                time = time.slice(1, -1);
                time[5] = +time[0] < 12 ? ' am' : ' pm';
                time[0] = +time[0] % 12 || 12;
            }
            return time.join('');
        }
    }
}

