import { render, staticRenderFns } from "./ServiceCategoryModal.vue?vue&type=template&id=0d77b801&scoped=true&"
import script from "./ServiceCategoryModal.vue?vue&type=script&lang=js&"
export * from "./ServiceCategoryModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d77b801",
  null
  
)

export default component.exports