<template>
  <div>
    <h3>{{ title }}</h3>
    <div class="row py-2" v-for="(wk,i) in week" :key="`day-${i}`">
      <div class="col-md-3">
        <input type="checkbox" class="form-check-input" :name="wk.day" :id="wk.day" :value="!!wk.on" v-model="wk.on">
        <span class="day-name">{{ wk.day }}</span>
      </div>
      <template v-for="(time,index) in wk.timing" class="col-md-9">
        <div :class="`col-md-4 py-1 ${index>0?'offset-3':''} ${!wk.on?'disabled-day':''}`">
            <label>Open</label>
            <vue-timepicker :minute-interval="15" format="HH:mm" v-model="time.start"  />
        </div>
        <div :class="`col-md-4 py-1 ${!wk.on?'disabled-day':''}`">
            <label>Close</label>
            <vue-timepicker :minute-interval="15" format="HH:mm" v-model="time.end" />
        </div>
        <div :class="`col-md-1 ${!wk.on?'disabled-day':''}`">
          <a v-if="index===0" href="javascript:void(0);" class="btn btn-dark addTime" @click="addTimeSlot(i)">
            <i class="fa fa-plus"></i>
          </a>
          <a v-else href="javascript:void(0);" class="btn btn-dark addTime" @click="removeTimeSlot(i,index)">
            <i class="fa fa-minus"></i>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';

export default {
    name: 'OneWeekSchedule',
    components: { VueTimepicker },
    props: {
        title: {
            type: String,
            default: 'Opening Hours'
        },
        weekData: {
            type: Array | Object,
            default: () => []
        }
    },
    data(){
        return {
            week: this.weekData.length ? this.weekData : this.getDefault()
        }
    },
    methods: {
        addTimeSlot(weekIndex){
            this.week[weekIndex].timing.push({
                start: '',
                end: '',
            })
        },
        removeTimeSlot(weekIndex, timeIndex){
            this.week[weekIndex].timing.splice(timeIndex, 1);
        },
        getSchedule(){
            // Concat B/W HH and mm object from timings
            this.week.map(wk => {
                wk.timing.map(time => {
                    if(time.start?.hasOwnProperty('HH')){
                        time.start = time.start.HH+':'+time.start.mm
                    }
                })
            })
            return this.week;
        },
        setSchedule(schedule){
            this.week = schedule ? schedule : this.getDefault();
        },
        reset(){
            this.week = this.getDefault();
        },
        getDefault(){
            return [
                {
                    on: true,
                    day: 'Monday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                },
                {
                    on: true,
                    day: 'Tuesday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                },
                {
                    on: true,
                    day: 'Wednesday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                },
                {
                    on: true,
                    day: 'Thursday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                },
                {
                    on: true,
                    day: 'Friday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                },
                {
                    on: true,
                    day: 'Saturday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                },
                {
                    on: true,
                    day: 'Sunday',
                    timing: [
                        {
                            start: '',
                            end: '',
                        }
                    ]
                }
            ];
        }
    }
}
</script>

<style scoped>
@import 'vue2-timepicker/dist/VueTimepicker.css';

.addTime {
    margin-top: 28px;
}

input[type=checkbox] {
    position: initial;
    left: 0;
}

.disabled-day {
    pointer-events: none;
}

.disabled-day select,
.disabled-day a {
    background-color: #dddddd;
    border-color: #dddddd;
}

.form-check-input {
    border: 1px solid grey;
    border-radius: 5px;
    background-color: grey !important;
    margin: 0em 0 0 2em;
    padding: 8px;
}

.day-name {
    position: relative;
    top: 0px;
    left: 5px;
}
</style>
